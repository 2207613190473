import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import CollapsibleSection from "../CollapsibleSection/CollapsibleSection"
import publicChargerOutlets from "../../assets/images/publicChargerOutlets.svg"
import rightArrowBullet from "../../assets/images/icons/rightArrowBullet.svg"
import allElectricVehicle from "../../assets/images/icons/allElectricVehicle.svg"
import plugInHybridVehicle from "../../assets/images/icons/plugInHybridVehicle.svg"
import clock from "../../assets/images/icons/clock.svg"
import orangePopOut from "../../assets/images/icons/orangeGreyPopout.svg"
import "./ChargingMapBasics.scss"
import { FormatAsThousands } from "../../../utils/Helpers/Format"
import {FormattedMessage, useIntl} from 'react-intl';

const ChargingMapBasics = ({ ip, uuid, props }) => {
  const intl = useIntl()

  const collaspibleSection = [
    {
      headLine: intl.formatMessage ? intl.formatMessage({ id: "differentTypesOfChargingStationsQuestion", defaultMessage: "What are the different types of charging stations?"}): "",
      body: 
      <>
        <p>
          <FormattedMessage
            id="thereAreThreeLevels"
            defaultMessage="Public Charging Station Basics"
            description="Public Charging Station Basics"
            values= {{
              levelOne: <b>{intl.formatMessage ? intl.formatMessage({ id: "evs.levelOne", defaultMessage: "Level 1"}): ""}</b>,
              levelTwo: <b>{intl.formatMessage ? intl.formatMessage({ id: "evs.levelTwo", defaultMessage: "Level 2"}): ""}</b>,
              dcfc: <b>{intl.formatMessage ? intl.formatMessage({ id: "dcfcFastCharge", defaultMessage: "DC Fast Charge"}): ""}</b>
            }}
          />
        </p>
        <p>
          <FormattedMessage
            id="levelOneChargingDescription"
            defaultMessage="An hour connected to a {levelOne} charger (the standard US household outlet) will provide a battery electric vehicle (BEV) or plug-in hybrid electric vehicle (PHEV) with about 2-10 miles of additional range. This is best for charging overnight when your car isn’t in use, or for PHEVs which have smaller batteries and can rely on their built-in gas engines if they run out of battery."
            description="Public Charging Station Basics"
            values= {{
              levelOne: <b>{intl.formatMessage ? intl.formatMessage({ id: "evs.levelOne", defaultMessage: "Level 1"}): ""}</b>,
            }}
          />
        </p>
        <p>
          <FormattedMessage
            id="levelTwoChargingDescription"
            defaultMessage="{levelTwo} is considerably faster than Level 1 and is currently the most common option for public charging. An hour of Level 2 charging will add about 20-25 miles to most BEVs and PHEVs."
            description="Public Charging Station Basics"
            values= {{
              levelTwo: <b>{intl.formatMessage ? intl.formatMessage({ id: "evs.levelTwo", defaultMessage: "Level 2"}): ""}</b>,
            }}
          />
        </p>
        <p>
          <FormattedMessage
            id="dcfcChargingDescription"
            defaultMessage="{dcfc} are the fastest type of charger publicly available today. BEV drivers can expect to gain over 150 miles of range in an hour on the charger. For most BEVs, drivers can expect to get almost 80% of their total range restored in just 30 minutes. It’s important to note that PHEVs (and some older BEVs) are not compatible with DCFC stations."
            values= {{
              dcfc: <b>{intl.formatMessage ? intl.formatMessage({ id: "dcfcFastCharge", defaultMessage: "DC Fast Charge"}): ""}</b>,
            }}
          />
        </p>
        <p>
          <FormattedMessage
            id="teslaChargingDescription"
            defaultMessage="Tesla Supercharging stations are most similar to the speeds of DCFC, but only Tesla cars can charge at Tesla-branded public charging stations.  See below for more on charging connector compatibility, and {browseElectricVehicles} to learn more about specific brands and models."
            values= {{
              browseElectricVehicles: <Link to="/vehicles">
              <span className="link">{intl.formatMessage ? intl.formatMessage({ id: "header.browseElectricCars", defaultMessage: "Browse Electric Cars"}): ""}</span>
            </Link>
            }}
          />
        </p>
      </>
    },
    {
      headLine: intl.formatMessage ? intl.formatMessage({ id: "publicChargingEVQuestion", defaultMessage: "How do I select a public charging station that works with my EV?"}): "",
      body: <>
      <p>
        <FormattedMessage
          id="everyEVCompatible"
          defaultMessage="Every EV on the road today in the U.S. is compatible with one of three standard charging connectors: SAE J1772, CHAdeMO, & Tesla."
        />
      </p>
      <img src={publicChargerOutlets} alt="public charger outlets" />
      <br/>
      <div className="d-flex align-items-start pt-2">
        <img src={rightArrowBullet} alt="right arrow bullet point" className="pr-2"/>
        <p>
          <FormattedMessage
            id="saeDescription"
            defaultMessage="SAE J1772 is designed for Level 1 and Level 2 charging, unless equipped with the CCS Combo plug, which enables DCFC."
          />
        </p>
      </div>
      <div className="d-flex align-items-start">
        <img src={rightArrowBullet} alt="right arrow bullet point" className="pr-2"/>
        <p>
          <FormattedMessage
            id="chademoDescription"
            defaultMessage="CHAdeMO connectors are capable of Level 1, Level 2 or DCFC with the same universal connector."
          />  
        </p>
      </div>
      <div className="d-flex align-items-start">
        <img src={rightArrowBullet} alt="right arrow bullet point" className="pr-2"/>
        <p>
          <FormattedMessage
            id="teslaDescription"
            defaultMessage="Tesla can charge using the proprietary Tesla Supercharger network with their car's standard Tesla connector but will need to use an adapter to charge at SAE J1772 or CHAdeMO stations."
          />
        </p>
      </div>

      <p>
        <FormattedMessage
          id="dcfcChargerDescription"
          defaultMessage="Before you use a DC Fast Charger, learn about your EV’s connector type. Some DC Fast Charger stations support both CHAdeMO and SAE J1772 connectors. The Tesla Supercharger network is not compatible with other vehicles."
        />
      </p>
      </>
    },
    {
      headLine: intl.formatMessage ? intl.formatMessage({ id: "payingForChargingQuestion", defaultMessage: "Do I have to pay for a public charger? If so, how?"}): "",
      body: <p>
        <FormattedMessage
          id="payingForChargingAnswer"
          defaultMessage="Some public charging stations are free, and some require the driver to pay. The station may allow a user to swipe their credit card right at the plug or offer subscriptions that can be prepaid monthly, often through an application on the driver’s phone. Go to the links in the National Public Charging Networks table to learn more about your options."
        />
      </p>
    },
    {
      headLine: intl.formatMessage ? intl.formatMessage({ id: "publicChargingEtiquette", defaultMessage: "Public charging etiquette"}): "",
      body: 
      <>
      <p>
        <FormattedMessage
          id="publicChargingRules"
          defaultMessage="Most public charger users follow simple “rules of the road” to ensure that everyone has a good experience. Some best practices are:"
        />      
      </p>
      <div className="d-flex align-items-start pt-2">
          <img src={rightArrowBullet} alt="right arrow bullet point" className="pr-2"/>
          <p>
            <FormattedMessage
              id="moveCarAnswer"
              defaultMessage="When you’re done charging, move your car out of the charging spot as soon as possible so others can use the charger."
            /> 
          </p>
        </div>
        <div className="d-flex align-items-start">
          <img src={rightArrowBullet} alt="right arrow bullet point" className="pr-2"/>
          <p>
            <FormattedMessage
              id="unplugAnswer"
              defaultMessage="Don’t unplug other’s cars to plug yours in."
            /> 
          </p>
        </div>
        <div className="d-flex align-items-start">
          <img src={rightArrowBullet} alt="right arrow bullet point" className="pr-2"/>
          <p>
            <FormattedMessage
              id="topOffAnswer"
              defaultMessage="Top off when you can, not when you have to. If you add a little charge here and there (especially when you wouldn’t be in the car anyway – such as running into the grocery store or mall), you might be able to avoid long charging stops to juice up a battery that is nearly empty."
            />
          </p>
        </div>
      </>
    },
  ];

  const renderFaq = collaspibleSection.map((content, index) => {
    return (
      <CollapsibleSection key={index} headLine={content.headLine} > 
        {content.body}
      </CollapsibleSection>
    )}
    )
  
  const chargingSpeeds = [
    {
      level: intl.formatMessage ? intl.formatMessage({ id: "evs.levelOne", defaultMessage: "Level 1"}): "",
      bevToFull: <p>
        <FormattedMessage
          id="bevToFull"
          defaultMessage="BEV empty to full: {about} {time} {hours}"
          values={{
            about: <span style={{fontWeight:"700", fontSize:"18px"}}>{intl.formatMessage ? intl.formatMessage({ id: "about", defaultMessage: "~"}): ""}</span>,
            time: "44",
            hours: intl.formatMessage ? intl.formatMessage({ id: "hours", defaultMessage: "hours"}): ""
          }}
        />
      </p>,
      phevToFull:<p>
        <FormattedMessage
          id="phevToFull"
          defaultMessage="PHEV empty to full: {about} {time} {hours}"
          values={{
            about: <span style={{fontWeight:"700", fontSize:"18px"}}>{intl.formatMessage ? intl.formatMessage({ id: "about", defaultMessage: "~"}): ""}</span>,
            time: "44",
            hours: intl.formatMessage ? intl.formatMessage({ id: "hours", defaultMessage: "hours"}): ""
          }}
        />
      </p>,
      description: intl.formatMessage ? intl.formatMessage({ id: "levelOneDescription", defaultMessage: "Best used when car is idle for many hours."}): "",
      speed: intl.formatMessage ? intl.formatMessage({ id: "leastFast", defaultMessage: "LEAST FAST"}): "",
      miles: `5 ${intl.formatMessage ? intl.formatMessage({ id: "vehicle.miles", defaultMessage: "Miles"}): ""}`
    },
    {
      level: intl.formatMessage ? intl.formatMessage({ id: "evs.levelTwo", defaultMessage: "Level 2"}): "",
      bevToFull: <p>
        <FormattedMessage
          id="bevToFull"
          defaultMessage="BEV empty to full: {about} {time} {hours}"
          values={{
            about: <span style={{fontWeight:"700", fontSize:"18px"}}>{intl.formatMessage ? intl.formatMessage({ id: "about", defaultMessage: "~"}): ""}</span>,
            time: "10",
            hours: intl.formatMessage ? intl.formatMessage({ id: "hours", defaultMessage: "hours"}): ""
          }}
        />
      </p>,
      phevToFull:<p>
        <FormattedMessage
          id="phevToFull"
          defaultMessage="PHEV empty to full: {about} {time} {hours}"
          values={{
            about: <span style={{fontWeight:"700", fontSize:"18px"}}>{intl.formatMessage ? intl.formatMessage({ id: "about", defaultMessage: "~"}): ""}</span>,
            time: "2",
            hours: intl.formatMessage ? intl.formatMessage({ id: "hours", defaultMessage: "hours"}): ""
          }}
        />
      </p>,
      description: intl.formatMessage ? intl.formatMessage({ id: "levelTwoDescription", defaultMessage: "Most readily available at public charging stations for adding range on the go or quickly topping off at home."}): "",
      speed: intl.formatMessage ? intl.formatMessage({ id: "faster", defaultMessage: "FASTER"}): "",
      miles: `20 ${intl.formatMessage ? intl.formatMessage({ id: "vehicle.miles", defaultMessage: "Miles"}): ""}`
    },
    {
      level: "DC Fast Charger",
      bevToFull: <p>
        <FormattedMessage
          id="bevToFull"
          defaultMessage="BEV empty to full: {about} {time} {hours}"
          values={{
            about: <span style={{fontWeight:"700", fontSize:"18px"}}>{intl.formatMessage ? intl.formatMessage({ id: "about", defaultMessage: "~"}): ""}</span>,
            time: "1",
            hours: intl.formatMessage ? intl.formatMessage({ id: "hours", defaultMessage: "hours"}): ""
          }}
        />
      </p>,
      phevToFull:<p>
        <FormattedMessage
          id="phevToFull"
          defaultMessage="PHEV empty to full: {about} {time} {hours}"
          values={{
            about: <span style={{fontWeight:"700", fontSize:"18px"}}>{intl.formatMessage ? intl.formatMessage({ id: "about", defaultMessage: "~"}): ""}</span>,
            time: "N/A",
            hours: intl.formatMessage ? intl.formatMessage({ id: "hours", defaultMessage: "hours"}): ""
          }}
        />
      </p>,
      description: intl.formatMessage ? intl.formatMessage({ id: "dcfcDescription", defaultMessage: "Fastest charging available, with growing networks of public stations."}): "",
      speed: intl.formatMessage ? intl.formatMessage({ id: "fastest", defaultMessage: "FASTEST"}): "",
      miles: `160 ${intl.formatMessage ? intl.formatMessage({ id: "vehicle.miles", defaultMessage: "Miles"}): ""}`
    }
  ]

  const renderChargingSpeeds = chargingSpeeds.map(({level, bevToFull, phevToFull, description, speed, miles }, i) => {
    return (
      <div key={i} className="row charging-speed-row">
        <div className="col-9 charge-description">
          <div className="row p-2 m-0 level">
            {level}
          </div>
          <div className="row m-0 charge-time">
            <img src={allElectricVehicle} alt="all electric vehicle"/>{bevToFull}&nbsp; 
            <img src={plugInHybridVehicle} alt="plug in hybrid vehicle"/>{phevToFull}
          </div>
          <div className="row p-2 m-0 description">
            {description}
          </div>
        </div>
        <div className="col-3 charge-speed">
          <div className="row p-2 m-0 justify-content-between align-items-center description">
            {speed} <img src={clock} alt="clock"/>
          </div>
          <div className="row p-2 m-0 charge-speed-miles">
            {miles}
          </div>
          <div className="row p-2 m-0 description">
            <FormattedMessage
              id="milesOfRangePerHourCharge"
              defaultMessage="of range added per hour charged"
            />
          </div>
        </div>
      </div>
    )
  })

  const renderMobileChargingSpeeds = chargingSpeeds.map(({level, bevToFull, phevToFull, description, speed, miles }, i) => {
    return (
      <div key={i} className="row charging-speed-row-mobile">
        <div className="row level-row p-2 m-0">
          <div className="level">{level}</div>
          <div>{speed} <img src={clock} alt="clock"/></div>
        </div>
        <div className="row m-0 charge-time">
          <div className="charge-vehicle">
            <img src={allElectricVehicle} alt="all electric vehicle"/>
            {bevToFull}&nbsp; 
          </div>
          <div className="charge-vehicle">
            <img src={plugInHybridVehicle} alt="plug in hybrid vehicle"/>{phevToFull}
          </div>
        </div>
        <div className="row pt-2 pb-2 pr-2 m-0 description">
          <p>{description}</p>
        </div>
        <hr className="charging-speed-divider"/>
        <div className="row pb-2 m-0 charge-speed-miles">
        <p><span style={{fontWeight:"700", fontSize:"18px"}}>~&nbsp;</span><span style={{fontWeight:"700", fontSize:"16px"}}>{miles}</span>            <FormattedMessage
              id="milesOfRangePerHourCharge"
              defaultMessage="of range added per hour charged"
            /></p>
        </div>
      </div>
    )
  })

  const chargingNetworks = [
    {
      network: "Blink",
      l2Stations: "1,308",
      dcfcStations: "69",
      l2Cost: intl.formatMessage ? intl.formatMessage({ id: "mixOfKwhAndSession", defaultMessage: "Mix of $/kWh and $/session, by state"}): "",
      dcfcCost: "",
      href: "https://www.blinkcharging.com/"
    },
    {
      network: "ChargePoint",
      l2Stations: "10,569",
      dcfcStations: "814",
      l2Cost: intl.formatMessage ? intl.formatMessage({ id: "variesByLocation", defaultMessage: "Varies by location"}): "",
      dcfcCost: "",
      href: "https://www.chargepoint.com/"
    },
    {
      network: "Electrify America",
      l2Stations: "93",
      dcfcStations: "457",
      l2Cost: intl.formatMessage ? intl.formatMessage({ id: "dollarPerMinuteVaryWithPowerAndState", defaultMessage: "$/minute, varies with power level and by state"}): "",
      dcfcCost: "",
      href: "https://www.electrifyamerica.com/"
    },
    {
      network: "EV Connect",
      l2Stations: "552",
      dcfcStations: "37",
      l2Cost: intl.formatMessage ? intl.formatMessage({ id: "variesByLocation", defaultMessage: "Varies by location"}): "",
      dcfcCost: "",
      href: "https://www.evconnect.com/"
    },
    {
      network: "EVgo",
      l2Stations: "302",
      dcfcStations: "811",
      l2Cost: <FormattedMessage
        id="flatFeePerHour"
        defaultMessage="Flat fee - {cost}/hour"
        values= {{
          cost: "$1.50"
        }}
      />,
      dcfcCost: intl.formatMessage ? intl.formatMessage({ id: "dollarPerMinuteByState", defaultMessage: "$/minute, by state"}): "",
      href: "https://www.evgo.com/"
    },
    {
      network: "FLO",
      l2Stations: "187",
      dcfcStations: "0",
      l2Cost: <FormattedMessage
      id="flatFeePerHour"
      defaultMessage="Flat fee - {cost}/hour"
      values= {{
        cost: "$1"
      }}
    />,
      dcfcCost: <FormattedMessage
      id="costPerHour"
      defaultMessage="{cost}/hour"
      values= {{
        cost: "$10"
      }}
    />,
      href: "https://www.flo.com/"
    },
    {
      network: "GE WattStation",
      l2Stations: "122",
      dcfcStations: "0",
      l2Cost: intl.formatMessage ? intl.formatMessage({ id: "variesByLocation", defaultMessage: "Varies by location"}): "",
      dcfcCost: "",
      href: "https://www.ge.com/news/press-releases/ge-energy-launches-fully-networked-wattstation%E2%84%A2-and-wattstation-connect-software"
    },
    {
      network: "Greenlots",
      l2Stations: "534",
      dcfcStations: "211",
      l2Cost: intl.formatMessage ? intl.formatMessage({ id: "variesByLocation", defaultMessage: "Varies by location"}): "",
      dcfcCost: "",
      href: "https://greenlots.com/"
    },
    {
      network: "OpConnect",
      l2Stations: "117",
      dcfcStations: "13",
      l2Cost: <FormattedMessage
      id="costsPerHour"
      defaultMessage="{lowCost}/hour - {highCost}/hour"
      values= {{
        lowCost: "$1.25",
        highCost: "$3"
      }}
    />,
      dcfcCost: <FormattedMessage
      id="costsPerHour"
      defaultMessage="{lowCost}/hour - {highCost}/hour"
      values= {{
        lowCost: "$5",
        highCost: "$6"
      }}
    />,
      href: "https://www.opconnect.com/press/"
    },
    {
      network: "SemaConnect",
      l2Stations: "1,598",
      dcfcStations: "0",
      l2Cost: intl.formatMessage ? intl.formatMessage({ id: "variesByLocation", defaultMessage: "Varies by location"}): "",
      dcfcCost: "",
      href: "https://semaconnect.com/"
    },
    {
      network: "Tesla",
      l2Stations: "3,771",
      dcfcStations: "887",
      l2Cost: intl.formatMessage ? intl.formatMessage({ id: "variesByLocation", defaultMessage: "Varies by location"}): "",
      dcfcCost: "",
      href: "https://www.tesla.com/supercharger"
    },
    {
      network: "Volta",
      l2Stations: "588",
      dcfcStations: "1",
      l2Cost: intl.formatMessage ? intl.formatMessage({ id: "free", defaultMessage: "Free"}): "",
      dcfcCost: "",
      href: "https://voltacharging.com/"
    },
    {
      network: "Webasto",
      l2Stations: "59",
      dcfcStations: "56",
      l2Cost: <FormattedMessage
        id="flatFeePerSession"
        defaultMessage="Flat fee - {cost}/session"
        values= {{
          cost: "$4.00"
        }}  
      />,
      dcfcCost: <FormattedMessage
        id="dollarPerSession"
        defaultMessage="$7.50/session"
      />,
      href: "https://www.evsolutions.com/"
    },
  ]

  const renderMobileChargingNetworks = chargingNetworks.map(({network, l2Stations, dcfcStations, l2Cost, dcfcCost, href}, i) => {
    const totalChargers = () => {
      const noCommaArray = [l2Stations, dcfcStations].map(num => {
        const noComma = num.replace(/,/g, '')
          return parseInt(noComma)
      })
      const total = noCommaArray[0] + noCommaArray[1]
      return total > 999 ? FormatAsThousands(total) : total
    }

    const headLine = (
      <>
      <div className="collapse-head d-flex justify-content-between">
        <div className="pl-4">
          <img src={orangePopOut} alt="orange pop out"/> &nbsp; 
          <a
            href={href}
            target="_blank"
            rel="noopener noreferrer"
          >
            {network}
          </a>
        </div>
        <div className="pr-4">
          {totalChargers()}
        </div>
      </div>
      <hr className="charging-speed-divider"/>
      </>

    )
    const contentBody = (
      <div className="network-body">
        <span className="pb-1"><b style={{textTransform: "uppercase"}}>{intl.formatMessage ? intl.formatMessage({ id: "costPerCharge", defaultMessage: "Cost Per Charge"}): ""}</b></span>
        <div className="d-flex justify-content-between pt-1">
          <span><b>L2</b></span>
          <span className="text-right">{l2Cost}</span>
        </div>
        {dcfcCost && 
          <div className="d-flex justify-content-between">
            <span><b>DCFC</b></span>
            <span className="text-right">{dcfcCost}</span>
          </div>
        }
      </div>
    )
    return (
      <CollapsibleSection key={i} headLine={headLine}> 
        {contentBody}
      </CollapsibleSection>
    )
  })

  const chargingNetworkMobile = (
    <div className=" charging-network-mobile faqContainer flex-column">
      <div className="d-flex flex-row justify-content-around charging-network-titles">
        <span>
          <FormattedMessage
            id="network"
            defaultMessage="Network"
            description="Network"
          />
        </span>
        <span>
          <FormattedMessage
            id="numOfStations"
            defaultMessage="# OF STATIONS"
            description="Number of Stations"
          />
        </span>
      </div>
      <hr className="charging-speed-divider"/>
      {renderMobileChargingNetworks}
    </div>
  )

  const renderChargingNetworks = chargingNetworks.map(({network, l2Stations, dcfcStations, l2Cost, dcfcCost, href}, i) => {
    return (
      <div key={i} className="row charging-network-table">
        <div className="col-3 p-2 network">
          <img src={orangePopOut} alt="orange pop out"/> &nbsp; 
          <a
            href={href}
            target="_blank"
            rel="noopener noreferrer"
            style={{padding: "8px 0"}}
          >
            {network}
          </a>
        </div>
        <div className="col-2 p-2 l2stations text-center">
          {l2Stations}
        </div>
        <div className="col-2 p-2 dcfcStations text-center">
          {dcfcStations}
        </div>
        {dcfcCost ? 
          <>
          <div className="col-3 p-2 l2CostDivided text-center">
            {l2Cost}
          </div>
          <div className="col-2 p-2 dcfcCostDivided text-center">
            {dcfcCost}
          </div>
          </>
          :
          <div className="col-5 p-2 l2Cost">
          {l2Cost}
        </div>
          }
      </div>
    )
  })

  const chargingNetworkTable = (
    <div className="charging-network-table mb-3">
      <div className="row charging-network-header">
        <div className="col-3 p-2 d-flex align-items-center heading">
          <FormattedMessage
            id="network"
            defaultMessage="Network"
            description="Network"
          />
        </div>
        <div className="col-2 p-2 text-center heading">
          L2 <br /> {intl.formatMessage ? intl.formatMessage({ id: "stations", defaultMessage: "Stations"}): ""}
        </div>
        <div className="col-2 p-2 text-center heading">
          DCFC <br /> {intl.formatMessage ? intl.formatMessage({ id: "stations", defaultMessage: "Stations"}): ""}
        </div>
        <div className="col-3 p-2 text-center heading">
          L2 <br /> {intl.formatMessage ? intl.formatMessage({ id: "costPerCharge", defaultMessage: "Cost Per Charge"}): ""}
        </div>
        <div className="col-2 p-2 text-center heading">
          DCFC <br /> {intl.formatMessage ? intl.formatMessage({ id: "costPerCharge", defaultMessage: "Cost Per Charge"}): ""}
        </div>
      </div>
      {renderChargingNetworks}
    </div>
  )

  return (
    <>
      <section id="chargingMapBasics" className="container-fluid d-none d-lg-block d-xl-block d-md-block">
        <div className="container">
            <div className="row justify-content-center mt-3 pt-4">
              <h3 className="pageHeaderLead">
                <FormattedMessage
                  id="publicChargingStationBasics"
                  defaultMessage="Public Charging Station Basics"
                  description="Public Charging Station Basics"
                />
              </h3>
            </div>
            <div className="row">
              <div className="col-5 faqContainer">
                <div className="row my-5 d-flex " >
                  <div className={`col-md-12 faq`}>
                    <FormattedMessage
                      id="publicChargingFAQ"
                      defaultMessage="Public Charging FAQs"
                      description="Public Charging FAQs"
                    />
                  </div>
                </div>
              {renderFaq}
              </div>
              <div className="col-7 charging-speed-network">
                <div className="row my-5 d-flex" >
                  <div className={`ev-charging-speed`}>
                    <div className="title">
                    <FormattedMessage
                      id="evChargingSpeed"
                      defaultMessage="EV Charging Speed"
                      description="EV Charging Speed"
                    />
                    </div>
                  </div>
                </div>
                <div>
                  {renderChargingSpeeds}
                  <span className="disclaimer">
                    <FormattedMessage
                      id="chargingSpeedDisclaimer"
                      defaultMessage="Above figures are representative of a Battery Electric Vehicle (BEV) with a 62 kW battery capacity and a Plug-in Hybrid Vehicle (PHEV) with a 14kW battery capacity. Both vehicles have an EPA Rating of 31 kWh per 100 miles."
                      description="Charging Speed Disclaimer"
                    />
                  </span>
                </div>
                <div className="row my-5 d-flex" >
                  <div className={`national-public-charging-networks`}>
                    <div className="title">
                      <FormattedMessage
                        id="nationalPublicChargingNetworks"
                        defaultMessage="National Public Charging Networks"
                        description="National Public Charging Networks"
                      />
                    </div>
                  </div>
                </div>
                {chargingNetworkTable}
              </div>
            </div>
          </div>
      </section>
      {/* Ipad and Mobile View */}
      <section id="chargingMapBasicsMobile" className="container-fluid d-md-none d-lg-none d-xl-none p-0 charging">
        <div className="container">
          <div className="row justify-content-center mt-3 pt-4">
            <h3 className="pageHeaderLead">
              <FormattedMessage
                id="publicChargingStationBasics"
                defaultMessage="Public Charging Station Basics"
                description="Public Charging Station Basics"
              />
            </h3>
          </div>
            <div className={`row ev-charging-speed`}>
              EV Charging Speed
            </div>
            <div>
              {renderMobileChargingSpeeds}
              <span className="disclaimer">
                <FormattedMessage
                  id="chargingSpeedDisclaimer"
                  defaultMessage="Above figures are representative of a Battery Electric Vehicle (BEV) with a 62 kW battery capacity and a Plug-in Hybrid Vehicle (PHEV) with a 14kW battery capacity. Both vehicles have an EPA Rating of 31 kWh per 100 miles."
                  description="Charging Speed Disclaimer"
                />
              </span>
            </div>
            <div className={`row faq public-charging-faq`}>
              <FormattedMessage
                id="publicChargingFAQ"
                defaultMessage="Public Charging FAQs"
                description="Public Charging FAQs"
              />
            </div>
            <div className="faqContainer">
              {renderFaq}
            </div>
          <div className="row d-flex flex-column" >
            <div className={`national-public-charging-networks`}>
              <FormattedMessage
                id="nationalPublicChargingNetworks"
                defaultMessage="National Public Charging Networks"
                description="National Public Charging Networks"
              />
            </div>
            {chargingNetworkMobile}
          </div>
        </div>
      </section>
    </>
  );
};
export default ChargingMapBasics;

ChargingMapBasics.propTypes = {
  ip: PropTypes.string,
  uuid: PropTypes.string,
  props: PropTypes.object
};