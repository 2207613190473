const MaintenanceCost = {
  maintenanceCostPerGasMile: function (
    vehicle,
    milesDrivenAnnually,
    monthsOfOwnership,
    maintenanceData
  ) {
    if (!maintenanceData) return 0;
    var yearsOfOwnership = monthsOfOwnership / 12;
    var totalMaintenanceCost = 0;
    for (const item of maintenanceData) {
      if (item.year_reference <= yearsOfOwnership) {
        totalMaintenanceCost += item.annual_cost;
      } else {
        break;
      }
    }
    var totalMilesDriven = milesDrivenAnnually * yearsOfOwnership;

    return isFinite(totalMaintenanceCost / totalMilesDriven)
      ? totalMaintenanceCost / totalMilesDriven
      : 0;
  },

  maintenanceCostPerElectricMile: function (
    vehicle,
    milesDrivenAnnually,
    monthsOfOwnership,
    maintenanceData
  ) {
    if (!maintenanceData) return 0;
    var yearsOfOwnership = monthsOfOwnership / 12;
    var maintenanceCostPerYear = 0;
    for (const item of maintenanceData) {
      if (item.year_reference <= yearsOfOwnership) {
        maintenanceCostPerYear += item.annual_cost;
      } else {
        break;
      }
    }
    var totalMaintenanceCost = maintenanceCostPerYear * yearsOfOwnership;
    var totalMilesDriven = milesDrivenAnnually * yearsOfOwnership;

    return isFinite(totalMaintenanceCost / totalMilesDriven)
      ? totalMaintenanceCost / totalMilesDriven
      : 0;
  },

  perMile: function (
    vehicle,
    milesDrivenAnnually,
    monthsOfOwnership,
    electricMilesPortionForPhev,
    maintenanceData
  ) {
    if (!vehicle) return 0;

    let fuelType = vehicle && vehicle.fuel ? vehicle.fuel : "";

    let cost = this.maintenanceCostPerGasMile(
      vehicle,
      milesDrivenAnnually,
      monthsOfOwnership,
      maintenanceData
    ); // ICE cost
    if (["BEV"].includes(fuelType.toUpperCase()))
      cost = this.maintenanceCostPerElectricMile(
        vehicle,
        milesDrivenAnnually,
        monthsOfOwnership,
        maintenanceData
      );
    if (["PHEV"].includes(fuelType.toUpperCase())) {
      cost =
        (electricMilesPortionForPhev / 100) *
          this.maintenanceCostPerElectricMile(
            vehicle,
            milesDrivenAnnually,
            monthsOfOwnership,
            maintenanceData
          ) +
        (1 - electricMilesPortionForPhev / 100) *
          this.maintenanceCostPerGasMile(
            vehicle,
            milesDrivenAnnually,
            monthsOfOwnership,
            maintenanceData
          );
    }

    return isFinite(cost) ? cost : 0;
  },
};

export default MaintenanceCost;
