import React, { useContext } from "react";

import PropTypes from "prop-types";
import UserPrefsContext from "../../context/UserPrefs/UserPrefsContext"
import { Link } from "react-router-dom";

import UsedEVCard from "../UsedEVCard/UsedEVCard"
import sortEVs from "../../functions/vehicle/Sort/sortEVs"
import filterEVs from "../../functions/vehicle/Filter/filterEVs"
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner"
import calcMatchScore from "../../functions/vehicle/MatchScore/calcMatchScore"
import {FormatAsInt} from "../../utils/Helpers/Format"
import sortIncentives from "../../functions/incentives/Sort/sortIncentives"

const UsedEVCatalog = ({
  vehicles,
  hasIncentivesAndMatchScore,
  hasEvDetails,
  hasLinktoEv
}) => {
  const userPrefs = useContext(UserPrefsContext);
  const usedEvsBundle = {}

  if (!vehicles) return <LoadingSpinner />;
  
  // Helper Fuunction to determine match score
  const matchScore = (ev) => {
    return calcMatchScore(
      ev,
      userPrefs.get("milesDrivenDaily"),
      userPrefs.get("chargerLevel"),
      userPrefs.get("maxBudget"),
      userPrefs.get("minSeats"),
      userPrefs.get("monthsOfOwnership"),
      userPrefs.get("interestRateAsBasisPoints"),
      userPrefs.get("milesDrivenAnnually"),
      userPrefs.get("salesTax")
    );
  }

  const filteredElectricVehicles = filterEVs(vehicles, {
    vehicleFormFactorFilters: userPrefs.get("vehicleFormFactorFilters"),
    vehicleFuelTypeFilters: userPrefs.get("vehicleFuelTypeFilters"),
    vehicleMakeFilter: userPrefs.get("vehicleMakeFilter"),
    vehicleAgeFilter: userPrefs.get("vehicleAgeFilter")
  });


  filteredElectricVehicles.forEach(ev => {
    const displayName = ev.make + ev.model + ev.trim
    if (!usedEvsBundle[displayName]) {
      usedEvsBundle[displayName] = []
    }
    usedEvsBundle[displayName].push(ev)
  })
  
  const usedIncentivesSavingsCalc = car => {
    const usedIncentives = car.incentives.filter(incentive => incentive.applicable_to_used_vehicles)
    return sortIncentives(usedIncentives).total("eligible-rebates");
  }

  const renderUsedEvs = Object.values(usedEvsBundle).map(evBundle => {
    const images = evBundle[0].images
    const make = evBundle[0].make
    const model = evBundle[0].model
    const trim = evBundle[0].trim
    const seatsMax = evBundle[0].seats_max
    const fuel = evBundle[0].fuel
    let usedIncentivesSavings = usedIncentivesSavingsCalc(evBundle[0])

    if (evBundle.length === 1) {
      const ev = evBundle[0]
      return { 
        yearRange: ev.model_year, 
        msrp: ev.msrp, 
        matchScore: matchScore(ev),
        images: images,
        make: make,
        model: model,
        trim: trim,
        electric_range: ev.electric_range,
        lowerBoundMsrp: ev.lowerBoundMsrp,
        upperBoundMsrp: ev.upperBoundMsrp,
        total_range: ev.total_range,
        electric_efficiency: ev.electric_efficiency,
        seats_max: seatsMax,
        fuel: fuel,
        usedIncentivesSavings: usedIncentivesSavings
      }
    }
    
    let lowerYearRange = evBundle[0].model_year
    let higherYearRange = evBundle[0].model_year
    let lowerPriceRange = evBundle[0].msrp
    let higherPriceRange = evBundle[0].msrp
    let matchScoreSum = {
      budgetAdjustment: 0,
      charging: 0,
      range: 0,
      seats: 0,
      total: 0
    }
    let electricRange = 0
    let msrp = 0
    let totalRange = 0
    let electricEfficiency = 0
    usedIncentivesSavings = 0


    evBundle.forEach(ev => {
      if (ev.model_year < lowerYearRange) {
        lowerYearRange = ev.model_year
      }

      if (ev.model_year > higherYearRange) {
        higherYearRange = ev.model_year
      }

      if (ev.lowerBoundMsrp < lowerPriceRange) {
        lowerPriceRange = ev.lowerBoundMsrp
      }

      if (ev.upperBoundMsrp > higherPriceRange) {
        higherPriceRange = ev.upperBoundMsrp
      }

      for (const key in matchScoreSum) {
        matchScoreSum[key] += matchScore(ev)[key]
      }
      electricRange += ev.electric_range 
      msrp += ev.msrp
      totalRange += ev.total_range
      electricEfficiency += ev.electric_efficiency
      usedIncentivesSavings += usedIncentivesSavingsCalc(ev)
    })

    for (const key in matchScoreSum) {
      matchScoreSum[key] = FormatAsInt(matchScoreSum[key] /= evBundle.length) 
    }

    return {
      yearRange: `${lowerYearRange} - ${higherYearRange}`, 
      msrp: Math.round(msrp / evBundle.length),
      lowerBoundMsrp: lowerPriceRange,
      upperBoundMsrp: higherPriceRange,
      matchScore: matchScoreSum,
      images: images,
      make: make,
      model: model,
      trim: trim,
      electric_range: Math.round(electricRange / evBundle.length),
      total_range: Math.round(totalRange / evBundle.length),
      electric_efficiency: Math.round(electricEfficiency / evBundle.length),
      seats_max: seatsMax,
      fuel: fuel,
      usedIncentivesSavings: Math.round(usedIncentivesSavings / evBundle.length)
    }
  })

  let filteredAndSortedElectricVehicles = sortEVs(
    renderUsedEvs,
    userPrefs.get("vehicleSortType"),
    userPrefs.get("vehicleSortDirection"),
    userPrefs.get("milesDrivenDaily"),
    userPrefs.get("chargerLevel"),
    userPrefs.get("maxBudget"),
    userPrefs.get("minSeats"),
  );

  if (userPrefs.get("vehicleSortType") === "match_score") {
    filteredAndSortedElectricVehicles = filteredAndSortedElectricVehicles.sort( (a, b) => {
      return b.matchScore.total - a.matchScore.total
    })
  }  

  if (!filteredAndSortedElectricVehicles.length) {
    return (
      <div className="text-center">
        <p className="h4">No vehicles found</p>
      </div>
    );
  }

  const renderCards = filteredAndSortedElectricVehicles.map((ev, index) => {
    return (
      <Link
        to={`${`/used-vehicles/${ev.make.replace(/ /g,"_")}:${ev.model.replace(/ /g,"_")}:${ev.trim.replace(/ /g,"_")}`}`}
        className="evc-card EVCard"
        key={index}
      >
        <UsedEVCard
          ev={ev}
          hasEvDetails={hasEvDetails}
          hasIncentivesAndMatchScore={hasIncentivesAndMatchScore}
          hasLinktoEv={hasLinktoEv}
          hasLocallyAvailableHidden={true}
        />
      </Link>
    );
  });

  return <div className="render-cards-container">{renderCards}</div>;
};

export default UsedEVCatalog;

UsedEVCatalog.propTypes = {
  vehicles: PropTypes.array,
  hasEvDetails: PropTypes.bool,
  hasIncentivesAndMatchScore: PropTypes.bool,
  hasLinktoEv: PropTypes.bool
};
