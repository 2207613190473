import React from "react";
import PropTypes from "prop-types";
import backgroundImg from "../../../assets/images/banner.jpg";
import {FormattedMessage} from 'react-intl';


const HomepageBigPromise = ({
  homePageBannerImage
}) => {
  return (
  <section
    className="container-fluid homepage-jumbotron"
    style={{
      backgroundImage: "url(" + backgroundImg + ")",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center center",
      backgroundColor: 'rgba(0,0,0,0.3)',
      WebkitBackgroundSize: "cover",
      MozBackgroundSize: "cover",
      OBackgroundSize: "cover",
      backgroundSize: "cover",
      padding: 0,
      textAlign: "center"
    }}
  >
    <div className="big-promise">
      <div className="hp-header-holder text-center">
        <h1 className="mt-4 mb-4 hp-welcome text-center">
          <FormattedMessage 
            id="homepage.welcome"
            defaultMessage="Welcome to EV Assistant"
            description="Welcome to EV Assistant"
          />
        </h1>
        <div className="line"></div>
        <p className="mt-4 hp-welcome-sub">
          <FormattedMessage 
            id="homepage.welcomeSub"
            defaultMessage="Browse and compare cars. {lineBreak} Discover electric vehicle incentives and savings. {lineBreak} Explore charging options for home and on the road."
            description="Homepage Welcome Subtitle"
            values= {{
              lineBreak: <br />
            }} 
          />
        </p>
      </div>
    </div>
  </section>
    
  );
};

export default HomepageBigPromise;

HomepageBigPromise.propTypes = {
  homePageBannerImage: PropTypes.string
}