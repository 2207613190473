import React from "react";

import { Link } from "react-router-dom";
import "./NewUsedEVFilter.scss";
import { FormattedMessage } from "react-intl";

const NewUsedEVFilter = ({ value }) => {
  return (
    <div className="filter-btns">
      <Link to={`/vehicles`}>
        <button
          className={
            "ev-filter-btn " +
            (value === "new" ? "buttonActiveStyle" : "buttonInactiveStyle")
          }
          value="new"
        >
          <FormattedMessage
            id="vehicles.new"
            defaultMessage="New"
            description="New Option"
          />
        </button>
      </Link>
      <Link to={`/used-vehicles`}>
        <button
          className={
            "ev-filter-btn " +
            (value === "used" ? "buttonActiveStyle" : "buttonInactiveStyle")
          }
          value="used"
        >
          <FormattedMessage
            id="vehicles.used"
            defaultMessage="Pre-owned"
            description="Used Option"
          />
        </button>
      </Link>
    </div>
  );
};
export default NewUsedEVFilter;
