import React from "react";
import PropTypes from "prop-types";

import LocationCard from "../LocationCard/LocationCard";
import ShowHideButton from '../../../../components/ShowHideButton/ShowHideButton';

import "./LocationsList.scss";

const LocationsList = ({ locations, buttonText, type }) => {  
  return (
    <div className="locationsList text-center" id="show-charging-stations-button">
      <ShowHideButton buttonText={buttonText}>
        <div className="location-cards-container">
          <div className="row">
            <div className="col-sm-6 offset-sm-3">
              {locations.map((location, i) => {
                return (
                  <LocationCard
                    key={i}
                    location={location}
                    type={type}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </ShowHideButton>
    </div>
  );
};

LocationsList.propTypes = {
  chargingStations: PropTypes.array
};

LocationsList.defaultProps = {
  chargingStations: []
};

export default LocationsList;
