import React from "react";
import PropTypes from "prop-types";
import "./ChargerCard.scss";

import typeIcon from "../../assets/images/icons/typeIcon.svg"
import cordLengthIcon from "../../assets/images/icons/cordLengthIcon.svg"
import socketIcon from "../../assets/images/icons/socketIcon.svg"
import timeIcon from "../../assets/images/icons/timeIcon.svg"
import wifiIcon from "../../assets/images/icons/wifi_icon.svg"
import whitePopOut from "../../assets/images/icons/whitePopOut.png"

import {FormatAsInt} from "../../../utils/Helpers/Format"

import { FormattedMessage, useIntl } from 'react-intl';

const ChargerCard = ({
  charger,
  selectedVehicle
}) => {
  const intl = useIntl() 
  if (!charger) return null;
  
  const make = charger.make ? charger.make : "";
  const model = charger.model ? charger.model : "";
  const price = charger.price ? charger.price : 0;
  const type = charger.form_factor ? charger.form_factor : "";
  const length = charger.cord_length ? charger.cord_length : 0;
  const socket = charger.home_outlet ? charger.home_outlet : "";
  const details_link = charger.details_link ? charger.details_link : "";
  const wifi = charger.wifi ? charger.wifi : ""
  const gridshift = charger.gridshift;

  const image = charger.img ? charger.img.replace(/ /g,"%20") : "";
  const imgSrc = image

  const chargerTypes = {"Portable" : intl.formatMessage({ id: "homeChargers.portable", defaultMessage: "Portable"}), "Wall mounted" : intl.formatMessage({ id: "homeChargers.wallMounted", defaultMessage: "Wall Mounted"})}

  let altText = `${make} ${model}`
  let vehicleChargingPower = null
  
  if (selectedVehicle) {
    vehicleChargingPower = selectedVehicle.dc_charging_power ? selectedVehicle.dc_charging_power : selectedVehicle.ac_charging_power
  }
  
  const chargingSpeed = Math.min(vehicleChargingPower, charger.amps * 240/1000)

  const renderTeslaWarning = (make) => {
    if(make !== 'Tesla') {
      return;
    }

    return (
      <div className="charger-compatibility-warning">
        <FormattedMessage
          id="homeChargersCard.teslaWarning"
          description="Warning for Tesla chargers" 
        />
      </div>
    )
  }

  const renderMilesPerHourOfCharge = () => {
    if (selectedVehicle) {
      if (make.toLowerCase() === "tesla" && selectedVehicle.make.toLowerCase() === "tesla") {
        return (
          <p>
            <FormattedMessage
              id="milesPerHourOfCharge"
              description="{miles} miles per 1 hour of charge"
              values= {{
                miles: FormatAsInt(chargingSpeed / selectedVehicle.electric_efficiency * 100)
              }}
            />
        </p>
        )
      } else if (make.toLowerCase() === "tesla"){
        return (
          <p>
            <FormattedMessage
              id="evs.chargercard.chargingtime.na"
              description="NA" 
            />
          </p>
        )
      } else {
        return (
          <p>
            <FormattedMessage
              id="milesPerHourOfCharge"
              description="{miles} miles per 1 hour of charge"
              values= {{
                miles: FormatAsInt(chargingSpeed / selectedVehicle.electric_efficiency * 100)
              }}
            />
          </p>
        )
      }
    } else {
      return (
        <p className="please-select-car">
          <FormattedMessage
            id="chargercard.selectMakeModel"
            description="Select Make & Model"
            defaultMessage="*Please select car make & model to see charging times."
            />
        </p>
      )
    }

  }

  const renderChargerDetails = (
    <div className="renderRowOfData">
      <div className="charger-row">
        <img src={typeIcon} alt="wrench"/>
        <p>
          {chargerTypes ? chargerTypes[type] : ""}
        </p>
      </div>
      <div className="charger-row">
        <img src={cordLengthIcon} alt="double-sided arrow"/>
        <p>
          <FormattedMessage 
            id="homeChargers.cable"
            defaultMessage="{length} ft. Cable"
            description="cable"
            values= {{
              length: length
            }}
          />
        </p>
      </div>
      <div className="charger-row">
        <img src={socketIcon} alt="plug"/>
        <p>
          {socket === "Hardwired" ? intl.formatMessage({ id: "homeChargers.hardwired", defaultMessage: "Hardwired"}) :  <FormattedMessage 
            id="homeChargersCard.socket"
            defaultMessage="{socket} Socket"
            description="Socket"
            values= {{
              socket: socket
            }}
          />
          }
        </p>
      </div>
      <div className="charger-row">
        <img src={timeIcon} alt="clock"/>
          {renderMilesPerHourOfCharge()}
      </div>

    </div>
  )

  const renderCardBottom = (
    <div className="ChargerCardBottom">
      <div>
        <p>
          <span className="charger-price">${price} </span>
          <FormattedMessage 
            id="homeChargersCard.beforeIncentives"
            defaultMessage="Before Incentives"
            description="Before Incentives"
          />
        </p>
      </div>
      <div className="buy-charger">
        <a 
            href={details_link}
            target="_blank"
            rel="noopener noreferrer"
        >
            <button className="btn btn-svce"> 
              {intl.formatMessage({ id: "homeChargersCard.buy", defaultMessage: "DETAILS & BUY"})}
              <img className="more-sites-popout" alt="Go to new Tab of Home Charger" src={whitePopOut}/> 
            </button>
        </a>
      </div>
    </div>
  );

  return (
    <>
      {wifi ? 
      <div className={`ChargerCardWifiIcon`}>
        <img src={wifiIcon} alt="Charger is wireless enabled" className="" />
      </div>
      :
      null}
      {gridshift ?
        <div className="GridshiftFlag">
          <span className="OrangeText">GridShift</span> <b>&#10004;</b>
        </div>
        :
      null}
      <div className="ChargerCardTop">
        <p className="h2" style={{ maxWidth: "70%" }}>
          {make}
        </p>
        <p className="h3 mt-1">
          {model}   
        </p>
        <div className="text-center">
            <img src={imgSrc} alt={altText} className="img-fluid" />
        </div>
        {renderChargerDetails}
      </div>
      <div className="row">
          {renderTeslaWarning(make)}
      </div>
      {renderCardBottom}
    </>
  );
};

export default ChargerCard;

ChargerCard.propTypes = {
    charger: PropTypes.object,
    selectedVehicle: PropTypes.object
};
