import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import UserPrefsContext from "../../context/UserPrefs/UserPrefsContext";
import EVIncentives from "./../../components/EVIncentives/EVIncentives";
import EVJumbotron from "../../client_customizations/components/EVJumbotron/EVJumbotron"
import EVReviews from "./../../components/EVReviews/EVReviews";
import ChargingMap from "../../client_customizations/components/LocationMap/ChargingMap"
import CostOfOwnership from "./../../components/CostOfOwnership/CostOfOwnership";
import getPaymentDetails from "./../../functions/vehicle/getPaymentDetails";

import LoadingSpinner from "./../../components/LoadingSpinner/LoadingSpinner";
import isPHEV from "../../functions/vehicle/isPHEV";
import { FormatCarName } from "../../utils/Helpers/Format";

import ad_728x90 from "../../client_customizations/assets/images/ads/ad-728x90.png";

const EV = ({
  match,
  electricVehicles,
  userLocation,
  ip,
  uuid
}) => {
  const userPrefs = useContext(UserPrefsContext);

  let car = null;
  let paymentDetails = {};

  if (electricVehicles) {
    const evId = match ? match.params["evId"] : 0;
    car = electricVehicles.find(ev => ev.handle === evId);
    paymentDetails = getPaymentDetails(
      car,
      userPrefs.get("monthsOfOwnership"),
      userPrefs.get("interestRateAsBasisPoints"),
      userPrefs.get("milesDrivenAnnually"),
      userPrefs.get("salesTax")
    );
  }

  useEffect(() => {
    document.title = car
      ? `${FormatCarName(car)}`
      : "";
  });

  const ignoreSuperchargerStations = car && isPHEV(car);

  const renderEV = electricVehicles ? (
    <>
      <EVJumbotron
        car={car}
        incentives={car.incentives}
        paymentDetails={paymentDetails}
      />
      <CostOfOwnership cars={[car, car.equivalent_gas_vehicle]} />
      <EVIncentives incentives={car.incentives} car={car} />
      <section className="container">
        <ChargingMap
          userLocation={userLocation}
          ignoreSuperchargerStations={ignoreSuperchargerStations}
        />
      </section>
      <EVReviews reviews={car.video_reviews} />

       <div className="container text-center">
              <div className="ad-container" style={{marginBottom: "50px"}}>
                     <div className="ad">
                            <a href="https://googleads.g.doubleclick.net/pcs/click?xai=AKAOjstl8trkQRzhWBvPwvn9S4kuK60S490aTGxJyDFzXTcO34QcBsYeBi8Q2-cLVx6HZEc8MeC69dKDrABSeDx0xYSF0-X6C1rxbTlRYTnVD7PHGw4dA04oBc2ke86wB_pXVfACHQXWv0wMD7GukaeTu3KrYSIjVvgKbRKg6Y2kroR18zF1UcOz6R2gQrGapzeRikIvXGBYPZUwAP_UYuGoBhFbqciiwiarUVgOTMiMZdvC2LGiyioGZYZ_R_avmTj82o95ggZK2tbuy6iQixF7OFJJtvKB-tj83vwCLW3qV8CzisdjIvQto842AYXozOORPjncPhQTxZri7EQs4DD-o5Zh_w&sai=AMfl-YTwwDUOETAoy9wBUZQu4faBJFlMdmnIfGI4SMl4TwfsY1izkP4paCZMmbkahjhfe1OXKSHL227RAicy5H41FTimcdbdkC6Lkk2IcnR-8SFKYbkrCRfSYCR9sbZ0u6vWoLkRxeJE&sig=Cg0ArKJSzIuD9nXzz3PA&fbs_aeid=[gw_fbsaeid]&adurl=https://ev.svcleanenergy.org/&nm=2&nx=279&ny=-36&mb=2" target="_blank"
                            rel="noopener noreferrer">
                                   <img src={ad_728x90} alt="ad" />
                            </a>
                     </div>
              </div>
       </div>
    </>
  ) : (
      <div className="container text-center">
        <LoadingSpinner />
      </div>
    );


  return (
    <>
     { renderEV }
    </>
  );
};

export default EV;

EV.propTypes = {
  match: PropTypes.object,
  electricVehicles: PropTypes.array,
  userLocation: PropTypes.object,
  ip: PropTypes.string,
  uuid: PropTypes.string
};
