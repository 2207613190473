import React from "react";
import PropTypes from "prop-types";
// import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext"
import { Link } from "react-router-dom";
import "./UsedEVCard.scss";

import RenderItem from "../RenderItem/RenderItem"
import VehicleImage from "../VehicleImage/VehicleImage"

import { FormatInThousands, FormatCarModelAndTrim } from "../../utils/Helpers/Format"


import IconBEV from "../../client_customizations/assets/images/icons/icon-electric.png"
import IconPHEV from "../../client_customizations/assets/images/icons/icon-hybrid.png"
import IconLocal from "../../client_customizations/assets/images/icons/icon-local.png";
import isBEV from "../../functions/vehicle/isBEV"
import isPHEV from "../../functions/vehicle/isPHEV";

import { FormattedMessage } from "react-intl";

import { useIntl } from 'react-intl';

const UsedEVCard = ({
  ev,
  hasEvDetails,
  hasIncentivesAndMatchScore,
  hasLinkToEv,
  hasLocallyAvailableHidden
}) => {
  const intl = useIntl()
  
  if (!ev) return null;

  let evImgs = ev.images ? ev.images.map( (n) =>
                (n.url_thumbnail == null) ?  [] : 
                (n.url_full == null) ?  []: 
                [n]) : [];

  //Flatten evImgs into a single array                  
  const evImgSrc = [].concat.apply([], evImgs);

  const imgSrc = ev.images ? (ev.images.length >= 0 ? evImgSrc[0] : "") : "";

  

  const renderLocalIcon = ev.locally_available ? (
    <span className="badge-locally-available">
      <img alt="Available Locally" src={IconLocal} />
    </span>
  ) : null;

  const renderFuelTypeBadge = isBEV(ev) ? (
    <span className="badge-fuel-type">
      <img alt="All-Electric" src={IconBEV} />
    </span>
  ) : isPHEV(ev) ? (
    <span className="badge-fuel-type">
      <img alt="Hybrid" src={IconPHEV} />
    </span>
  ) : null;

  let altText = "Go to " +(ev.make + " " + ev.model + " " + ev.trim).toString().trim(); 


  const renderEVDetails = hasEvDetails ? (
    <div className="renderRowOfData">
      <p>
        <small>
          <FormattedMessage
            id="evCard.yearRange"
            description="Year Range"
            defaultMessage="Year Range Title"
          />
        </small>
        <span>{ev.yearRange}</span>
      </p>
      <p>
        <small>
          <FormattedMessage
            id="evCard.electricRangeAverage"
            description="Electric Range Average"
            defaultMessage="Electric Range Title"
          />
        </small>
        <span>{ev.electric_range}</span>
      </p>
    </div>
  ) : null;

  const renderCardBottom = hasIncentivesAndMatchScore ? (
    <div className="EVCardBottom">
      <div>
        <RenderItem
          title={intl.formatMessage({
            id: "vehicle.afterIncentives",
            defaultMessage: "AFTER INCENTIVES",
          })}
          value={
            ev.lowerBoundMsrp
              ? `${FormatInThousands(
                  ev.lowerBoundMsrp - ev.usedIncentivesSavings
                )} - ${FormatInThousands(
                  ev.upperBoundMsrp - ev.usedIncentivesSavings
                )}`
              : ev.msrp
          }
        />
      </div>
      <div>
        <RenderItem
          title={intl.formatMessage({
            id: "evCard.matchScore",
            defaultMessage: "MATCH SCORE",
          })}
          value={ev.matchScore}
          matchScore
        />
      </div>
    </div>
  ) : (
    hasLinkToEv && (
      <div className="EVCardBottomWithLink">
        <Link
          to={`${"/vehicles/" + ev.handle}`}
          className="btn btn-ae"
          target="_blank"
          style={{ outline: "none" }}
        >
          {intl.formatMessage({
            id: "evCard.seeDetails",
            defaultMessage: "SEE DETAILS",
          })}
        </Link>
      </div>
    )
  );

  return (
    <>
      <div className="EVCardTop">
        {!hasLocallyAvailableHidden && renderLocalIcon}
        {renderFuelTypeBadge}
        <p className="h2" style={{ maxWidth: "70%" }}>
          {ev.make}
        </p>
        <p className="h3 mt-1">
          {FormatCarModelAndTrim(ev)}   
        </p>
        <div className="text-center">
          <VehicleImage image={imgSrc} size="thumb" alt={altText} />
        </div>
        {renderEVDetails}
      </div>
      {renderCardBottom}
    </>
  );
};

export default UsedEVCard;

UsedEVCard.propTypes = {
  ev: PropTypes.object,
  hasEvDetails: PropTypes.bool,
  hasIncentivesAndMatchScore: PropTypes.bool,
  hasLinkToEv: PropTypes.bool,
  hasLocallyAvailableHidden: PropTypes.bool
};
