import React from "react";
import PropTypes from "prop-types";
import "./EVReviews.scss";

import VideoReview from "./../VideoReview/VideoReview";

const EVReviews = ({ reviews }) => {
  if (!reviews || reviews.length === 0) return null;
  return (
    <section className="container">
      <div className="EVReviews" id="EVReviews">
        <div className="row">
          <div className="col-sm-12">
            <p className="h3">Reviews from around the web</p>
          </div>
        </div>
        <div className="row">
          {reviews.map((review, index) => {
            return <VideoReview link={review.video_url} title={review.reviewer} key={index} />;
          })}
        </div>
      </div>
    </section>
  );
};

export default EVReviews;

EVReviews.propTypes = {
  reviews: PropTypes.array
};
