import React, { useContext } from "react";
import PropTypes from "prop-types";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";

import Select from "../../shared/InputElements/Select";
import {useIntl} from 'react-intl';

const SelectHouseholdSize = ({
  id = "household-size",
  ...rest
}) => {
  const intl = useIntl()
  const userPrefs = useContext(UserPrefsContext);
  const label = intl.formatMessage({ id: "incentives.householdSize", defaultMessage: "Household Size"})

  return (
    <Select
      id={id}
      value={userPrefs.get("householdSize").toString()}
      label={label}
      optionNames={["1", "2", "3", "4", "5", "6", "7", "8"]}
      optionValues={[1, 2, 3, 4, 5, 6, 7, 8]}
      handler={e => userPrefs.set({ householdSize: e.target.value })}
      tooltip={'Include all members of your household (spouse, dependents, etc). Used to determine incentive eligibility. Few to no incentives mention households larger than 8 members, so select 8 if your family is any larger.'}
    />
  );
};

export default SelectHouseholdSize;

SelectHouseholdSize.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  tooltip: PropTypes.string
};