import React from "react";
import PropTypes from "prop-types";
import TabbedMaps from '../TabbedMaps/TabbedMaps';

import useMapTabs from '../../../hooks/useMapTabs';

const MapPage = ({ userLocation, ip, uuid, history, dealerLocations, tabId, title, zipcode }) => {

  const { activeTabId, toggle, findTab } = useMapTabs(tabId);

  const toggleWithHistory = newTabId => {
    const newTab = findTab(newTabId);
    history.push(newTab.url);
    toggle(newTab.id);
  };

  return (
    <section className="container-fluid" style={{backgroundColor: "#f2f2f2"}}>
      <div className="container">
        <h1 className="hide-offscreen">Map</h1>
      
        <TabbedMaps
          toggle={toggleWithHistory}
          activeTabId={activeTabId}
          userLocation={userLocation}
          dealerLocations={dealerLocations}
          zipcode={zipcode}
        />
      </div>
    </section>
  );
};

export default MapPage;

MapPage.propTypes = {
  userLocation: PropTypes.object,
  ip: PropTypes.string,
  uuid: PropTypes.string
};
