export const LANGUAGE_TABS = [
    {
        route: "/",
        language: "EN"
    },
    {
        route: "/es",
        language: "ES"
    },
    {
        route: "/vi",
        language: "VI"
    },
    {
        route: "/zh",
        language: "ZH-HANT"
    },
];
