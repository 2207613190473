import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// import UserPrefsContext from "../../context/UserPrefs/UserPrefsContext";
import EVIncentives from "../../components/EVIncentives/EVIncentives";
import EVJumbotron from "../../client_customizations/components/EVJumbotron/EVJumbotron";
import EVReviews from "../../components/EVReviews/EVReviews";
import ChargingMap from "../../client_customizations/components/LocationMap/ChargingMap";
import CostOfOwnership from "../../components/CostOfOwnership/CostOfOwnership";
// import getPaymentDetails from "../../functions/vehicle/getPaymentDetails";

import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import isPHEV from "../../functions/vehicle/isPHEV";
// import { FormatCarName } from "../../utils/Helpers/Format";
// import { set } from "react-ga";

import ad_728x90 from "../../client_customizations/assets/images/ads/ad-728x90.png";

const UsedEV = ({
  match,
  electricVehicles,
  userLocation,
  ip,
  uuid
}) => {
  // const userPrefs = useContext(UserPrefsContext);
  const [usedEv, setUsedEv] = useState(null)
  const [usedEvs, setUsedEvs] = useState(null)

  // let usedEvs = []
  let car
  // let make
  // let model
  // let allUsedEvs

  // console.log(make)
  // const setElectricVehicles = () => {
  //   if (electricVehicles) {
  //   const evId = match ? match.params["evId"].split(":") : "";
  //   make = evId[0]
  //   model = evId[1].replace(/_/g, ' ')

  //   allUsedEvs = electricVehicles.filter(ev => {
  //     return ev.make === make && ev.model === model
  //   })
  //   setUsedEv(allUsedEvs[0])
  //   setUsedEvs(allUsedEvs)
  //   }
  // } 

  useEffect(() => {

    const setElectricVehicles = () => {
      if (electricVehicles) {
      const evId = match ? match.params["evId"].split(":") : "";
      const make = evId[0].replace(/_/g, ' ')
      const model = evId[1].replace(/_/g, ' ')
      const trim = evId[2].replace(/_/g, ' ')
  
      const allUsedEvs = electricVehicles.filter(ev => {
        return ev.make === make && ev.model === model && ev.trim === trim
      })
      setUsedEv(allUsedEvs[0])
      setUsedEvs(allUsedEvs)

      document.title = make
      ? `Used Vehicle: ${make} ${model}`
      : "";
      }
    } 
    setElectricVehicles()
  }, [electricVehicles, match]);
  
  const ignoreSuperchargerStations = car && isPHEV(car);

  const renderEV = usedEv ? (
    // console.log(usedEvs)
    <>
      <EVJumbotron
        car={usedEv}
        incentives={usedEv.incentives}
        cars={usedEvs}
        setUsedEv={setUsedEv}
      />
      <CostOfOwnership cars={[usedEv, usedEv.equivalent_gas_vehicle]} usedEv={true} />
      <EVIncentives incentives={usedEv.incentives} car={usedEv} usedEv={true} />
      <section className="container">
        <ChargingMap
          userLocation={userLocation}
          ignoreSuperchargerStations={ignoreSuperchargerStations}
        />
      </section>
      <EVReviews reviews={usedEv.video_reviews} />

      <div className="container text-center">
              <div className="ad-container" style={{marginBottom: "50px"}}>
                     <div className="ad">
                            <a href="https://googleads.g.doubleclick.net/pcs/click?xai=AKAOjstl8trkQRzhWBvPwvn9S4kuK60S490aTGxJyDFzXTcO34QcBsYeBi8Q2-cLVx6HZEc8MeC69dKDrABSeDx0xYSF0-X6C1rxbTlRYTnVD7PHGw4dA04oBc2ke86wB_pXVfACHQXWv0wMD7GukaeTu3KrYSIjVvgKbRKg6Y2kroR18zF1UcOz6R2gQrGapzeRikIvXGBYPZUwAP_UYuGoBhFbqciiwiarUVgOTMiMZdvC2LGiyioGZYZ_R_avmTj82o95ggZK2tbuy6iQixF7OFJJtvKB-tj83vwCLW3qV8CzisdjIvQto842AYXozOORPjncPhQTxZri7EQs4DD-o5Zh_w&sai=AMfl-YTwwDUOETAoy9wBUZQu4faBJFlMdmnIfGI4SMl4TwfsY1izkP4paCZMmbkahjhfe1OXKSHL227RAicy5H41FTimcdbdkC6Lkk2IcnR-8SFKYbkrCRfSYCR9sbZ0u6vWoLkRxeJE&sig=Cg0ArKJSzIuD9nXzz3PA&fbs_aeid=[gw_fbsaeid]&adurl=https://ev.svcleanenergy.org/&nm=2&nx=279&ny=-36&mb=2" target="_blank"
                            rel="noopener noreferrer">
                                   <img src={ad_728x90} alt="ad" />
                            </a>
                     </div>
              </div>
       </div>
    </>
  ) : (
      <div className="container text-center">
        <LoadingSpinner />
      </div>
    );

  return (
    <>
     { renderEV }
    </>
  );
};

export default UsedEV;

UsedEV.propTypes = {
  match: PropTypes.object,
  electricVehicles: PropTypes.array,
  userLocation: PropTypes.object,
  ip: PropTypes.string,
  uuid: PropTypes.string
};
