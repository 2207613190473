export default {
  electricVehicles: "新電動車",
  usedElectricVehicles: "二手電動車",
  compareVehicles: "比較車輛",
  incentives: "激勵措施",
  "": "",
  chargingStations: "充電站",
  homeChargers: "家居充電裝置",
  dealers: "經銷商",
  newVehicles: "新車輛",
  electricians: "電工",
  electricity: "電力",
  gasoline: "汽油",
  total: "總計",
  description: "說明",
  detailedCalculations: "計算詳情",
  charging: "充電",
  emissions: "排放",
  source: "來源",
  vehicles: "車輛",
  make: "品牌",
  model: "型號",
  all: "所有",
  age: "年齡",
  zipcode: "郵遞區號",
  personalizeIncentives: "個人化激勵措施",
  "vehicle.miles": "英哩數",
  "vehicle.trees": "樹木",
  "vehicle.view": "檢視",
  "vehicle.more": "更多",
  "vehicle.fewer": "更少",
  "vehicle.greater": "更大",
  "vehicle.plugInHybrid": "插電式混合動力",
  "vehicle.allElectric": "全電動",
  "vehicle.gas": "汽油",
  "vehicle.afterIncentives": "計算激勵金後",
  "vehicle.msrp": "MSRP",
  "vehicle.estimatedIncentives": "估計的激勵金",
  "vehicle.type": "類型",
  "vehicle.fuelType": "燃料類型",
  "vehicle.batterySize": "電池大小",
  "vehicle.electricRange": "電動續航里程",
  "vehicle.timeToChargeLvlTwo": "充電所需時間-第二級",
  "vehicle.milesPerThirtyFastCharge": "每快速充電 30 分鐘可行駛的英哩數",
  "vehicle.coTwoEmissions": "二氧化碳減排",
  "vehicle.phev": "電力及汽油",
  "vehicle.bev": "電力",
  "vehicle.age.one": "0-1 years old",
  "vehicle.age.two": "2-3 years old",
  "vehicle.age.four": "4+ years old",
  "vehicle.type.sedan": "轎車",
  "vehicle.type.hatchback": "揭背車",
  "vehicle.type.coupe": "轎跑車",
  "vehicle.type.crossover": "跨界車",
  "vehicle.type.minivan": "轎式休旅車",
  "vehicle.type.suv": "SUV",
  "vehicle.type.wagon": "旅行車",
  "vehicle.type.truck": "卡車",
  "header.electricVehicles": "電動車",
  "header.compareVehicles": "比較車輛",
  "header.incentives": "激勵措施",
  "header.chargingStations": "充電站",
  "header.homeChargers": "家居充電裝置",
  "footer.electricVehicles": "{electricVehicles}",
  "footer.compareVehicles": "{compareVehicles}",
  "footer.incentives": "{incentives}",
  "footer.chargingStations": "{chargingStations}",
  "footer.homeChargers": "{homeChargers}",
  "footer.allRights": "保留所有權利。",
  "footer.poweredBy": "由 Zappyride 驅動",
  "homepage.linkCard.browseElectricVehicles": "瀏覽電動車",
  "homepage.linkCard.discoverIncentives": "瞭解激勵措施",
  "homepage.linkCard.locateChargingStations": "尋找充電站",
  "homepage.linkCard.homeChargers": "HOME CHARGERS",
  "homepage.vehicleCarousel.title":
    "提供 {vehicleCount} 款電動車供選擇。請找出您偏好的車輛。",
  "homepage.vehicleCarousel.subTitle":
    "Choose an EV and compare the cost to a similar gas vehicle. EVs can help you save money because {lineBreak} they're often cheaper to own and maintain than a 100% gasoline-powered competitor.",
  "homepage.vehicleCarousel.findEVButton": "查看所有車輛",
  "graph.assumptions": "假設",
  "graph.yourEV": "您的 EV 選擇",
  "graph.similarGasVehicle": "類似的汽油車",
  "graph.graphValues": "圖形上的數值",
  "graph.show": "顯示",
  "graph.hide": "隱藏",
  "graph.moreExpensive": "昂貴",
  "graph.cheaper": "便宜",
  "graph.toOwnOver": "擁有成本",
  "graph.years": "年",
  "graph.year": "年",
  "graph.costOfOwnership.title": "擁有成本",
  "graph.costOfOwnership.subTitle":
    "{carName} 的 {costDeltaTextEnding}{costDeltaText} {style}",
  "graph.costOfOwnership.subTitleThreeCars":
    "{carName} 的 {yearsOfOwnership} 年擁有成本最便宜",
  "graph.costOfOwnership.descriptionRow.vehicle": "車輛",
  "graph.costOfOwnership.descriptionRow.maintenance": "保養",
  "graph.costOfOwnership.descriptionRow.insurance": "保險",
  "graph.costOfOwnership.descriptionRow.electricity": "電力",
  "graph.costOfOwnership.descriptionRow.gasoline": "汽油",
  "graph.costOfOwnership.totalRow": "總計",
  "graph.costOfOwnership.description": "說明",
  "graph.costOfOwnership.chart.vehicle": "車輛淨激勵金，轉售",
  "graph.costOfOwnership.chart.electricity": "電力",
  "graph.costOfOwnership.chart.gasoline": "汽油",
  "graph.costOfOwnership.chart.maintenance": "保養",
  "graph.costOfOwnership.chart.insurance": "保險",
  "graph.title.costAnalysis": "成本分析",
  "graph.title.speedOfCharge": "充電速度",
  "graph.title.electricRange": "電動續航里程",
  "graph.monthlyCostToFill.title": "每月充電成本",
  "graph.speedOfCharge.levelTwoChargingSpeed": "第二級充電器的速度",
  "graph.speedOfCharge.fastChargingSpeed": "快速充電速度",
  "graph.speedOfCharge.mphLevelTwoCharger":
    "使用第二級充電器的每小時可行駛英哩數",
  "graph.speedOfCharge.thirtyFastCharge": "快速充電的每 30 分鐘英哩數",
  "graph.speedOfCharge.subTitle":
    "{car}{subTitleText}{costDeltaText}{number}英哩",
  "graph.speedOfCharge.mphLevelTwoChargerThreeCars":
    "使用第二級充電器每充電一小時，續航里程最多",
  "graph.speedOfCharge.thirtyFastChargeThreeCars":
    "每快速充電 30 分鐘，續航里程最多",
  "graph.speedOfCharge.subTitleThreeCars": "{car} {subTitleText}",
  "graph.speedOfCharge.mphLevelTwoChargerTitle": "第二級充電的每小時充電量",
  "graph.speedOfCharge.fastChargerTitle": "快速充電的每 30 分鐘充電量",
  "graph.electricRange.subTitleThreeCars": "{car}的電動續航里程最長",
  "graph.electricRange.subTitle":
    "{car}的電動續航里程{costDeltaText}{number}英哩",
  "graph.electricRange.gasolineRange": "汽油續航里程",
  "graph.electricRange.assumption": "續航里程基於 EPA 估計值",
  "graph.monthlyCostToFill.subTitle": "{carName}每月充電{costDeltaText}{style}",
  "graph.monthlyCostToFill.subTitleThreeCars": "{carName}每月充電最便宜",
  "graph.costToBreakdown": "成本明細",
  "graph.costToBreakdown.subTitle": "比較{car1}與{car2}的累積壽命成本",
  "graph.costToBreakdown.subTitleThreeCars":
    "比較 {car1}、{car2} 和 {car3} 的累積壽命成本",
  "graph.costToBreakdown.note": "備註：收支平衡圖假設車輛以現金購得。",
  "homepage.incentives.title": "探索可能的 EV 激勵措施和稅務寬免",
  "homepage.incentives.subTitle":
    "查看駕駛 EV（無論是購買還是租賃）可為您節省的開支。激勵措施因應您的居住地點而自訂。",
  "homepage.incentives.exporeIncentives": "瞭解激勵措施",
  "compareVehicles.subTitle": "查看所有車輛 >",
  "compareVehicles.selectFuel": "請選取燃料類型",
  "compareVehicles.selectMake": "請選取車輛品牌",
  "compareVehicles.selectModel": "請選取車輛型號",
  "compareVehicles.pickTwo": "如需比較，請選取至少兩款車輛",
  "compareVehicles.disclaimer": "部份車輛品牌或型號不可用。",

  "vehicles.new": "新車",
  "vehicles.used": "二手車",
  "evs.welcomeSub":
    "按續航里程、價格或個人化匹配分數比較電動車。點擊 EV，取得詳細資料（包括與類似汽油車比較的總成本）。",
  "evs.buttonMatchScoreAndFilters": "匹配分數和篩選器",
  "evs.matchScoreAndFilters": "精簡匹配分數和篩選器",
  "evs.matchScoreOptions": "精簡匹配分數",
  "evs.roundTripCommute": "來回通勤",
  "evs.budgetAfterIncentives": "計算激勵金後的預算",
  "evs.minSeats": "最少座位數",
  "evs.seats": "座位數",
  "evs.homeChargingAvailability": "可家居充電",
  "evs.homeChargingAvailabilityTooltip":
    "如能在家快速充電，更多電動車將為您帶來方便。",
  "evs.noCharging": "無法充電",
  "evs.levelOne": "第一級",
  "evs.levelTwo": "第二級",
  "evs.helpMeChoose": "請幫助我做出選擇",
  "evs.chargingAvailability": "可充電",
  "evs.chargercard.chargingtime.na": "NA",
  "evs.disclaimer":
    "所顯示的車輛可能無法反映實際可用情況。SVCE 不認可或推薦任何特定車輛或汽車生產商。",
  "chargingWizard.carOvernight": "您會把汽車停在何處過夜？",
  "chargingWizard.parkingGarage": "私人車庫或車位",
  "chargingWizard.onTheStreet": "路邊車位",
  "chargingWizard.publicParking": "公共停車場",
  "chargingWizard.electricianInstall": "您的停車地點是否容許電工安裝充電站？",
  "chargingWizard.overnightParking": "您可否把車通宵停在設有充電站的位置？",
  "chargingWizard.powerOutlet": "您的停車處附近是否有普通充電裝置？",
  "chargingWizard.chargingAvailability": "可充電",
  "chargingWizard.noChargingAvailability": "不可充電",
  "chargingWizard.youWouldHave": "您將有 {level 2 charging availability}",
  "chargingWizard.useThisValue": "使用此值",
  evfilter: "篩選器",
  "evfilter.fuel": "燃料",
  "evfilter.fuelTooltip":
    "純電動車只會使用電力。插電式混合動力車使用電力及汽油。",
  "evfilter.type": "類型",
  yes: "是",
  no: "否",
  "evCard.electricRange": "電動續航里程",
  "evCard.totalRange": "總續航里程",
  "evCard.matchScore": "匹配分數",
  "evCard.seeDetails": "查看詳細資料",
  "evCard.seeElectricVehicle": "查看電動車",
  "evCard.yearRange": "年份範圍",
  "evCard.electricRangeAverage": "平均電動續航里程",
  "evSort.title": "分類依據",
  "evSort.matchScore": "匹配分數",
  "evSort.electricRange": "電動續航里程",
  "evSort.priceLtH": "價格：由低至高",
  "evSort.priceHtL": "價格：由高至低",
  "evSort.alphabetical": "英文字母順序（A 至 Z）",
  "pricePanels.cash": "現金",
  "pricePanels.loan": "貸款",
  "pricePanels.lease": "租賃",
  "pricePanels.loanPayment": "貸款付款",
  "pricePanels.downPayment": "首期付款",
  "pricePanels.leasePayment": "租賃付款",
  "pricePanels.perMonth": "／月",
  "pricePanels.downPaymentDescription": "10% 的 MSRP 加稅",
  "pricePanels.firstLeasePayment": "首次租賃付款",
  "pricePanels.incentivesForLease": "為租賃而設的激勵措施",
  "pricePanels.seeAllIncentives": "查看所有激勵措施",
  "pricePanels.seeAllCosts": "查看所有成本",
  "ev.jumbotron.viewAllCars": "檢視所有車輛",
  "ev.jumbotron.seeReviews": "查看評論",
  "ev.jumbotron.findADealer": "搜尋經銷商",

  "ev.carDetails.fastChargingTooltip": "DC 快速充電器速度：",
  "ev.carDetails.levelTwoChargerSpeedTooltip": "第二級充電器速度：",
  "ev.carDetails.batterySizeTooltip":
    "一千瓦時 (kWh) 提供的能量足以使車輛行駛一至四英哩，實際情況視車款而定。通常而言，筆記型電腦電池只有不足 0.1 千瓦時的能量。",
  "ev.carDetails.treesPlanted": "已種植的樹木",
  "ev.carDetails.gasolineSaved": "已節省的汽油",
  "ev.carDetails.compareText":
    "我們選擇了 {gasVehicle}，因其於品牌、大小和價格方面皆與 {vehicle} 相近。",
  "ev.cardetails.calc.dcRate": "DC 快速充電 (DCFC) 率",
  "ev.cardetails.calc.EPA": "EPA 能效評級",
  "ev.cardetails.calc.chargingRate": "充電率",
  "ev.cardetails.calc.vehicleBatteryCapacity": "車輛電池容量",
  "ev.cardetails.calc.maxACIntake": "車輛最大 AC 輸入",
  "ev.cardetails.calc.lvlTwoChargingRate": "第二級充電率",
  "ev.cardetails.calc.minOflvl2AC": "第二級充電率最低值和車輛最大 AC 輸入",
  "ev.cardetails.calc.lvl2FullCharge": "完成充電所需時間（第二級）",
  "ev.cardetails.calc.milesDrivenPerYr": "每年行駛英哩數",
  "ev.cardetails.calc.userInput": "使用者輸入",
  "ev.cardetails.calc.milesPerGallon": "每加侖英哩數 (MPG)",
  "ev.cardetails.calc.mpg": "英哩/加侖",
  "ev.cardetails.calc.portionElectric": "電力驅動所佔比例",
  "ev.cardetails.calc.portionElectricSource":
    "使用者輸入。就混合動力車而言，估計值基於車輛由電力（而非汽油）驅動的英哩數比例。",
  "ev.cardetails.calc.gallonsUsed": "已使用的加侖數",
  "ev.cardetails.calc.gallonsUsedFormula":
    "行駛的英哩數 *（1 - 電力驅動所佔比例）/ MPG",
  "ev.cardetails.calc.gallonsSaved": "已節省的加侖數",
  "ev.cardetails.calc.gallonsSavedFormula":
    "同等汽油車使用的加侖數 - EV 使用的加侖數",
  "ev.cardetails.calc.electricEmissions": "電力排放量（以磅計）",
  "ev.cardetails.calc.electricEmissionsLbsMwh": "電力排放量（以磅/兆瓦時計）",
  "ev.cardetails.calc.EIA": "美國能源資訊局",
  "ev.cardetails.calc.gasolineEmissionsYr": "汽油排放量（以二氧化碳磅數/年計）",
  "ev.cardetails.calc.gasolineEmissions": "汽油排放量（以磅/加侖計）",
  "ev.cardetails.calc.emissionsReudctions": "減排量",
  "ev.cardetails.calc.lbsCo2PerYr": "每年的二氧化碳磅數",
  "ev.cardetails.calc.gasolineEmissionsLbs": "汽油排放量（以磅計）",
  "ev.cardetails.calc.arborDayFoundation": "植樹節基金會",
  "ev.cardetails.calc.emissionsPerTree": "每棵樹節省的排放量",
  "ev.cardetails.calc.electricityEmissions":
    "電力排放量（以二氧化碳磅數/兆瓦時計）",
  "ev.usedCarTitle": "二手 BMW",
  "ev.cardetails.electricRange":
    "鑑於電池退化情況，此處引用的續航里程為以車齡和正常駕駛或充電模式為基礎的估計值。",
  "ev.carDetails.usedBatterySizeTooltip":
    "一千瓦時 (kWh) 提供的能量足以使車輛行駛一至四英哩，實際情況視車款而定。鑑於二手 EV 的電池退化情況，此處引用的電池大小為以車齡和正常駕駛或充電模式為基礎的估計值。",
  "ev.purchaseMethod": "購買方法",
  "ev.purchaseMethodToolTip":
    "就租賃而言，您需要在租賃期完結後交回車輛。就分期貸款購車而言，您可以在還清貸款後保留車輛。",
  "ev.cash": "現金",
  "ev.loan": "貸款",
  "ev.lease": "租賃",
  "ev.milesDrivenAnnually": "每年行駛英哩數",
  "ev.electricPortion": "PHEV 的部分電動",
  "ev.electricPortionTooltip":
    "插電式混合動力汽車 (PHEV) 同時使用電力和汽油。通常來說，使用電力驅動更為有益。",
  "ev.yearsOwnership": "擁有/租賃年數",
  "ev.interestRate": "利率",
  "assumption.salesTaxPercent": "Sales Tax %",
  "assumption.salesTax": "銷售稅",
  "assumption.gasolinePrice": "汽油價格",
  "assumption.downPayment": "首期付款",
  "assumption.electricityRate": "電價",
  "assumption.downPaymentValue": "10% 的 MSRP 加稅",
  "ev.compareGasVehicle": "把{car}與類似汽油車（即 {gasCar}）比較",
  "ev.compareOtherVehicles": "比較其他車輛",
  "ev.incentives.title":
    "就 {car} 而言，您或可享受高達{savings}美元的稅務寬免和補貼",
  "afterIncentives.infoTooltip":
    "價格範圍是根據 MSRP (廠商建議零售價) 和估計的壽命里程。請參閱下方「來源」之下的「轉售價值」。請聯絡您當地經銷商查詢更準確的定價資訊",
  "ev.reviews": "來自網上的評論",
  "chargingMap.updateZipcode": "更新郵遞區號",
  "chargingMap.errorZipcode": "錯誤：{workingZipcode} 並非有效的郵遞區號",
  "chargingMap.showFullListOfStations": "完整充電站清單",
  "chargingMap.public": "公共充電站",
  "chargingMap.businessGovernment": "由私營企業或政府安裝",
  "chargingMap.highPowerStations": "大功率充電站",
  "chargingMap.DCChargers": "DC 快速充電站或超級充電站",
  "chargingMap.otherSttations": "其他類型的充電站",
  "chargingMap.privateStations": "私營充電站",
  "chargingMap.searchDealers": "搜尋合資格的經銷商",
  "chargingMap.chargingStationCluster": "充電站集群",
  "chargingMap.clusterOfChargingStations": "充電站集群",
  "chargingMap.disclaimer":
    "資料來自替代燃料數據中心。地圖或不能反映最新充電站可用情況。",

  "dealers.brands": "品牌",
  "dealers.allBrands": "所有品牌",
  "dealers.allDealers": "所有經銷商",
  "dealers.contact": "聯絡方式",
  "dealers.website": "網站",
  "dealers.findDealers": "搜尋經銷商",
  "incentives.title": "電動車激勵措施",
  "incentives.subTitle":
    "您或有資格享受各種激勵措施（包括回贈、稅務寬免和其他各項優惠）。激勵措施因應您的居住地點而自訂。",
  "incentives.single": "單身",
  "incentives.married": "已婚",
  "incentives.headOfHousehold": "一家之主",
  "incentives.vehicleType": "車輛類型",
  "incentives.vehicleTypeTooltip": "部分激勵措施取決於您購買的車款。",
  "incentives.tradeIn": "「舊換新」",
  "incentives.income": "收入和繳稅狀況",
  "incentives.incomeTooltip":
    "激勵措施的資格和可獲金額通常取決於您的收入。較低收入的顧客通常可取得較多回贈。",
  "incentives.clunkerNone":
    "部分激勵措施會於您選擇停用現有車輛（即舊車）的情況下給予補貼。",
  "incentives.clunkertoolTip":
    "部分激勵措施會於您選擇停用現有車輛（即舊車）的情況下給予補貼。具體而言：",
  "incentives.clunkertoolTipContd":
    "它們在您的地區可用。每種激勵措施的資格要求皆有不同；請查看計劃詳情。",
  "incentives.householdSize": "住戶人數",
  "incentives.householdIncome": "住戶收入",
  "incentives.planClunker": "您有否打算置換舊車？",
  "incentives.location": "位置",
  "incentives.locationTooltip":
    "很多激勵措施取決於您的居住地。請輸入您的郵遞區號，以找出適用於您居住地的激勵措施。",
  "incentives.taxFilingStatus": "報稅身份",
  "incentives.whichVehicle": "您打算購買哪一款車輛？",
  "incentives.incentiveEligibility": "更新激勵措施資格",
  "homeChargers.subText":
    "請選取您自己感興趣的車輛，查看個別車輛完成充電所需時間。",
  "homeChargers.wallMounted": "壁裝式",
  "homeChargers.portable": "便攜式",
  "homeChargers.price": "價格",
  "homeChargers.cordLength": "電線長度",
  "homeChargers.wifi": "WiFi 連線能力",
  "homeChargers.socket": "電源插座",
  "homeChargers.socketTooltip":
    "不同充電器型號的插頭形狀各異。以上插頭形狀需與插片進入壁裝插座的方式對應。如您不清楚哪個選項最適合自己的家或車庫，請與合資格電工聯絡。",
  "homeChargers.cordLengthLtH": "電線長度：由低至高",
  "homeChargers.cordLengthHtL": "電線長度：由高至低",
  "homeChargersCard.buy": "詳情與購買",
  "homeChargersCard.beforeIncentives": "計算激勵金前",
  "homeChargersCard.socket": "{socket} 電源插座",
  "homeChargersCard.teslaWarning": "此充電器僅適用於 Tesla 產品",
  "homeChargers.cable": "{length} 英呎電線",
  "homeChargers.hardwired": "固線設計",
  "homeChargers.toFullCharge": "為完全充電而設",
  "homeChargers.wifiTooltip":
    "部分充電器能夠連線到您家中的 WIFI 網路，以允許啟用其他功能。",
  "chargercard.selectMakeModel": "*請選取車輛品牌及型號以查看充電時間。",
  "electricians.residential": "家用",
  "electricians.commercial": "商用",
  "electricians.industrial": "工業用",
  "electricians.type": "電工類型",
  "electricians.searchElectricians": "搜尋合資格電工",
  "tabbed-maps-dealer-catalog-tab": "經銷商",
  "tabbed-maps-all-stations-tab": "充電站",
  "tabbed-maps-electricians-tab": "電工",
  "tabbed-maps-route-tab": "繪製路線",
  "ev.showSources.electricityRate":
    "電費是按照夏季非尖峰期四個月和冬季非尖峰期八個月的平均值計算的。請點按{website}以瞭解詳情。",
  "ev.showSources.portionElectricSource":
    "使用者輸入。就混合動力車而言，估計值基於車輛由電力（而非汽油）驅動的英哩數比例。",
  "ev.showSources.resaleValue": "轉售價值",
  "ev.showSources.resaleValueSource":
    "車輛 MSRP 和達 10 萬英哩壽命里程的函式，假設為負指數折舊，其中如距離超過 36,000 英哩，轉售價值將為六成",
  "ev.showSources.totalVehicleCost": "總車輛成本",
  "ev.showSources.cashFormula": "車輛 MSRP *（1 + 銷售稅） - 激勵金 - 轉售價值",
  "ev.showSources.assumption": "假設：",
  "ev.showSources.loanAmount": "貸款金額",
  "ev.showSources.loanFormula": "MSRP *（1 + 銷售稅） - 首期付款",
  "ev.showSources.monthlyPayments": "月繳費用",
  "ev.showSources.monthlyPaymentsSource":
    "貸款金額、利率以及擁有月數的財務函式",
  "ev.showSources.monthsOfOwnership": "擁有月數",
  "ev.showSources.totalMonthlyPayments": "總月繳費用",
  "ev.showSources.totalMonthlyPaymentsSource": "月繳費用 * 擁有月數",
  "ev.showSources.totalLoanFormula":
    "首期付款 + 總月繳費用 - 激勵金 - 轉售價值",
  "ev.showSources.capitalizedIncentives": "資本化激勵金",
  "ev.showSources.capitalizedIncentivesSource": "租賃期內資本化的激勵金",
  "ev.showSources.capitalizedCost": "資本化成本",
  "ev.showSources.capitalizedCostSource": "MSRP - 首期付款 - 資本化激勵金",
  "ev.showSources.monthlyDepreciationCost": "每月折舊費用",
  "ev.showSources.monthlyDepreciationCostSource":
    "（資本化成本 - 轉售價值）/擁有月數",
  "ev.showSources.monthlyFinancingCost": "每月分期付款費用",
  "ev.showSources.monthlyFinancingCostSource":
    "（資本化成本 + 轉售價值）* 貨幣因子",
  "ev.showSources.moneyFactor": "貨幣因子",
  "ev.showSources.moneyFactorSource": "等同於使用者輸入 (3.50%)",
  "ev.showSources.monthlyLeasePayment": "每月租賃付款",
  "ev.showSources.monthlyLeasePaymentSource":
    "（每月折舊費用 + 每月分期付款費用）*（1 + 銷售稅）",
  "ev.showSources.firstLeasePayment": "首次租賃付款",
  "ev.showSources.firstLeasePaymentSource": "首期付款 + 每月租賃付款",
  "ev.showSources.nonCapLeaseIncentives": "非資本化租賃激勵金",
  "ev.showSources.nonCapLeaseIncentivesSource":
    "未計入資本化租賃成本內的激勵金",
  "ev.showSources.leaseForumla":
    "首次租賃付款 +（擁有月數 - 1）*（每月租賃付款）- 非資本化租賃激勵金",
  "ev.showSources.vehicleKWh": "車輛每行駛 100 英哩的耗電量（千瓦時）",
  "ev.showSources.EPAEfficiency": "EPA 能效評級",
  "ev.showSources.dollarPerMileDriven": "每行駛 100 英哩的費用",
  "ev.showSources.dollarPerMileDrivenSource":
    "電力成本 * 車輛每行駛 100 英哩的耗電量（千瓦時）",
  "ev.showSources.lifetimeMiles": "車輛壽命里程內的英哩數",
  "ev.showSources.totalElectricityCost": "總電力成本",
  "ev.showSources.totalElectricityCostSource":
    "每行駛 100 英哩的費用 * 車輛壽命里程內的英哩數 * 30/ 100",
  "ev.showSources.gasolineCost": "汽油成本",
  "ev.showSources.vehicleMPG": "車輛 MPG（每加侖英哩數）",
  "ev.showSources.totalGasolineCost": "總汽油成本",
  "ev.showSources.totalGasolineCostSource":
    "汽油成本 * 車輛壽命里程內的英哩數 * 30/ MPG",
  "ev.showSources.maintenancePerMile": "汽油每英哩的保養成本",
  "ev.showSources.maintenancePerMileSource":
    "以每英哩 0.06 美元的平均保養成本推算，由車輛 MSRP 與 37,000 美元的平均 MSRP 的關係驅動",
  "ev.showSources.maintenanceCostReduction": "EV 保養成本減免",
  "ev.showSources.maintenanceCostReductionSource": "{website}",
  "ev.showSources.maintenancePerMileElectricity": "電力每英哩的保養成本",
  "ev.showSources.maintenancePerMileElectricitySource":
    "汽油每英哩保養成本 * EV 保養減免因子",
  "ev.showSources.blendedMaintenanceCostPerMile": "每英哩的混合保養成本",
  "ev.showSources.blendedMaintenanceCostPerMileSource":
    "電力驅動所佔比例，汽油和電力的每英哩保養",
  "ev.showSources.totalMaintenanceCost": "總保養成本",
  "ev.showSources.totalMaintenanceCostSource":
    "每英哩保養成本 * 車輛壽命里程內的英哩數",
  "ev.showSources.averageInsurancePerYear": "每年的平均保險成本",
  "ev.showSources.insurancePerYear": "每年保險成本",
  "ev.showSources.insurancePerYearSource":
    "以 CA 中的平均保險成本推算，由車輛 MSRP 與 37,000 美元的平均 MSRP 的關係驅動",
  "ev.showSources.yearsOfOwnership": "擁有年數",
  "ev.showSources.totalInsuranceCost": "總保險成本",
  "ev.showSources.totalInsuranceCostSource": "每年保險成本 * 擁有年數",
  here: "此處",
  sources: "來源",

  seeFullListOfStations: "查看{scroll}",
  fullListOfStations: "完整充電站清單",
  publicChargingStationBasics: "公共充電站基本資料",
  numOfStations: "# 充電站",
  publicChargingFAQ: "公共充電站常見問題",
  differentTypesOfChargingStationsQuestion: "充電站有哪些不同種類？",
  thereAreThreeLevels:
    "EV 充電速度分為三級：{levelOne}、{levelTwo}和 {dcfc}。EV 的充電時間取決於您 EV 的電池大小、車輛可接受的充電率和充電站的充電速度。",
  dcfcFastCharge: "DC 快速充電",
  levelOneChargingDescription:
    "如連接{levelOne}充電器（美國標準家用電源插座）達一小時，純電動車 (BEV) 或插電式混合動力車 (PHEV) 將可額外行駛約 2 至 10 英哩。此選項最適合不需使用車輛時為其通宵充電；或者設有較小型電池、可以在電池電力耗盡時轉用車內汽油引擎的 PHEV。",
  levelTwoChargingDescription:
    "{levelTwo}較第一級充電器快得多，亦是公共充電設施的最常見選項。如連接第二級充電器達一小時，大部分 BEV 和 PHEV 車輛皆可額外行駛約 20 至 25 英哩。",
  dcfcChargingDescription:
    "{dcfc}是現時最快可供大眾使用的充電器。如接上此類充電器達一小時，BEV 車輛可額外行駛超過 150 英哩。就大部分 BEV 車輛而言，駕駛者只需充電 30 分鐘便可恢復近八成的總續航里程。請注意，PHEV 車輛（以及部分舊型號 BEV）與 DCFC 充電站並不相容。",
  teslaChargingDescription:
    "Tesla 超級充電站與 DCFC 充電站的充電速度大致相似。但是，只有 Tesla 車輛才可使用 Tesla 旗下的公共充電站。 請查閱下文，瞭解有關充電接頭相容性的更多資訊，並{browseElectricVehicles}，深入瞭解特定品牌和型號。",
  publicChargingEVQuestion: "我能如何為自己的 EV 挑選公共充電站？",
  everyEVCompatible:
    "目前，所有可於美國路面行駛的 EV 皆能使用以下其中一種標準充電接頭：SAE J1772、CHAdeMO 和 Tesla。",
  publicChargerOutlets: "公共充電站",
  saeDescription:
    "SAE J1772 為第一級和第二級充電而設。如車輛配有 CCS Combo 插頭，DCFC 亦屬可供使用之列。",
  chademoDescription: "CHAdeMO 使用通用接頭，支援第一級、第二級和 DCFC 充電。",
  teslaDescription:
    "Tesla 車輛可透過標準 Tesla 接頭使用專有的 Tesla 超級充電站網絡。如欲在 SAE J1772 或 CHAdeMO 充電站充電，駕駛者需使用轉接器。",
  dcfcChargerDescription:
    "在使用 DC 快速充電器前，請先瞭解您 EV 的接頭類型。部分 DC 快速充電站同時支援 CHAdeMO 和 SAE J1772 接頭。Tesla 超級充電站網絡與其他類型的車輛並不相容。",
  payingForChargingQuestion:
    "如使用公共充電器，我是否需要付費？如需要，如何付費？",
  payingForChargingAnswer:
    "部分公共充電站可免費使用，而其他則需駕駛者繳付費用。充電站或會容許使用者直接在充電座刷信用卡，或採用其他可每月預繳費用的訂閱計劃（手續通常透過駕駛者的手機辦理）。您可按國家公共充電網絡表中的連結，詳細瞭解您擁有的選項。",
  publicChargingEtiquette: "公共充電規範",
  publicChargingRules:
    "大部分公共充電站的使用者都會遵從簡單的「道路規則」，務求使大家都能獲得美好體驗。以下是一些最佳做法：",
  moveCarAnswer: "完成充電後，請盡快把車輛由充電站駛出，以方便他人使用充電站。",
  unplugAnswer: "切勿把他人車上正在使用的充電插頭移至您的車輛。",
  topOffAnswer:
    "每次充電時，您皆應把電池充滿。您不應只在必要時才如此做。如您每次充電都十分短促，以使要到訪多個充電站（若您會在充電期間離開車輛前往商店或商場閒逛，此點尤其重要），您或可避免在電池幾近耗盡時需作冗長充電。",
  bevToFull: "BEV 充電需時（由電池耗盡至充滿：{about}{time}{hours}",
  about: "約",
  hours: "小時",
  phevToFull: "PHEV 充電需時（由電池耗盡至充滿）：{about}{time} {hours}",
  levelOneDescription: "您應在長時間閒置車輛時才進行上述完整充電。",
  levelTwoDescription:
    "大部分供公眾使用的公共充電站，是專為延長續航里程或縮短在家充電時間而設。",
  dcfcDescription: "您亦可考慮最快速的充電站，其公共充電站網絡正不斷發展狀大。",
  leastFast: "最慢",
  faster: "較快",
  fastest: "最快",
  milesOfRangePerHourCharge: "的續航里程增加產生於每充電一小時",
  chargingSpeedDisclaimer:
    "以上數字基於電池容量為 62 千瓦的純電動車 (BEV)，以及電池容量為 14 千瓦的插電式混合動力車 (PHEV)。上述兩款車輛的 EPA 評級皆為每 100 英哩 31 千瓦時。",
  nationalPublicChargingNetworks: "國家公共充電網絡",
  network: "網絡",
  stations: "充電站",
  levelTwoStations: "第二級充電站",
  dcfcStations: "DCFC 充電站",
  costPerCharge: "每次充電成本",
  levelTwoCostPerCharge: "第二級的每次充電成本",
  dcfcCostPerCharge: "DCFC 的每次充電成本",
  mixOfKwhAndSession: "美元/千瓦時和美元/充電次數之混合（按州計）",
  variesByLocation: "因位置而異",
  dollarPerMinuteVaryWithPowerAndState: "美元/分鐘，因功率高低和州而異",
  flatFeePerHour: "統一收費：{cost}/小時",
  costPerHour: "{cost}/小時",
  costsPerHour: "{lowCost}/小時 - {highCost}/小時",
  dollarPerMinuteByState: "美元/分鐘（按州計）",
  free: "免費",
  flatFeePerSession: "統一收費：4.00 美元/次",
  dollarPerSession: "7.5 美元/次",
  milesPerHourOfCharge: "每充電一小時等於{miles}英哩",

  "homepage.welcome": "歡迎來到 EV Assistant",
  "homepage.welcomeSub":
    "瀏覽和比較車輛。{lineBreak} 瞭解電動車的激勵措施和節省之處。{lineBreak} 瞭解家用和行駛途中的充電選項。",
  "header.exploreElectricCars": "瞭解電動車",
  "header.browseElectricCars": "新電動車",
  "header.browseUsedElectricCars": "二手電動車",
  "header.shopHomeChargers": "選購家居充電裝置",
  "header.discoverIncentives": "瞭解激勵措施",
  "header.chargingOptions": "充電選項",
  "header.locatePublicChargingStations": "尋找公共充電站",
  "header.findElectricians": "搜尋電工",
  "header.moreSites": "更多站點",

  "footer.havingTrouble": "在使用本網站時遇上問題？",
  "footer.contact": "請聯絡：support@zappyride.com",

  "ev.jumbotron.compare": "比較",

  "homepage.incentives.legalDisclaimer":
    "矽谷清潔能源開設本網站，為顧客提供資源。SVCE 不認可或保證任何供應商、承包商、服務或產品，也不對其提供任何保固或聲明。SVCE 對與本網站相關的任何供應商、承包商、服務或產品引起（或與之有關）的任何形式的損失或損害概不負責。您與供應商、承包商或其他協力廠商進行的任何交易，皆僅限於您與該供應商、承包商或其他協力廠商之間。",
  "homepage.locateChargingStations": "尋找充電站",

  "ev.carDetails.treeTooltip": "一棵樹每年吸收二氧化碳的速率為28磅",
  "ev.carDetails.calc.emissionsSaved": "每棵樹每年{CO2}磅的二氧化碳",
  gasolinePrice: "汽油價格 ($/GAL)",
  "ev.electricityRateCalc":
    "電費是按照夏季非尖峰期四個月和冬季非尖峰期八個月的平均值計算的。請點按{website}以瞭解詳情。",
  "ev.showSources.averageInsuranceCost": "{state}的平均保險費用",
  "graph.speedOfCharge.lessThanFiftykW": "充電不快於 50kW",
  "graph.fuelRangeTitle": "{budget} 美元可以行駛多遠?",
  "graph.fuelRangeSubTitle": "{range} 可以多行駛 {name} 英哩",

  publicCharingStationMap: "公共充電站地圖",
  fullListOfDealers: "經銷商的完整列表",
  evChargingSpeed: "電動汽車充電速度",
  comingSoon: "即將推出",
};