export default {
  electricVehicles: "Carros Eléctricos Nuevos",
  usedElectricVehicles: "Carros Eléctricos Usados",
  compareVehicles: "Compare vehículos",
  incentives: "Incentivos",
  chargingStations: "Estaciones de Carga",
  homeChargers: "Cargadores caseros",
  dealers: "Distribuidores",
  newVehicles: "Autos Nuevos",
  electricians: "Electricistas",

  electricity: "Fuente",
  gasoline: "Gasolina",
  total: "Total",
  description: "Descripción",
  detailedCalculations: "detalles del cálculo",
  charging: "Carga",
  emissions: "Emisiones",
  source: "Fuente",
  vehicles: "Vehículos",
  make: "Marca",
  model: "Modelo",
  all: "Todos",
  age: "Años",
  zipcode: "Código postal",

  personalizeIncentives: "Personalizar incentivos",

  "vehicle.miles": "Millas",
  "vehicle.trees": "Árboles",
  "vehicle.view": "Ver",
  "vehicle.more": "más",
  "vehicle.fewer": "menos",
  "vehicle.greater": "más",

  "vehicle.plugInHybrid": "Híbrido eléctrico enchufable",
  "vehicle.allElectric": "Eléctricos",
  "vehicle.gas": "Gasolina",
  "vehicle.afterIncentives": "Después de incentivos",
  "vehicle.msrp": "MSRP",
  "vehicle.estimatedIncentives": "Insentivos estimados",
  "vehicle.type": "Tipo",
  "vehicle.fuelType": "Tipo de combustible",
  "vehicle.batterySize": "Tamaño de la batería",
  "vehicle.electricRange": "Rango eléctrico",
  "vehicle.timeToChargeLvlTwo": "Tiempo de carga - nivel 2",
  "vehicle.milesPerThirtyFastCharge": "Millas por 30 minutos de carga rápida",
  "vehicle.coTwoEmissions": "Reducción de emisiones de CO2",
  "vehicle.phev": "Electricidad y gasolina ",
  "vehicle.bev": "Electricidad",
  "vehicle.age.one": "0-1 Años",
  "vehicle.age.two": "2-3 Años",
  "vehicle.age.four": "4+ Años",

  "vehicle.type.sedan": "Sedan",
  "vehicle.type.hatchback": "Cinco puertas",
  "vehicle.type.coupe": "Cupé",
  "vehicle.type.crossover": "Híbrido",
  "vehicle.type.minivan": "Minivan",
  "vehicle.type.suv": "SUV",
  "vehicle.type.wagon": "Camioneta",
  "vehicle.type.truck": "Camión",
  "vehicles.new": "Nuevo",
  "vehicles.used": "Usado",

  "footer.allRights": "Todos los derechos reservados.",
  "footer.poweredBy": "Patrocinado por Zappyride",

  "homepage.welcome": "Bienvenido a EV Assistant",
  "homepage.welcomeSub":
    "Encuentre y compare carros. {lineBreak} Descubra incentivos y ahorre. {lineBreak} Busque opciones de carga en el hogar y en la calle.",

  "homepage.linkCard.browseElectricVehicles":
    "VEHICULOS {lineBreak} ELÉCTRICOS",
  "homepage.linkCard.discoverIncentives": "DESCUBRA {lineBreak} INCENTIVOS",
  "homepage.linkCard.locateChargingStations":
    "ENCUENTRE ESTACIONES {lineBreak} DE CARGA",
  "homepage.linkCard.homeChargers": "CARGADORES PARA {lineBreak} EL HOGAR",

  "homepage.vehicleCarousel.title":
    "Existen {vehicleCount} vehículos eléctricos disponibles. Descubra el suyo.",
  "homepage.vehicleCarousel.subTitle":
    "Elija un VE y compare el costo con un vehículo de gasolina similar. Los VE’s le permiten ahorrar dinero porque {lineBreak} son más económicos y baratos de poseer y mantener a comparación de un competidor de 100% gasolina.",
  "homepage.vehicleCarousel.findEVButton": "Encuentre su vehículo eléctrico",

  "graph.assumptions": "Supuestos",
  "graph.yourEV": "VE seleccionado",
  "graph.similarGasVehicle": "Vehículo comparable a gasolina ",
  "graph.graphValues": "valores graficados",
  "graph.show": "Mostrar ",
  "graph.hide": "Ocultar ",
  "graph.moreExpensive": "más caro",
  "graph.cheaper": "más barato",
  "graph.toOwnOver": "de tener por ",
  "graph.years": " Años",
  "graph.year": " Año",
  "graph.fuelRangeTitle": "¿Qué tan lejos puede manejar con {budget}?",
  "graph.fuelRangeSubTitle": "El {name} puede conducir {range} millas más",

  "graph.costOfOwnership.title": "Costo de propiedad",
  "graph.costOfOwnership.subTitle":
    "El {carName} es {style} {costDeltaText} {costDeltaTextEnding}",
  "graph.costOfOwnership.subTitleThreeCars":
    "El {carName} es el más barato de tener por {yearsOfOwnership} años",
  "graph.costOfOwnership.descriptionRow.vehicle": "Vehículo",
  "graph.costOfOwnership.descriptionRow.maintenance": "Mantenimiento",
  "graph.costOfOwnership.descriptionRow.insurance": "Seguro",
  "graph.costOfOwnership.descriptionRow.electricity": "Electricidad",
  "graph.costOfOwnership.descriptionRow.gasoline": "Gasolina",
  "graph.costOfOwnership.totalRow": "Total",
  "graph.costOfOwnership.description": "Descripción",

  "graph.costOfOwnership.chart.vehicle": "Incentivos para vehículo, reventa.",
  "graph.costOfOwnership.chart.electricity": "Electricidad",
  "graph.costOfOwnership.chart.gasoline": "Gasolina",
  "graph.costOfOwnership.chart.maintenance": "Mantenimiento",
  "graph.costOfOwnership.chart.insurance": "Seguro",

  "graph.title.costAnalysis": "Análisis de costos",
  "graph.title.speedOfCharge": "Velocidad de Carga",
  "graph.title.electricRange": "Rango eléctrico",
  "graph.monthlyCostToFill.title": "Costo de llenar o cargar mensualmente",

  "graph.speedOfCharge.levelTwoChargingSpeed": "Velocidad de carga nivel 2",
  "graph.speedOfCharge.fastChargingSpeed": "Velocidad de carga rápida",
  "graph.speedOfCharge.mphLevelTwoCharger":
    "millas por hora al usar un cargador nivel 2 ",
  "graph.speedOfCharge.thirtyFastCharge":
    "millas por cada 30 minutos de carga rápida",
  "graph.speedOfCharge.subTitle":
    "El {car} tendrá {number} {costDeltaText} {subTitleText}",
  "graph.speedOfCharge.mphLevelTwoChargerThreeCars":
    "será el que gane más rango de carga tras una hora de uso de un cargador nivel 2",
  "graph.speedOfCharge.thirtyFastChargeThreeCars":
    "será el que gane más rango de carga después de 30 minutos de carga rápida",
  "graph.speedOfCharge.subTitleThreeCars": "El {car} {subTitleText}",
  "graph.speedOfCharge.mphLevelTwoChargerTitle":
    "Porcentaje de carga por hora en nivel 2",
  "graph.speedOfCharge.fastChargerTitle":
    "Cantidad de millas por 30 minutos de carga rápida.",
  "graph.electricRange.subTitleThreeCars":
    "El {car} tiene el rango eléctrico más amplio de todos",
  "graph.electricRange.subTitle":
    "El rango eléctrico del {car} tiene {number} millas { costDeltaText} ",
  "graph.electricRange.gasolineRange": "Rango de gasolina",
  "graph.electricRange.assumption": "Rango basado en estimaciones de la EPA",
  "graph.monthlyCostToFill.subTitle":
    "Mensualmente, es {style} {costDeltaText} llenar o cargar el {carName}",
  "graph.monthlyCostToFill.subTitleThreeCars":
    "Mensualmente, es más barato llenar o cargar el {carName}",

  "graph.costToBreakdown": "Costo de Avería",
  "graph.costToBreakdown.subTitle":
    "Comparar el costo de vida acumulado de {car1} con {car2}",
  "graph.costToBreakdown.subTitleThreeCars":
    "Comparar el costo de vida acumulado de {car1}, {car2} y {car3}",
  "graph.costToBreakdown.note":
    "Nota: El gráfico de equilibrio asume que los vehículos se compran en efectivo.",

  "homepage.incentives.title": "Descubra incentivos",
  "homepage.incentives.subTitle":
    "Descubra que tanto puede ahorrar con un VE, ya sea por compra o alquiler. {lineBreak} Los incentivos son personalizados según su lugar de residencia.",
  "homepage.incentives.exporeIncentives": "DESCUBRA INCENTIVOS",

  "compareVehicles.subTitle": "Ver todos los vehículos >",
  "compareVehicles.selectFuel": "Seleccione el tipo de combustible ",
  "compareVehicles.selectMake": "Seleccione la marca",
  "compareVehicles.selectModel": "Seleccione el modelo",
  "compareVehicles.pickTwo":
    "Elíja al menos 2 vehículos para hacer la comparación",
  "compareVehicles.disclaimer":
    "No todas las marcas / modelos están disponibles.",

  "evs.welcomeSub":
    "Compare carros eléctricos por su rango, precio o por las características que más se acomoden a su gusto. {lineBreak} Haga clic sobre el VE para obtener detalles como el costo total comparado con vehículos de gasolina.",
  "evs.buttonMatchScoreAndFilters": "Puntaje personalizado y Filtros",
  "evs.matchScoreAndFilters": "Asignar puntaje personalizado y Filtros",
  "evs.matchScoreOptions": "Asignar puntaje personalizado",
  "evs.roundTripCommute": "Viaje de ida y vuelta",
  "evs.budgetAfterIncentives": "Presupuesto después de incentivos",
  "evs.minSeats": "Cantidad mínima de asientos",
  "evs.seats": "Asientos",
  "evs.homeChargingAvailability": "Disponibilidad de carga en el hogar",
  "evs.homeChargingAvailabilityTooltip":
    "Más autos eléctricos serán mejores para ti si tienes la posibilidad de cargarlos rapidamente en casa.",
  "evs.noCharging": "Sin carga",
  "evs.levelOne": "Nivel 1",
  "evs.levelTwo": "Nivel 2",
  "evs.helpMeChoose": "Ayúdeme a elegir",
  "evs.chargingAvailability": "Disponibilidad de carga",
  "evs.chargercard.chargingtime.na": "NA",
  "evs.disclaimer":
    "Es posible que los vehículos mostrados en esta página no reflejen la disponibilidad real. {company} no respalda ni recomienda ningún modelo o fabricante de vehiculos específico. ",

  "chargingWizard.carOvernight": "¿En dónde parquear su carro en la noche?",
  "chargingWizard.parkingGarage": "Garaje privado o estacionamiento",
  "chargingWizard.onTheStreet": "En la calle",
  "chargingWizard.publicParking": "Estacionamiento público",
  "chargingWizard.electricianInstall":
    "¿Puede un electricista instalarme una estación de carga?",
  "chargingWizard.overnightParking":
    "¿Podrías estacionar tu auto en un lugar que cuenta con estación de carga?",
  "chargingWizard.powerOutlet":
    "¿Existe algún tomacorriente cerca al lugar en donde estacionas tu auto?",
  "chargingWizard.chargingAvailability": " Disponibilidad de carga",
  "chargingWizard.noChargingAvailability": "No disponibilidad de carga",
  "chargingWizard.youWouldHave": "Tendrás {resultText} ",
  "chargingWizard.useThisValue": "Usar este valor",

  evfilter: "Filtro",
  "evfilter.fuel": "Combustible",
  "evfilter.fuelTooltip":
    "Los vehículos eléctricos solo usan electricidad. Los vehículos híbridos pueden funcionar con electricidad y gasolina ",
  "evfilter.type": "Tipo",

  yes: "Sí",
  no: "No",

  "evCard.electricRange": "Rango eléctrico",
  "evCard.totalRange": "Rango total",
  "evCard.matchScore": "Puntaje personalizado",
  "evCard.seeDetails": "DETALLES",
  "evCard.seeElectricVehicle": "Ver vehículo eléctrico",
  "evCard.yearRange": "Rango de Años",
  "evCard.electricRangeAverage": "Promedio de Rango Eléctrico",

  "evSort.title": "Ordenar por",
  "evSort.matchScore": "Puntaje personalizado",
  "evSort.electricRange": "Rango eléctrico",
  "evSort.priceLtH": "Precio: Menor a mayor",
  "evSort.priceHtL": "Precio: Mayor a menor",
  "evSort.alphabetical": "Orden alfabético",

  "pricePanels.cash": "Efectivo",
  "pricePanels.loan": "Crédito",
  "pricePanels.lease": "Arrendamiento",
  "pricePanels.loanPayment": "Pago del préstamo",
  "pricePanels.downPayment": "Cuota inicial",
  "pricePanels.leasePayment": "Pago de arriendo",
  "pricePanels.perMonth": " / Mes",
  "pricePanels.downPaymentDescription": "10% de MSRP más impuestos",
  "pricePanels.firstLeasePayment": "Primera cuota de la renta",
  "pricePanels.incentivesForLease": "Incentivos para rentar",
  "pricePanels.seeAllIncentives": "Ver todos los incentivos",
  "pricePanels.seeAllCosts": "Ver todos los costos",

  "ev.jumbotron.viewAllCars": "Ver todos los autos",
  "ev.jumbotron.findADealer": "Ver Distribuidores",
  "ev.carDetails.fastChargingTooltip": "Velocidad de carga rápida con DC:",
  "ev.carDetails.levelTwoChargerSpeedTooltip": "Velocidad de carga nivel 2:",
  "ev.carDetails.batterySizeTooltip":
    "Un Kilovatio por hora (kWh) provee suficiente energía para conducir de 1 a 4 millas dependiendo del vehículo. Una batería común para computadora puede contener menos de 1 kWh",
  "ev.carDetails.treesPlanted": "Árboles plantados",
  "ev.carDetails.gasolineSaved": "Gasolina ahorrada",
  "ev.carDetails.compareText":
    "Seleccionamos el {gasVehicle} por su proximidad con el {vehicle} con base en la marca, tamaño y precio.",
  "ev.cardetails.calc.dcRate": "Cargador Rápido (DCFC)",
  "ev.cardetails.calc.EPA": "Calificación de eficiencia EPA",
  "ev.cardetails.calc.chargingRate": "Velocidad de carga",
  "ev.cardetails.calc.vehicleBatteryCapacity": "Capacidad de la batería",
  "ev.cardetails.calc.maxACIntake": "Capacidad maxima de AC",
  "ev.cardetails.calc.lvlTwoChargingRate": "Velocidad de carga nivel 2",
  "ev.cardetails.calc.minOflvl2AC":
    "Nivel mínimo de carga y capacidad máxima de absorcion del AC",
  "ev.cardetails.calc.lvl2FullCharge": "Tiempo de carga total en nivel 2",
  "ev.cardetails.calc.milesDrivenPerYr": "Millas conducidas al año",
  "ev.cardetails.calc.userInput": "Introducción de datos por el usuario",
  "ev.cardetails.calc.milesPerGallon": "Millas por Galón (mpg)",
  "ev.cardetails.calc.mpg": " millas/gal",
  "ev.cardetails.calc.portionElectric": "Porción eléctrica usada",
  "ev.cardetails.calc.portionElectricSource":
    "Introducción de datos por el usuario. Para un vehículo híbrido, estima la porción de millas conducidas usando batería eléctrica en vez de gasolina.",
  "ev.cardetails.calc.gallonsUsed": "Galones Usados",
  "ev.cardetails.calc.gallonsUsedFormula":
    "Millas manejadas * (1 - Porción eléctrica usada) / MPG",
  "ev.cardetails.calc.gallonsSaved": "Galones Ahorrados",
  "ev.cardetails.calc.gallonsSavedFormula":
    "Galones usados por un vehiculo a gasolina comparable - Galones usados por VE",
  "ev.cardetails.calc.electricEmissions":
    "Emisiones por consumo eléctrico en Lbs",
  "ev.cardetails.calc.electricEmissionsLbsMwh":
    "Emisiones de CO2 por consumo eléctrico en lbs/MWh",
  "ev.cardetails.calc.EIA":
    "Administración de información energética de Estados Unidos",
  "ev.cardetails.calc.gasolineEmissionsYr":
    "Emisiones por consumo de gasolina en lbs de CO2/yr",
  "ev.cardetails.calc.gasolineEmissions":
    "Emisiones de CO2 por consumo de gasolina en lbs/gal",
  "ev.cardetails.calc.emissionsReudctions": "Reducción de emisiones",
  "ev.cardetails.calc.lbsCo2PerYr": " lbs de CO2 al año",
  "ev.cardetails.calc.gasolineEmissionsLbs":
    "Emisiones por consumo de gasolina en lbs",
  "ev.cardetails.calc.arborDayFoundation": "Fundación Arbor Day",
  "ev.cardetails.calc.emissionsPerTree":
    "Emisiones capturadas por árbol por año",
  "ev.cardetails.calc.electricityEmissions":
    "Emisiones de CO2 por consumo eléctrico en lbs/MWh",
  "ev.usedCarTitle": "{car} Usado",
  "ev.cardetails.electricRange":
    "To account for battery degradation, the range quoted here is an estimation based on the age of the vehicle and normal driving/charging patterns.",
  "ev.carDetails.usedBatterySizeTooltip":
    "One kilowatt-hour (kWh) is enough energy to drive 1 to 4 miles, depending on the vehicle. To account for battery degradation for a used EV, the battery size quoted here is an estimation based on the age of the vehicle and normal driving/charging patterns.",

  "ev.purchaseMethod": "Forma de pago",
  "ev.purchaseMethodToolTip":
    "En modo renta, usted tiene que devolver el auto al final del periodo de arrendamiento. En el caso de financiación con un crédito, usted se queda con el auto al terminar de pagar el crédito.",
  "ev.cash": "Efectivo",
  "ev.loan": "Crédito",
  "ev.lease": "Renta",
  "ev.milesDrivenAnnually": "Millas conducidas por año",
  "ev.electricPortion": "Parte eléctrica de un PHEV",
  "ev.electricPortionTooltip":
    "A Plug-In Hybrid Electric Vehicle (PHEV) can use both electricity and gasoline. It is usually more beneficial to use electricity.",
  "ev.yearsOwnership": "Compra/renta al año",
  "ev.interestRate": "Tasa de interés",

  "assumption.salesTaxPercent": "Impuesto a las ventas %",
  "assumption.salesTax": "Impuesto a las ventas",
  "assumption.gasolinePrice": "Precio de gasolina",
  "assumption.downPayment": "Cuota inicial",
  "assumption.electricityRate": "Rango eléctrico",
  "assumption.downPaymentValue": "10% de MSRP más impuestos",

  "ev.compareGasVehicle":
    "Compare el {car} con un vehículo similar a gasolina, el {gasCar}.",
  "ev.compareOtherVehicles": "COMPRE OTHER VEHICLES",
  "ev.incentives.title":
    "Hasta {savings} en créditos fiscales y reembolsos están disponibles {lineBreak} para el {car}.",
  "afterIncentives.infoTooltip":
    'Rango de precio basado en MSRP y millaje estimado de por vida. Ver "Valor de reventa" en las "Fuentes" a continuación. Contacte su concesionario local para obtener información más precisa de precios.',
  "ev.reviews": "Reseñas en la web",

  "chargingMap.updateZipcode": "Actualizar código postal",
  "chargingMap.errorZipcode":
    "Error: {workingZipcode} no es un código postal válido",
  "chargingMap.showFullListOfStations": "lista completa de estaciones",
  "chargingMap.public": "Estaciones públicas",
  "chargingMap.businessGovernment": "Instaladas por empresas o el gobierno",
  "chargingMap.highPowerStations": "Estaciones de alta potencia",
  "chargingMap.DCChargers": "Carga rápida CC o súper-cargadores",
  "chargingMap.otherSttations": "Otros tipos de estaciones",
  "chargingMap.privateStations": "Estaciones rivadas",
  "chargingMap.searchDealers": "Buscar distribuidores calificados",
  "chargingMap.chargingStationCluster": "Grupo de estaciones de carga",
  "chargingMap.clusterOfChargingStations": "Varias estaciones de carga",
  "chargingMap.disclaimer":
    "Datos cortesía del Centro de datos de combustibles alternativos. Es posible que el mapa no refleje la disponibilidad más reciente de estaciones de carga.",

  "dealers.brands": "Marcas",
  "dealers.allBrands": "Todos las marcas",
  "dealers.allDealers": "Todos los distribuidores",
  "dealers.contact": "CONTACTO",
  "dealers.website": "SITIO WEB",
  "dealers.findDealers": "Encontrar distribuidores",

  "incentives.title": "Incentivos para Vehículos Eléctricos",
  "incentives.subTitle":
    "Usted puede ser elegible para un rango de incentivos, incluyendo reembolsos, créditos fiscales y otros beneficios. Los incentivos son personalizados según su lugar de residencia.",
  "incentives.single": "Soltero",
  "incentives.married": "Casado",
  "incentives.headOfHousehold": "Cabeza de hogar",
  "incentives.vehicleType": "Tipo de vehículo",
  "incentives.vehicleTypeTooltip":
    "Ciertos incentivos dependen de qué carro compres.",
  "incentives.tradeIn": "Intercambio",
  "incentives.income": "Estado de ingresos e impuestos",
  "incentives.incomeTooltip":
    "La elegiblidad para incentivos y la cantidad que puedes recibir depende normalmente de tu nivel de ingresos. Clientes con niveles de ingresos menores son usualmente elegibles para reembolsos mayores.",
  "incentives.clunkerNone":
    "Ciertos incentivos te compensan por retirar un vehículo existente, también conocido como carro viejo.",
  "incentives.clunkertoolTip":
    "Ciertos incentivos te compensan por retirar un vehículo existente, también conocido como carro viejo.",
  "incentives.clunkertoolTipContd":
    " Están disponibles en tu región. Los requisitos para elegibilidad varían por incentivo, por favor revise los detalles del programa.",
  "incentives.householdSize": "Tamaño del Hogar",
  "incentives.householdIncome": "Ingresos del Hogar",
  "incentives.planClunker": "¿Estás planeando intercambiar tu carro viejo?",
  "incentives.location": "Ubicación",
  "incentives.locationTooltip":
    "Muchos incentivos dependen del lugar en el que vives. Por favor ingresa tu código zip para ayudarte a determinar cuáles incentivos están disponibles en tu lugar de residencia.",
  "incentives.taxFilingStatus": "Estado Civil para Efectos de la Declaración",
  "incentives.whichVehicle": "¿Qué vehículo planeas comprar?",
  "incentives.incentiveEligibility": "Actualizar Elegibilidad para Incentivos",

  "homeChargers.subText":
    "Seleccione el vehículo que le interesa para ver los detalles de tiempo de carga total de la bateria.",
  "homeChargers.wallMounted": "Montaje en pared",
  "homeChargers.portable": "Portátil",
  "homeChargers.price": "Precio",
  "homeChargers.cordLength": "Longitud del cable",
  "homeChargers.wifi": "Conectividad Wifi",
  "homeChargers.socket": "Enchufe",
  "homeChargers.socketTooltip":
    "Cada modelo de cargador tiene un patrón de enchufe diferente. Estos tipos de patrón corresponden a la forma en la que las puntas encajan en la toma de corriente de la pared. Si no está seguro de cuál es el mejor para su hogar/garaje, consulte con un electricista calificado..",
  "homeChargers.cordLengthLtH": "Longitud del cable: Menor a mayor",
  "homeChargers.cordLengthHtL": "Longitud del cable: Mayor a menor",
  "homeChargersCard.buy": "Detalles y compra",
  "homeChargersCard.beforeIncentives": "Antes de incentivos",
  "homeChargersCard.socket": "Enchufe {socket}",
  "homeChargersCard.teslaWarning": "Este cargador solo funciona con Teslas",

  "homeChargers.cable": "Cable de {length} pies",
  "homeChargers.hardwired": "Enchufe cableado",
  "homeChargers.toFullCharge": "para carga completa",
  "homeChargers.wifiTooltip":
    "Algunos cargadores tienen la capacidad de conectarse a la red WIFI de su hogar para permitir funciones adicionales.",
  "chargercard.selectMakeModel":
    "*Seleccione la marca y modelo del carro para ver los tiempos de carga.",

  "electricians.residential": "Residencial",
  "electricians.commercial": "Comercial",
  "electricians.industrial": "Industrial",
  "electricians.type": "Tipo de Electricista",
  "electricians.searchElectricians": "Buscar Electricistas Calificados",

  "tabbed-maps-dealer-catalog-tab": "Distribuidores",
  "tabbed-maps-all-stations-tab": "Estaciones de Carga",
  "tabbed-maps-electricians-tab": "Electricistas",
  "tabbed-maps-route-tab": "Buscar una Ruta",

  // Todo: Spanish Translation
  "ev.showSources.electricityRate":
    "La tarifa de energía eléctrica se calcula como el promedio de cuatro meses de verano fuera de pico y ocho meses de invierno fuera de pico. Para obtener más información, haga clic {website}.",
  "ev.showSources.portionElectricSource":
    "Entrada del usuario. Para un vehículo híbrido, estima la cantidad de millas conducidas con la batería eléctrica en lugar de gasolina.",
  "ev.showSources.resaleValue": "Valor de reventa",
  "ev.showSources.resaleValueSource":
    "Función del precio de lista del vehículo y {lifetimeMiles} millas de vida útil, suponiendo una depreciación exponencial negativa con distancia donde el valor de reventa es del 60% después de 36,000 millas",
  "ev.showSources.totalVehicleCost": "Costo total del vehículo",
  "ev.showSources.cashFormula":
    "Precio de lista del Vehículo* (1 + Impuesto sobre las Ventas) - Incentivos - Valor de reventa",
  "ev.showSources.assumption": "Suposición:",
  "ev.showSources.loanAmount": "Monto del préstamo",
  "ev.showSources.loanFormula":
    "Precio de lista (1 + Impuesto sobre las Ventas) - Pago inicial",
  "ev.showSources.monthlyPayments": "Pagos mensuales",
  "ev.showSources.monthlyPaymentsSource":
    "Función financiera del Monto del Préstamo, Tasa de Interés y Meses de Propiedad",
  "ev.showSources.monthsOfOwnership": "Meses de propiedad",
  "ev.showSources.totalMonthlyPayments": "Total de pagos mensuales",
  "ev.showSources.totalMonthlyPaymentsSource":
    "Pago Mensual * Meses de Propiedad",
  "ev.showSources.totalLoanFormula":
    "Pago inicial + Total de Pagos Mensuales - Incentivos - Valor de Reventa",
  "ev.showSources.capitalizedIncentives": "Incentivos capitalizados",
  "ev.showSources.capitalizedIncentivesSource":
    "Incentivos capitalizados dentro del contrato de arrendamiento",
  "ev.showSources.capitalizedCost": "Costo capitalizado",
  "ev.showSources.capitalizedCostSource":
    "Precio de Lista - Pago inicial - Incentivos capitalizados",
  "ev.showSources.monthlyDepreciationCost": "Costo de depreciación mensual",
  "ev.showSources.monthlyDepreciationCostSource":
    "(Costo capitalizado - Valor reventa) / Meses de Propiedad",
  "ev.showSources.monthlyFinancingCost": "Costo de financiamiento mensual",
  "ev.showSources.monthlyFinancingCostSource":
    "(Costo capitalizado + Valor de reventa) * Factor dinero",
  "ev.showSources.moneyFactor": "Factor dinero",
  "ev.showSources.moneyFactorSource":
    "Equivalente a la entrada del usuario de los {interestRateAsBasisPoints}",
  "ev.showSources.monthlyLeasePayment": "Pago mensual de arrendamiento",
  "ev.showSources.monthlyLeasePaymentSource":
    "(Costo de depresiación mensual + Costo de financiamiento mensual) * (1 + Impuesto sobre las ventas)",
  "ev.showSources.firstLeasePayment": "Primera cuota de arrendamiento",
  "ev.showSources.firstLeasePaymentSource":
    "Pago inicial + Pago Mensual de Arrendamiento",
  "ev.showSources.nonCapLeaseIncentives":
    "Incentivos de Arrendamiento no capitalizados",
  "ev.showSources.nonCapLeaseIncentivesSource":
    "Incentivos no capturados en el costo de arrendamiento capitalizado",
  "ev.showSources.leaseForumla":
    "Primer Pago de Arrendamiento + (Meses de Propiedad - 1) * (Pago Mensual de Arrendamiento) - Incentivos de Arrendamiento No Capitalizados",
  "ev.showSources.vehicleKWh": "KWh del vehículo por 100 millas",
  "ev.showSources.EPAEfficiency": "Clasificación de eficiencia de la EPA",
  "ev.showSources.dollarPerMileDriven": "$/100 millas conducidas",
  "ev.showSources.dollarPerMileDrivenSource":
    "Costo de electricidad * KWh por cada 100 millas",
  "ev.showSources.lifetimeMiles": "Millas de vida util",
  "ev.showSources.totalElectricityCost": "Costo total de la electricidad",
  "ev.showSources.totalElectricityCostSource":
    "$/100 millas conducidas * Millas de por vida * {portionEVDriving}/100",
  "ev.showSources.gasolineCost": "Costo de la gasolina",
  "ev.showSources.vehicleMPG": "Millas por Galón (mpg)",
  "ev.showSources.totalGasolineCost": "Costo total de la gasolina",
  "ev.showSources.totalGasolineCostSource":
    "Costo total de la gasoline * Millas de vida util * (1 - Porción eléctrica usada) / Millas por Galón (mpg)",
  "ev.showSources.maintenancePerMile": "Mantenimiento por milla para gasolina",
  "ev.showSources.maintenancePerMileSource":
    "Extrapolación del costo de mantenimiento promedio de {avgMaintenanceCost}, impulsado por el MSRP del vehículo en relación con el MSRP promedio de {averageMSRP}",
  "ev.showSources.maintenanceCostReduction":
    "EV reducción de costos de mantenimiento",
  "ev.showSources.maintenanceCostReductionSource": "{website}",
  "ev.showSources.maintenancePerMileElectricity":
    "Mantenimiento por milla de electricidad",
  "ev.showSources.maintenancePerMileElectricitySource":
    "Mantenimiento por milla para gasolina * Mantenimiento por milla de electricidad",
  "ev.showSources.blendedMaintenanceCostPerMile":
    "Costo de mantenimiento combinado por milla",
  "ev.showSources.blendedMaintenanceCostPerMileSource":
    "Porción de conducción eléctrica aplicada al mantenimiento por milla para gasolina y electricidad",
  "ev.showSources.totalMaintenanceCost": "Costo total de mantenimiento",
  "ev.showSources.totalMaintenanceCostSource":
    "Costo de mantenimiento por milla * Millas de vida util",
  "ev.showSources.averageInsurancePerYear": "Seguro promedio por año",
  "ev.showSources.insurancePerYear": "Seguro por año",
  "ev.showSources.insurancePerYearSource":
    "Extrapolación del costo promedio del seguro en {state}, impulsado por el MSRP del vehículo en relación con el MSRP promedio del {msrp}",
  "ev.showSources.yearsOfOwnership": "Compra/renta al año",
  "ev.showSources.totalInsuranceCost": "Costo total del seguro",
  "ev.showSources.totalInsuranceCostSource":
    "Costo de seguro por año * Compra/renta al año",
  here: "aquí",
  sources: "fuentes",

  seeFullListOfStations: "Listado {scroll}",
  fullListOfStations: "completo de estaciones de carga",
  numOfStations: "#estaciones",
  publicChargingStationBasics:
    "Información general sobre las estaciones de carga públicas",
  publicChargingFAQ:
    "Preguntas frecuentes acerca de las estaciones de carga públicas",
  differentTypesOfChargingStationsQuestion:
    "¿Qué tipos de estaciones de carga existen?",
  thereAreThreeLevels:
    "Hay tres niveles de velocidad de carga EV: {levelOne}, {levelTwo} y {dcfc}. El tiempo que se tarda en cargar el EV depende del tamaño de la batería del VE, la velocidad de carga que pueda aceptar el vehículo y la velocidad de la estación de carga.",
  dcfcFastCharge: "Carga Rápida DC",
  levelOneChargingDescription:
    "Una hora conectada a un cargador de {levelOne} (en una toma de corriente estándar de EE. UU.) provisionará a un vehículo eléctrico de batería (BEV) o a un vehículo eléctrico híbrido enchufable (PHEV) con aproximadamente 2-10 millas de alcance adicional. Es mejor para recargas durante la noche cuando el carros no está en uso, o para los PHEVs que tienen baterías más pequeñas y pueden confiar en sus motores de gas integrados si se agota la carga de la batería.",
  levelTwoChargingDescription:
    "El {levelTwo} es bastante más rápido que el nivel 1 y actualmente es la opción más común para la carga pública. Una hora de carga de nivel 2 agregará aproximadamente 20-25 millas a la mayoría de los BEV y PHEVs.",
  dcfcChargingDescription:
    "{dcfc} es el tipo de cargador más rápido disponible hoy en día. Los conductores de BEV pueden esperar obtener más de 150 millas de alcance en una hora en el cargador. Para la mayoría de los BEVs, los conductores pueden esperar que casi el 80% de su alcance total de la batería se restaure en tan solo 30 minutos. Es importante tener en cuenta que los PHEVs (y algunos BEVs más antiguos) no son compatibles con las estaciones de DCFC.",
  teslaChargingDescription:
    "Las estaciones de recarga de Tesla son más similares a las velocidades de DCFC, pero solo se pueden recargar los carros de Tesla en las estaciones de carga públicas marca Tesla. Consulte a continuación para obtener más información sobre la compatibilidad de los conectores de carga y {browseElectricVehicles} para obtener más información sobre marcas y modelos específicos.",
  publicChargingEVQuestion:
    "¿Cómo selecciono una estación de carga pública que funcione para mi VE?",
  everyEVCompatible:
    "Hoy en día todos los VE en los EE. UU. son compatibles con alguno de los tres conectores de carga estándar: SAE J1772, CHAdeMO, & Tesla.",
  publicChargerOutlets: "Tomacorriente de cargadores públicos",
  saeDescription:
    "El SAE J1772 está diseñado para la carga de nivel 1 y nivel 2, a menos que esté equipado con el enchufe combinado CCS, que permite DCFC.",
  chademoDescription:
    "Los conectores CHAdeMO son compatibles con carga de nivel 1, nivel 2 y DCFC con el mismo conector universal.",
  teslaDescription:
    "Los Tesla se pueden recargar utilizando la red Tesla Supercharger con el conector Tesla estándar de su coche, pero tendrán que utilizar un adaptador para cargar en estaciones SAE J1772 o CHAdeMO.",
  dcfcChargerDescription:
    "Antes de utilizar un cargador rápido DC, obtenga información sobre el tipo de conector de su vehículo. Algunas estaciones de cargador rápido DC admiten conectores CHAdeMO y SAE J1772. La red de Tesla no es compatible con otros vehículos.",
  payingForChargingQuestion:
    "¿Debo pagar por un cargador? Si es así, ¿cómo lo hago?",
  payingForChargingAnswer:
    "Algunas estaciones de carga públicas son gratuitas y en algunas se requiere que el conductor pague. En la estación se puede permitir que un usuario pase su tarjeta de crédito directamente en el enchufe o se puede ofrecer suscripciones que se pueden pagar mensualmente a través de aplicaciones desde el teléfono celular. Vaya a los enlaces de la tabla de redes de carga públicas nacionales para obtener más información sobre las opciones existentes.",
  publicChargingEtiquette: "Normas de cortesía para la carga pública",
  publicChargingRules:
    "La mayoría de los usuarios de cargadores públicos siguen reglas muy sencillas para asegurarse de que todos puedan tener una buena experiencia. Algunas prácticas recomendadas son:",
  moveCarAnswer:
    "Cuando haya terminado de cargar, mueva su vehículo fuera del lugar de carga tan pronto como sea posible para que otros puedan usar el cargador.",
  unplugAnswer:
    "No desenchufe el vehículo de otra persona para enchufar el suyo.",
  topOffAnswer:
    "Recargue cada vez que pueda, no cuando tenga que hacerlo. Si añade un poco de carga en cada lugar que visita (especialmente cuando no está dentro del vehículo y entra a una tienda o un supermercado), es posible que pueda evitar evitar cargas prolongadas para recargar una batería que está casi vacía.",
  bevToFull: "BEV vacío a lleno: {about} {time} {hours}",
  about: "~",
  hours: "horas",
  phevToFull: "PHEV vacío a lleno: {about} {time} {hours}",
  levelOneDescription:
    "Mejor utilizarlo cuando el carro está inactivo durante muchas horas.",
  levelTwoDescription:
    "Casi siempre disponible en las estaciones de carga públicas para recargar rápidamente a tope mientras se encuentre en el camino o para recargar rápidamente en casa.",
  dcfcDescription:
    "Carga rápida disponible, con redes de estaciones públicas en aumento.",
  leastFast: "MENOS RÁPIDO",
  faster: "MÁS RÁPIDO",
  fastest: "EL MÁS RÁPIDO",
  milesOfRangePerHourCharge: "de alcance añadidas por cada hora cargada.",
  chargingSpeedDisclaimer:
    "Las cifras anteriores son representativas de un vehículo eléctrico de batería (BEV) con una capacidad de batería de 62 kW y un vehículo híbrido enchufable (PHEV) con una capacidad de batería de 14kW. Ambos vehículos tienen una calificación EPA de 31 kWh por 100 millas.",
  nationalPublicChargingNetworks: "REDES NACIONALES DE CARGA PÚBLICA",
  network: "Red",
  stations: "Estaciones",
  levelTwoStations: "Estaciones de nivel 2",
  dcfcStations: "Estaciones DCFC",
  costPerCharge: "Costo de carga",
  levelTwoCostPerCharge: "Costo de carga nivel 2",
  dcfcCostPerCharge: "Costo de carga DCFC",
  mixOfKwhAndSession: "Combinación de $/kWh y $/sesión, por estado",
  variesByLocation: "Varía según la ubicación",
  dollarPerMinuteVaryWithPowerAndState:
    "$/minuto, varía con el nivel de potencia y por estado",
  flatFeePerHour: "Tarifa fija - {cost}/hora",
  costPerHour: "{cost}/hora",
  costsPerHour: "{lowCost}/hora - {highCost}/hora",
  dollarPerMinuteByState: "$/minuto, por estado",
  free: "Gratis",
  flatFeePerSession: "Tarifa fija - $4.00/sesión",
  dollarPerSession: "$7.50/sesión",
  milesPerHourOfCharge: "{miles} millas oir 1 hora de carga",

  //SVCE Translations
  "header.poweredBy": "Patrocinado por",
  "header.exploreElectricCars": "Encuentre carros eléctricos",
  "header.browseElectricCars": "Buscar VEs Nuevos",
  "header.browseUsedElectricCars": "Buscar VEs Usados",
  "header.compareVehicles": "Compare vehículos",
  "header.chargingOptions": "Opciones de carga",
  "header.locatePublicChargingStations":
    "Encuentre estaciones de carga públicas",
  "header.shopHomeChargers": "Compre cargadores para el hogar",
  "header.findElectricians": "Encontrar electricistas",
  "header.discoverIncentives": "Descubra incentivos",
  "header.moreSites": "Más sitios ",
  "header.homeChargers": "Cargadores caseros",

  "footer.havingTrouble": "¿Tiene problemas para usar este sitio web?",
  "footer.contact": "Contacto: support@zappyride.com",

  "ev.jumbotron.compare": "Comparar",
  "ev.jumbotron.seeReviews": "Ver reseñas",

  "homepage.incentives.legalDisclaimer":
    "Silicon Valley Clean Energy presenta este sitio web como un recurso para los clientes. SVCE no respalda ni garantiza, tampoco provee ninguna garantía o representación con respecto a cualquier proveedor, contratista, servicio o producto. SVCE no será responsable de ninguna pérdida o daño de ningún tipo que surja de o esté conectado a cualquier proveedor, contratista, servicio o producto asociado con este sitio web. Cualquier transacción que usted realice con un proveedor, contratista u otro tercero se realiza únicamente entre usted y ese proveedor, contratista u otro tercero.",
  "homepage.locateChargingStations": "Encuentre estaciones de carga",

  "ev.carDetails.treeTooltip":
    "Un árbol absorbe CO2 a razón de 28 libras por año",
  "ev.carDetails.calc.emissionsSaved": "{CO2} libras de CO2 por árbol por año",
  gasolinePrice: "Precio de la Gasolina ($/GAL)",
  "ev.electricityRateCalc":
    "La tarifa de energía eléctrica se calcula como el promedio de cuatro meses de verano fuera de pico y ocho meses de invierno fuera de pico. Para obtener más información, haga clic {website}.",
  "ev.showSources.averageInsuranceCost":
    "Promedio del costo de seguros en {state}",
  "graph.speedOfCharge.lessThanFiftykW": "no se carga más rápido que 50kW",

  publicCharingStationMap: "Mapa de las estaciones de carga públicas",
  fullListOfDealers: "lista completa de distribuidores",
  evChargingSpeed: "VELOCIDAD DE CARGA DEL VE",
  comingSoon: "Próximamente, en breve, pronto",
};
