import React from "react";
import PropTypes from "prop-types";

import "./LocationCard.scss";

const LocationCard = ({location, type}) => {
  if (!location) return null
  let renderCard
  let street = location.street ? `${location.street}, ` : ""
  let city = `${location.city}, `
  let state = location.state
  let zip = location.zip ? location.zip : location.postcode
  let address = `${street}${city}${state} ${zip}`;

  switch (type) {
    case 'chargingStation':
      renderCard = (
        <div>
          <b>{location.station_name}</b> {location.street_address}, {location.city}, {location.state} {location.zip}
          &nbsp;
          <span className="badge badge-secondary">              
            {location.access_code}
          </span>
        </div>
      )
      break
    case 'dealer':
      renderCard = (
        <div>
          <b>{location.name}</b> 
          <br/> 
          {address}
          <br/> 
          {location.phone} 
          <br/> 
          {location.website}
          &nbsp;
      </div>
      )
      break
    case 'electrician':
      let commercial = location.commercial ? "Commercial" : ""
      let industrial = location.industrial ? "Industrial" : ""
      let residential = location.residential ? "Residential" : ""
      let renderType = [commercial, industrial, residential].map(type => {
        return (
          type ? 
          <span className="badge badge-secondary">
            {type}
          </span>
          :
          null
        )
      })
      renderCard = (
        <div>
          <b>{location.name}</b> 
          <br/> 
          {address}
          <br/> 
          {renderType}
          <br/> 
          {location.phone}
          <br/> 
          {location.website}
        </div>
      )
      break
      default:
        break
  }
  return (
    <div className="LocationCard">
      {renderCard}
    </div>
  );
};

LocationCard.propTypes = {
  chargingStation: PropTypes.object
};

export default LocationCard;
