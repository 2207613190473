import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import "./Homepage.scss";
import HomepageBigPromise from "./components/HomepageBigPromise";
import HomepageIncentives from "./components/HomepageIncentives";
import getLocalVehicles from "../../../functions/vehicle/getLocalVehicles";
import ShuffleFlatArray from "../../../utils/Helpers/ShuffleFlatArray";
import VehicleCarousel from "../VehicleCarousel/VehicleCarousel"
import ChargingMap from "../../../client_customizations/components/LocationMap/ChargingMap"
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import SortEVs from "../../../functions/vehicle/Sort/sortEVs"

import ad_728x90 from "../../assets/images/ads/ad-728x90.png";


const Homepage = ({
  homePageBannerImage,
  electricVehicles,
  incentives,
  userLocation,
  ip,
  uuid
}) => {

  const [vehicles, setVehicles] = useState([]);
  const setElectricVehicles = () => {
    if (electricVehicles) setVehicles(ShuffleFlatArray(electricVehicles));
  }

  useEffect(() => {
    setElectricVehicles();
  });

  const renderDetails = electricVehicles && incentives ?
    (
      <>
        <VehicleCarousel
          electricVehicles={SortEVs(getLocalVehicles(vehicles), "intermittenPrice")}
        />
        <HomepageIncentives incentives={incentives} />
      </>
    ) : (
      <section className="container">
        <LoadingSpinner />
      </section>
    );

  return (
    <>
      <HomepageBigPromise
        homePageBannerImage={homePageBannerImage}
      />
      {renderDetails}
      <section className="container-fluid">
       <div className="ad-container">
                     <div className="ad">
                            <a href="https://googleads.g.doubleclick.net/pcs/click?xai=AKAOjstl8trkQRzhWBvPwvn9S4kuK60S490aTGxJyDFzXTcO34QcBsYeBi8Q2-cLVx6HZEc8MeC69dKDrABSeDx0xYSF0-X6C1rxbTlRYTnVD7PHGw4dA04oBc2ke86wB_pXVfACHQXWv0wMD7GukaeTu3KrYSIjVvgKbRKg6Y2kroR18zF1UcOz6R2gQrGapzeRikIvXGBYPZUwAP_UYuGoBhFbqciiwiarUVgOTMiMZdvC2LGiyioGZYZ_R_avmTj82o95ggZK2tbuy6iQixF7OFJJtvKB-tj83vwCLW3qV8CzisdjIvQto842AYXozOORPjncPhQTxZri7EQs4DD-o5Zh_w&sai=AMfl-YTwwDUOETAoy9wBUZQu4faBJFlMdmnIfGI4SMl4TwfsY1izkP4paCZMmbkahjhfe1OXKSHL227RAicy5H41FTimcdbdkC6Lkk2IcnR-8SFKYbkrCRfSYCR9sbZ0u6vWoLkRxeJE&sig=Cg0ArKJSzIuD9nXzz3PA&fbs_aeid=[gw_fbsaeid]&adurl=https://ev.svcleanenergy.org/&nm=2&nx=279&ny=-36&mb=2" target="_blank"
                            rel="noopener noreferrer">
                                   <img src={ad_728x90} alt="ad" />
                            </a>
                     </div>
              </div>
        </section>

      <div id="HomepageChargingStations">
        <section className="container">
          <ChargingMap userLocation={userLocation} />
        </section>
      </div>
    </>
  );
};

export default Homepage;

Homepage.propTypes = {
  homePageBannerImage: PropTypes.string,
  electricVehicles: PropTypes.array,
  incentives: PropTypes.array,
  ip: PropTypes.string,
  uuid: PropTypes.string
};
