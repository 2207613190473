import calcMatchScore from "../MatchScore/calcMatchScore";
import cloneDeep from "lodash/cloneDeep";

const Private = {
  alphabeticalSort: function(evs, direction) {
    return evs.sort((a, b) => {
      let name = ev => `${ev.make} ${ev.model}  ${ev.trim} || ""}`;
      // let name = ev => `${ev.make} ${ev.model} ${ev.model_variant || ""}`;
      switch (direction) {
        case "asc":
          return name(a).localeCompare(name(b));
        case "desc":
          return name(b).localeCompare(name(a));
        default:
          return evs;
      }
    });
  },
  priceSort: function(evs, direction) {
    return evs.sort((a, b) => {
      let price = ev => parseInt(ev.msrp, 10);
      switch (direction) {
        case "asc":
          return price(a) - price(b);
        case "desc":
          return price(b) - price(a);
        default:
          return evs;
      }
    });
  },
  electricRangeSort: function(evs, direction) {
    return evs.sort((a, b) => {
      let range = ev => parseInt(ev.electric_range, 10);
      switch (direction) {
        case "asc":
          return range(a) - range(b);
        case "desc":
          return range(b) - range(a);
        default:
          return evs;
      }
    });
  },
  matchScoreSort: function(
    evs,
    direction,
    milesDrivenDaily,
    chargerLevel,
    maxBudget,
    minSeats,
    monthsOfOwnership,
    interestRateAsBasisPoints,
    milesDrivenAnnually,
    salesTax
  ) {
    return evs.sort((a, b) => {
      let matchScoreA = calcMatchScore(
        a,
        milesDrivenDaily,
        chargerLevel,
        maxBudget,
        minSeats,
        monthsOfOwnership,
        interestRateAsBasisPoints,
        milesDrivenAnnually,
        salesTax
      ).total;
      let matchScoreB = calcMatchScore(
        b,
        milesDrivenDaily,
        chargerLevel,
        maxBudget,
        minSeats,
        monthsOfOwnership,
        interestRateAsBasisPoints,
        milesDrivenAnnually,
        salesTax
      ).total;

      let name = ev => `${ev.make} ${ev.model}  ${ev.trim} || ""}`;
      // let name = ev => `${ev.make} ${ev.model} ${ev.model_variant || ""}`;

      switch (direction) {
        case "asc":
          return matchScoreA - matchScoreB || name(a).localeCompare(name(b));
        case "desc":
          return matchScoreB - matchScoreA || name(a).localeCompare(name(b));
        default:
          return 1;
      }
    });
  },
  intermittenPriceSort: function(evs) {
    const priceSortedEvs = evs.sort((a, b) => {
      let price = ev => parseInt(ev.msrp, 10);
      return price(a) - price(b);
    })

    let inexpensiveEvs = priceSortedEvs.slice(0, Math.floor(priceSortedEvs.length/2))
    let expensiveEvs = priceSortedEvs.slice(Math.floor(priceSortedEvs.length/2))

    // Fisher-Yates Shuffle
    const shuffleHelper = (array) => {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * i)
        const temp = array[i]
        array[i] = array[j]
        array[j] = temp
      }
      return array
    }

    inexpensiveEvs = shuffleHelper(inexpensiveEvs)
    expensiveEvs = shuffleHelper(expensiveEvs)

    let intermittenArray = [], leftIndex = 0, rightIndex = 0

    while (leftIndex < inexpensiveEvs.length && rightIndex < expensiveEvs.length) {
      intermittenArray.push(inexpensiveEvs[leftIndex])
      intermittenArray.push(expensiveEvs[rightIndex])
      leftIndex++
      rightIndex++
    }

    return intermittenArray.concat(expensiveEvs.slice(rightIndex))

  }
};

const sortEVs = (
  evs,
  sortType,
  sortDirection,
  milesDrivenDaily,
  chargerLevel,
  maxBudget,
  minSeats,
  monthsOfOwnership,
  interestRateAsBasisPoints,
  milesDrivenAnnually
) => {
  const clonedEvs = cloneDeep(evs);

  switch (sortType) {
    case "alphabetical":
      return Private.alphabeticalSort(clonedEvs, sortDirection);
    case "price":
      return Private.priceSort(clonedEvs, sortDirection);
    case "electric_range":
      return Private.electricRangeSort(clonedEvs, sortDirection);
    case "intermittenPrice":
      return Private.intermittenPriceSort(clonedEvs)
    case "match_score":
    default:
      return Private.matchScoreSort(
        clonedEvs,
        sortDirection,
        milesDrivenDaily,
        chargerLevel,
        maxBudget,
        minSeats,
        monthsOfOwnership,
        interestRateAsBasisPoints,
        milesDrivenAnnually
      );
  }
};

export default sortEVs;
