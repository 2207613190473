import React, { useContext } from "react";
import PropTypes from "prop-types";
import UserPrefsContext from "../../context/UserPrefs/UserPrefsContext";

import ChargerCard from "../../client_customizations/components/ChargerCard/ChargerCard"
import sortChargers from "../../functions/chargers/Sort/sortChargers"
import fitlerChargers from "../../functions/chargers/Filter/filterChargers"
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

const ChargerCatalog = ({
  homeChargers, 
  selectedVehicle
}) => {
  const userPrefs = useContext(UserPrefsContext);

  if (!homeChargers) return <LoadingSpinner />;
  const sortedhomeChargers = sortChargers(
    homeChargers,
    userPrefs.get("chargerSortType"),
    userPrefs.get("chargerSortDirection"),
  );

// Filter CHARGERS HERE
  const filteredAndSortedChargers = fitlerChargers(sortedhomeChargers, {
    chargerFormFactorFilters: userPrefs.get("chargerFormFactorFilters"),
    chargerTypeFilters: userPrefs.get("chargerTypeFilters"),
    chargerWifi: userPrefs.get("chargerWifiFilter"),
    chargerBudget: userPrefs.get("chargerBudget"),
    chargerCordLength: userPrefs.get("chargerCordLength")
  });

  if (!filteredAndSortedChargers.length) {
    return (
      <div className="text-center">
        <p className="h4">No Chargers found</p>
      </div>
    );
  }

  const renderCards = filteredAndSortedChargers.map((charger, index) => {
    return (
        <div key={index}className="evc-card ChargerCard">
            <ChargerCard
              charger={charger}
              selectedVehicle={selectedVehicle}
            />
        </div>
    );
  });

  return <div className="render-cards-container">{renderCards}</div>;
};

export default ChargerCatalog;

ChargerCatalog.propTypes = {
    homeChargers: PropTypes.array,
    selectedVehicle: PropTypes.object
};
