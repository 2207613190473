import React from "react";
import PropTypes from "prop-types";
import IncentiveCatalog from "../IncentiveCatalog/IncentiveCatalog";
import IncentivePreferences from "../IncentivePreferences/IncentivePreferences"
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import { FormattedMessage, useIntl } from 'react-intl';
import ad_728x90 from "../../../client_customizations/assets/images/ads/ad-728x90.png";

const Incentives = ({ electricVehicles, incentives, ip, uuid }) => {
  const intl = useIntl()
  const renderIncentives = incentives ? (
    <IncentiveCatalog incentives={incentives} category="all" />
  ) : (
    <LoadingSpinner />
  );



  return (
    <>
      <section className="container">
        <div className="row">
          <div className="col-sm-12">
            <h1>
              <FormattedMessage 
                id="homepage.incentives.title"
                defaultMessage="Discover Incentives"
                description="Discover Incentives"
              />
            </h1>
            <div className="text-center">
              <IncentivePreferences
                incentives={incentives}
                electricVehicles={electricVehicles}
                titleText={intl.formatMessage ? intl.formatMessage({ id: "personalizeIncentives", defaultMessage: "Personalize Incentives"}) : "Personalize Incentives"}
                btnText={intl.formatMessage ? intl.formatMessage({ id: "personalizeIncentives", defaultMessage: "Personalize Incentives"}) : "Personalize Incentives"}
              />
            </div>
          </div>
        </div>
        <br />
        {renderIncentives}
        <br />
      </section>

      <div className="container text-center">
              <div className="ad-container" style={{marginBottom: "50px"}}>
                     <div className="ad">
                            <a href="https://googleads.g.doubleclick.net/pcs/click?xai=AKAOjstl8trkQRzhWBvPwvn9S4kuK60S490aTGxJyDFzXTcO34QcBsYeBi8Q2-cLVx6HZEc8MeC69dKDrABSeDx0xYSF0-X6C1rxbTlRYTnVD7PHGw4dA04oBc2ke86wB_pXVfACHQXWv0wMD7GukaeTu3KrYSIjVvgKbRKg6Y2kroR18zF1UcOz6R2gQrGapzeRikIvXGBYPZUwAP_UYuGoBhFbqciiwiarUVgOTMiMZdvC2LGiyioGZYZ_R_avmTj82o95ggZK2tbuy6iQixF7OFJJtvKB-tj83vwCLW3qV8CzisdjIvQto842AYXozOORPjncPhQTxZri7EQs4DD-o5Zh_w&sai=AMfl-YTwwDUOETAoy9wBUZQu4faBJFlMdmnIfGI4SMl4TwfsY1izkP4paCZMmbkahjhfe1OXKSHL227RAicy5H41FTimcdbdkC6Lkk2IcnR-8SFKYbkrCRfSYCR9sbZ0u6vWoLkRxeJE&sig=Cg0ArKJSzIuD9nXzz3PA&fbs_aeid=[gw_fbsaeid]&adurl=https://ev.svcleanenergy.org/&nm=2&nx=279&ny=-36&mb=2" target="_blank"
                            rel="noopener noreferrer">
                                   <img src={ad_728x90} alt="ad" />
                            </a>
                     </div>
              </div>
       </div>
    </>
  );
};

export default Incentives;

Incentives.propTypes = {
  incentives: PropTypes.array,
  electricVehicles: PropTypes.array,
  zip: PropTypes.string,
  ip: PropTypes.string,
  uuid: PropTypes.string
};
