import isBEV from "../../functions/vehicle/isBEV";
import isPHEV from "../../functions/vehicle/isPHEV";
import {
  ELECTRICITY_RATE_IN_DOLLARS_PER_KWH
} from "../../client_customizations/data/assumptions/ASSUMPTIONS"

const calcElectricRangeForBudget = (kwhPerHundredMiles, budget) => {
  if (kwhPerHundredMiles === 0) return 0;

  return (
    budget *
    (1 / ELECTRICITY_RATE_IN_DOLLARS_PER_KWH.value) *
    (100 / kwhPerHundredMiles)
  );
};

const calcGasolineRangeForBudget = (mpg, budget, gas_price) => {
  return budget * (1 / gas_price) * mpg;
};

const calcVehicleRangeForBudget = (
  vehicle,
  budget,
  electricMilesPortionForPhev,
  gasPrice
) => {
  if (isBEV(vehicle)) {
    return calcElectricRangeForBudget(vehicle.electric_efficiency, budget);
  }

  if (isPHEV(vehicle)) {
    return (
      (electricMilesPortionForPhev / 100) *
        calcElectricRangeForBudget(vehicle.electric_efficiency, budget, gasPrice) +
      (1 - electricMilesPortionForPhev / 100) *
        calcGasolineRangeForBudget(vehicle.fossil_fuel_efficiency, budget, gasPrice)
    );
  }

  return calcGasolineRangeForBudget(vehicle.fossil_fuel_efficiency, budget, gasPrice);
};

export default calcVehicleRangeForBudget;
