export const loadMaintenanceData = async (
  cars,
  userPrefs,
  setMaintenanceData,
  setIsLoading
) => {
  const params = {
    annualmiles: userPrefs.get("milesDrivenAnnually"),
    postcode: userPrefs.get("zipcode"),
  };

  let maintenanceData = [];
  for (let i = 0; i < cars.length; i++) {
    let vehicle_handle = cars[i].handle;
    let url = new URL(
      `${process.env.REACT_APP_EV_INFO_API_HOST}/vehicles/${vehicle_handle}/maintenance`
    );
    let searchParams = new URLSearchParams(params);
    url.search = searchParams;

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${process.env.REACT_APP_EV_INFO_TKN}`,
        },
      });
      if (response.status === 200) {
        const resultData = await response.json();
        maintenanceData.push({
          handle: vehicle_handle,
          maintenance: resultData.maintenance.costs,
        });
      }
    } catch (error) {
      console.log("Failed to load maintenance data.");
      console.log(error);
    }
  }

  setMaintenanceData(maintenanceData);
  setIsLoading(false);
};
