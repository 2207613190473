import React, { useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import FuelRangeChart from "../../../components/CostsCharts/FuelRangeChart"
import MonthlyFuelCostChart from "../../../components/CostsCharts/MonthlyFuelCostChart";
import { FormattedMessage } from "react-intl"

// import EVCard from "../EVCard/EVCard";
import EVCard from "../EVCard/EVCard"

const VehicleCarousel = ({ electricVehicles }) => {
  const [selectedEv, setSelectedEv] = useState(1);
  const [firstEv, setFirstEv] = useState(
    Math.floor(electricVehicles.length * Math.random())
  );

  if (!electricVehicles || electricVehicles.length <= 3) return null;

  const vehicleCount = electricVehicles ? electricVehicles.length : "many";
  const maxIndex = vehicleCount - 1;

  const evIndices = [
    firstEv,
    firstEv + 1 <= maxIndex ? firstEv + 1 : firstEv + 1 - vehicleCount,
    firstEv + 2 <= maxIndex ? firstEv + 2 : firstEv + 2 - vehicleCount,
    firstEv + 3 <= maxIndex ? firstEv + 3 : firstEv + 3 - vehicleCount
  ];

  const filteredEVs = [
    electricVehicles[evIndices[0]],
    electricVehicles[evIndices[1]],
    electricVehicles[evIndices[2]],
    electricVehicles[evIndices[3]]
  ];

  const selectableEvs = filteredEVs.map((ev, i) => {
    return (
      <div
        onClick={() => setSelectedEv(i)}
        onKeyPress={() => setSelectedEv(i)}
        className={`${"evc-card EVCard " +
          (selectedEv === i ? " selected" : "not-selected")}`}
        tabIndex="0"
        key={i}
      >
        <EVCard
          ev={ev}
          hasLinkToEv={selectedEv === i}
          hasLocallyAvailableHidden
        />
      </div>
    );
  });

  const linkstoEvs = filteredEVs.map((ev, i) => {
    return (
      <Link
        to={`${"/vehicles/" + ev.handle}`}
        className="evc-card EVCard"
        key={i}
      >
        <EVCard ev={ev} hasLocallyAvailableHidden />
      </Link>
    );
  });

  const renderVehicles = (
    <>
      <div className="row d-none d-lg-flex d-xl-flex">
        <div className="col-sm-1 text-right">
          <button
            className="btn btn-default"
            style={{ marginTop: "105px", fontSize: "21px" }}
            onClick={() =>
              firstEv === 0 ? setFirstEv(maxIndex) : setFirstEv(firstEv - 1)
            }
          >
            &lsaquo;
          </button>
        </div>
        <div className="col-sm-10">
          <div className="render-cards-container full-width">
            {selectableEvs}
          </div>
        </div>
        <div className="col-sm-1">
          <button
            className="btn btn-default"
            style={{ marginTop: "105px", fontSize: "21px" }}
            onClick={() =>
              firstEv === maxIndex ? setFirstEv(0) : setFirstEv(firstEv + 1)
            }
          >
            &rsaquo;
          </button>
        </div>
      </div>

      <div className="render-cards-container full-width d-flex d-lg-none d-xl-none">
        {linkstoEvs}
      </div>
    </>
  );

  const renderVehicleCostChart = (
    <div className="d-none d-lg-block d-xl-block">
      <div
        className="arrow-up"
        style={{ left: selectedEv * 20.5 + 17 + "%" }}
      />
      <FuelRangeChart cars={[electricVehicles[evIndices[selectedEv]], electricVehicles[evIndices[selectedEv]].equivalent_gas_vehicle]} />
    </div>
  );


  const renderMonthlyFuelCostChart = (
    <div className="d-none d-lg-block d-xl-block">
      <div
        className="arrow-up"
        style={{ left: selectedEv * 20.5 + 17 + "%" }}
      />
      <MonthlyFuelCostChart hasExtraPadding={true} cars={[electricVehicles[evIndices[selectedEv]], electricVehicles[evIndices[selectedEv]].equivalent_gas_vehicle]} />
    </div>
  );

  return (
    <section className="container-fluid pb-0" id="HomepageVehiclesCarousel">
      <div className="row ">
        <div className="col-md-9">
          <h2>
            <FormattedMessage 
              id="header.exploreElectricCars"
              defaultMessage="Explore Electric Cars"
              description="Explore Electric Cars"
            />
          </h2>
          <p className="homepage-sub-title">
            <FormattedMessage 
              id="homepage.vehicleCarousel.title"
              defaultMessage="There are {vehicleCount} electric cars available. Discover Yours"
              description="Vehicle Carousel Title"
              values= {{
                vehicleCount: vehicleCount
              }}
            />
          </p>
        </div>
        <div className="col-md-3">
          <Link to="/vehicles" className="btn btn-svce" role="button">
            <FormattedMessage 
              id="homepage.vehicleCarousel.findEVButton"
              defaultMessage="See All Cars"
              description="Vehicle Carousel See All Cars"
            />
          </Link>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-sm-12">{renderVehicles}</div>
        <div
          className="col-sm-12 d-none d-md-block"
          style={{ marginTop: "1rem" }}
        >
          {process.env.REACT_APP_PAGES_HOME_GRAPH_BUDGET_ENABLED ? (renderVehicleCostChart) : (null)}
          {process.env.REACT_APP_PAGES_HOME_GRAPH_COST_TO_FILL_ENABLED ? (renderMonthlyFuelCostChart) : (null)}
        </div>
      </div>
    </section>
  );
};

export default VehicleCarousel;

VehicleCarousel.propTypes = {
  electricVehicles: PropTypes.array
};
