import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";
import { TabContent, TabPane, Nav } from "reactstrap";

import calcEmissions from "./../../../functions/vehicle/Emissions/calcEmissions";
import isPHEV from "../../../functions/vehicle/isPHEV";

import {
  FormatCarName,
  FormatAsInt,
  FormatAsThousands,
  FormatAsPercentRounded,
  FormatAsTime
} from "./../../../utils/Helpers/Format";
import {
  ELECTRICITY_EMISSIONS_IN_LBS_PER_MWH,
  EMISSIONS_PER_TREE_IN_LBS_OF_CO2,
  GASOLINE_EMISSIONS_IN_LBS_PER_GAL
} from "../../../client_customizations/data/assumptions/ASSUMPTIONS"
import calcCharging from "../../../functions/vehicle/Charging/calcCharging";
import {FormattedMessage, useIntl} from 'react-intl';

const CarDetailsCalcs = ({ car }) => {
  const intl = useIntl()
  const [activeTab, setActiveTab] = useState("1");
  const toggle = tab => setActiveTab(tab);

  const userPrefs = useContext(UserPrefsContext);
  const milesDrivenAnnually = userPrefs.get("milesDrivenAnnually");
  const electricMilesPortionForPhev = userPrefs.get(
    "electricMilesPortionForPhev"
  );

  const carIsPHEV = isPHEV(car);
  const equivalentGasVehicle = car.equivalent_gas_vehicle || {};
  const comparisonText = (
    <p className="text-left">
      <FormattedMessage 
        id="ev.carDetails.compareText"
        defaultMessage="We selected the {gasVehicle} based on its
        proximity to the {vehicle} based on brand, size, and price."
        description="Compare Vehicle Text"
        values= {{
          gasVehicle: FormatCarName(equivalentGasVehicle),
          vehicle: FormatCarName(car),
        }}
      />
    </p>
  );

  return (
    <div className="CalcPanels">
      <Nav className="nav-fill">
        <li>
          <button
            className={`btn flex-grow-1 mr-1 ${
              activeTab === "1" ? "active" : ""
            }`}
            onClick={() => toggle("1")}
          >
            <span>{intl.formatMessage({ id: "charging", defaultMessage: "Charging"})}</span>
          </button>
        </li>

        <li>
          <button
            className={`btn flex-grow-1 mr-1 ${
              activeTab === "2" ? "active" : ""
            }`}
            onClick={() => toggle("2")}
          >
            <span>{intl.formatMessage({ id: "evfilter.fuel", defaultMessage: "Fuel"})}</span>
          </button>
        </li>

        <li>
          <button
            className={`btn flex-grow-1 mr-1 ${
              activeTab === "3" ? "active" : ""
            }`}
            onClick={() => toggle("3")}
          >
            <span>{intl.formatMessage({ id: "emissions", defaultMessage: "Emissions"})}</span>
          </button>
        </li>
      </Nav>
      <TabContent activeTab={activeTab}>
        <br />
        <TabPane tabId="1">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th scope="col">
                  {intl.formatMessage({ id: "description", defaultMessage: "Description"})}
                </th>
                <th scope="col">{FormatCarName(car)}</th>
                <th scope="col">
                  {intl.formatMessage({ id: "source", defaultMessage: "Source"})}  
                </th>
              </tr>
            </thead>
            <tbody>
              {!carIsPHEV && (
                <>
                  <tr>
                    <th scope="row">
                      {intl.formatMessage({ id: "ev.cardetails.calc.dcRate", defaultMessage: "DC Fast Charging (DCFC) Rate"})}
                    </th>
                    <td>{calcCharging.speedInKw("dc_fast_charger")} kW</td>
                    <td />
                  </tr>
                  <tr>
                    <th scope="row">kWh per 100 mi</th>
                    <td>{car.electric_efficiency} kWh</td>
                    <td>
                      {intl.formatMessage({ id: "ev.cardetails.calc.EPA", defaultMessage: "EPA Efficiency Rating"})}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      {intl.formatMessage({ id: "vehicle.milesPerThirtyFastCharge", defaultMessage: "MILES PER 30 MIN OF FAST CHARGING"})}
                    </th>
                    <td>
                      ~{" "}
                      {FormatAsInt(
                        calcCharging.milesPerHalfHourOfCharge(
                          car.electric_efficiency,
                          "dc_fast_charger"
                        )
                      )}{" "}
                      {intl.formatMessage({ id: "vehicle.miles", defaultMessage: "miles"})}
                    </td>
                    <td>
                      {intl.formatMessage({ id: "ev.cardetails.calc.chargingRate", defaultMessage: "Charging Rate"})} / (kWh per 100 mi / 100) / 2
                    </td>
                  </tr>
                </>
              )}
              <tr>
                <th scope="row">
                  {intl.formatMessage({ id: "ev.cardetails.calc.vehicleBatteryCapacity", defaultMessage: "Vehicle Battery Capacity"})}
                </th>
                <td>{car.battery_capacity} kWh</td>
                <td />
              </tr>
              <tr>
                <th scope="row">
                  {intl.formatMessage({ id: "ev.cardetails.calc.maxACIntake", defaultMessage: "Vehicle Max AC Intake"})}
                  </th>
                <td>{car.ac_charging_power} kW</td>
                <td />
              </tr>
              <tr>
                <th scope="row">
                  {intl.formatMessage({ id: "ev.cardetails.calc.lvlTwoChargingRate", defaultMessage: "Level 2 Charging Rate"})}
                </th>
                <td>{calcCharging.speedInKw("level_2")} kW</td>
                <td />
              </tr>
              <tr>
                <th scope="row">
                  {intl.formatMessage({ id: "ev.cardetails.calc.chargingRate", defaultMessage: "Charging Rate"})}
                </th>
                <td>
                  {Math.min(
                    calcCharging.speedInKw("level_2"),
                    car.ac_charging_power
                  )}{" "}
                  kW
                </td>
                <td>
                  {intl.formatMessage({ id: "ev.cardetails.calc.minOflvl2AC", defaultMessage: "Minimum of Level 2 Charging Rate and Vehicle Max AC Intake"})}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th scope="row">
                  {intl.formatMessage({ id: "ev.cardetails.calc.lvl2FullCharge", defaultMessage: "Time to Full Charge for Level 2"})}
                </th>
                <td>
                  ~{" "}
                  {FormatAsTime(
                    calcCharging.timeForFullBattery(
                      car.battery_capacity,
                      car.ac_charging_power,
                      "level_2"
                    )
                  )}
                </td>
                <td>
                  {intl.formatMessage({ id: "ev.cardetails.calc.vehicleBatteryCapacity", defaultMessage: "Vehicle Battery Capacity"})} / {intl.formatMessage({ id: "ev.cardetails.calc.chargingRate", defaultMessage: "Charging Rate"})}
                </td>
              </tr>
            </tfoot>
          </table>
        </TabPane>
        <TabPane tabId="2">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th scope="col">
                  {intl.formatMessage({ id: "description", defaultMessage: "Description"})}
                </th>
                <th scope="col">{FormatCarName(car)}</th>
                <th scope="col">{FormatCarName(equivalentGasVehicle)}</th>
                <th scope="col">
                  {intl.formatMessage({ id: "source", defaultMessage: "Source"})}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">
                  {intl.formatMessage({ id: "ev.cardetails.calc.milesDrivenPerYr", defaultMessage: "Miles driven per year"})}
                  </th>
                <td colSpan="2">{FormatAsThousands(milesDrivenAnnually)}</td>
                <td>
                  {intl.formatMessage({ id: "ev.cardetails.calc.userInput", defaultMessage: "User Input"})}
                </td>
              </tr>
              <tr>
                <th scope="row">
                  {intl.formatMessage({ id: "ev.cardetails.calc.milesPerGallon", defaultMessage: "Miles Per Gallon (MPG)"})}
                </th>
                <td>
                  {carIsPHEV && car.fossil_fuel_efficiency + intl.formatMessage({ id: "ev.cardetails.calc.mpg", defaultMessage: " miles/gal"})}
                  {!carIsPHEV && "N/A"}
                </td>
                <td>{equivalentGasVehicle.fossil_fuel_efficiency + intl.formatMessage({ id: "ev.cardetails.calc.mpg", defaultMessage: " miles/gal"})}</td>
                <td />
              </tr>
              {carIsPHEV && (
                <tr>
                  <th scope="row">
                    {intl.formatMessage({ id: "ev.cardetails.calc.portionElectric", defaultMessage: "Portion of Electric Driving"})}
                  </th>
                  <td>{FormatAsPercentRounded(electricMilesPortionForPhev)}</td>
                  <td>N/A</td>
                  <td>
                    {intl.formatMessage({ id: "ev.cardetails.calc.portionElectricSource", defaultMessage: "User input. For a hybrid vehicle, estimates the portion of miles driven using the electric battery instead of gasoline."})}
                  </td>
                </tr>
              )}
              <tr>
                <th scope="row">
                  {intl.formatMessage({ id: "ev.cardetails.calc.gallonsUsed", defaultMessage: "Gallons Used"})}
                  </th>
                <td>
                  {carIsPHEV &&
                    FormatAsThousands(
                      calcEmissions.gallonsUsed(
                        car,
                        milesDrivenAnnually,
                        electricMilesPortionForPhev
                      )
                    )}
                  {!carIsPHEV && "N/A"}
                </td>
                <td>
                  {FormatAsThousands(
                    calcEmissions.gallonsUsed(
                      equivalentGasVehicle,
                      milesDrivenAnnually,
                      electricMilesPortionForPhev
                    )
                  )}
                </td>
                <td>
                  {intl.formatMessage({ id: "ev.cardetails.calc.gallonsUsedFormula", defaultMessage: "Miles driven * (1 - Portion of Electric Driving) / MPG"})}
                  </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th scope="row">
                  {intl.formatMessage({ id: "ev.cardetails.calc.gallonsSaved", defaultMessage: "Gallons Saved"})}
                </th>
                <td colSpan="2">
                  {FormatAsThousands(
                    calcEmissions.gallonsSaved(
                      car,
                      milesDrivenAnnually,
                      electricMilesPortionForPhev
                    )
                  )}
                </td>
                <td>
                  {intl.formatMessage({ id: "ev.cardetails.calc.gallonsSavedFormula", defaultMessage: "Gallons used by Equivalent Gas Vehicle - Gallons used by EV"})}
                </td>
              </tr>
            </tfoot>
          </table>
          {comparisonText}
        </TabPane>
        <TabPane tabId="3">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th scope="col">
                  {intl.formatMessage({ id: "description", defaultMessage: "Description"})}
                </th>
                <th scope="col">{FormatCarName(car)}</th>
                <th scope="col">{FormatCarName(equivalentGasVehicle)}</th>
                <th scope="col">
                  {intl.formatMessage({ id: "source", defaultMessage: "Source"})}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">
                  {intl.formatMessage({ id: "ev.cardetails.calc.electricityEmissions", defaultMessage: "Electricity Emissions in CO2 lbs/MWh"})}
                </th>
                <td>{ELECTRICITY_EMISSIONS_IN_LBS_PER_MWH.valueAsFormatted}</td>
                <td>N/A</td>
                <td />
              </tr>
              <tr>
                <th scope="row">
                  {intl.formatMessage({ id: "ev.cardetails.calc.milesDrivenPerYr", defaultMessage: "Miles driven per year"})}
                </th>
                <td colSpan="2">{FormatAsThousands(milesDrivenAnnually)}</td>
                <td>
                  {intl.formatMessage({ id: "ev.cardetails.calc.userInput", defaultMessage: "User Input"})}
                </td>
              </tr>
              {carIsPHEV && (
                <tr>
                  <th scope="row">
                    {intl.formatMessage({ id: "ev.cardetails.calc.portionElectric", defaultMessage: "Portion of Electric Driving"})}
                  </th>
                  <td>{FormatAsPercentRounded(electricMilesPortionForPhev)}</td>
                  <td>N/A</td>
                  <td>
                    {intl.formatMessage({ id: "ev.cardetails.calc.portionElectricSource", defaultMessage: "User input. For a hybrid vehicle, estimates the portion of miles driven using the electric battery instead of gasoline."})}
                  </td>
                </tr>
              )}
              <tr>
                <th scope="row">kWh per 100 mi</th>
                <td>{car.electric_efficiency} kWh</td>
                <td>N/A</td>
                <td>
                  {intl.formatMessage({ id: "ev.cardetails.calc.EPA", defaultMessage: "EPA Efficiency Rating"})}
                </td>
              </tr>
              <tr>
                <th scope="row">
                  {intl.formatMessage({ id: "ev.cardetails.calc.electricEmissions", defaultMessage: "Electric Emissions in Lbs"})}
                  </th>
                <td>
                  {carIsPHEV &&
                    FormatAsThousands(
                      calcEmissions.electricEmissionsInLbs(
                        car.electric_efficiency,
                        milesDrivenAnnually *
                          (electricMilesPortionForPhev / 100)
                      )
                    )}
                  {!carIsPHEV &&
                    FormatAsThousands(
                      calcEmissions.electricEmissionsInLbs(
                        car.electric_efficiency,
                        milesDrivenAnnually
                      )
                    )}
                </td>
                <td>N/A</td>
                <td>
                  {intl.formatMessage({ id: "ev.cardetails.calc.electricEmissionsLbsMwh", defaultMessage: "Electric Emissions in Lbs/MWh"})} *
                  {carIsPHEV && <span>{intl.formatMessage({ id:"ev.cardetails.calc.portionElectric", defaultMessage: "Portion of Electric Driving"})} *</span>}{" "}
                  {intl.formatMessage({ id: "ev.cardetails.calc.milesDrivenPerYr", defaultMessage: "Miles driven per year"})} * kWh per 100 mi / (100 * 1000)
                </td>
              </tr>
              <tr>
                <th scope="row">{intl.formatMessage({ id: "ev.cardetails.calc.gasolineEmissionsYr", defaultMessage: "Gasoline Emissions in lbs of CO2/yr"})}</th>
                <td>
                  {carIsPHEV &&
                    GASOLINE_EMISSIONS_IN_LBS_PER_GAL.valueAsFormatted}
                  {!carIsPHEV && "N/A"}
                </td>
                <td>{GASOLINE_EMISSIONS_IN_LBS_PER_GAL.valueAsFormatted}</td>
                <td>
                  <a
                    href={GASOLINE_EMISSIONS_IN_LBS_PER_GAL.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {intl.formatMessage({ id: "ev.cardetails.calc.EIA", defaultMessage: "U.S. Energy Information Administration"})}
                  </a>
                </td>
              </tr>
              <tr>
                <th scope="row">
                  {intl.formatMessage({ id: "ev.cardetails.calc.milesPerGallon", defaultMessage: "Miles Per Gallon (MPG)"})}
                </th>
                <td>
                  {carIsPHEV && car.fossil_fuel_efficiency + intl.formatMessage({ id: "ev.cardetails.calc.mpg", defaultMessage: " miles/gal"})}
                  {!carIsPHEV && "N/A"}
                </td>
                <td>{equivalentGasVehicle.fossil_fuel_efficiency + intl.formatMessage({ id: "ev.cardetails.calc.mpg", defaultMessage: " miles/gal"})}</td>
                <td />
              </tr>
              <tr>
                <th scope="row">
                  {intl.formatMessage({ id: "ev.cardetails.calc.gasolineEmissionsYr", defaultMessage: "Gasoline Emissions in lbs of CO2/yr"})}
                </th>
                <td>
                  {carIsPHEV &&
                    FormatAsThousands(
                      calcEmissions.gasolineEmissionsInLbs(
                        car.fossil_fuel_efficiency,
                        milesDrivenAnnually *
                          (1 - electricMilesPortionForPhev / 100)
                      )
                    )}
                  {!carIsPHEV && "N/A"}
                </td>
                <td>
                  {FormatAsThousands(
                    calcEmissions.gasolineEmissionsInLbs(
                      equivalentGasVehicle.fossil_fuel_efficiency,
                      milesDrivenAnnually
                    )
                  )}
                </td>
                <td>
                  {intl.formatMessage({ id: "ev.cardetails.calc.gasolineEmissions", defaultMessage: "Gasoline Emissions In Lbs per Gallon"})} * {intl.formatMessage({ id: "ev.cardetails.calc.milesDrivenPerYr", defaultMessage: "Miles driven per year"})}{" "}
                  {carIsPHEV && (
                    <span>* (1 - {intl.formatMessage({ id:"ev.cardetails.calc.portionElectric", defaultMessage: "Portion of Electric Driving"})}) </span>
                  )}
                  / {intl.formatMessage({ id: "ev.cardetails.calc.milesPerGallon", defaultMessage: "Miles Per Gallon (MPG)"})}
                </td>
              </tr>
              <tr>
                <th scope="row">
                  {intl.formatMessage({ id: "ev.cardetails.calc.emissionsReudctions", defaultMessage: "Emissions Reductions"})}
                </th>
                <td colSpan="2">
                  {FormatAsThousands(
                    calcEmissions.reductionInLbs(
                      car,
                      milesDrivenAnnually,
                      electricMilesPortionForPhev
                    )
                  ) + intl.formatMessage({ id: "ev.cardetails.calc.lbsCo2PerYr", defaultMessage: " lbs of CO2 per year"})}
                </td>
                <td>
                  {intl.formatMessage({ id: "ev.cardetails.calc.gasolineEmissionsLbs", defaultMessage: "Gasoline Emissions in Lbs"})} - {intl.formatMessage({ id: "ev.cardetails.calc.electricEmissions", defaultMessage: "Electric Emissions in Lbs"})}</td>
              </tr>
              <tr>
                <th scope="row">
                  {intl.formatMessage({ id: "ev.cardetails.calc.emissionsPerTree", defaultMessage: "Emissions Saved per Tree"})}
                </th>
                <td colSpan="2">
                  <FormattedMessage 
                    id="ev.carDetails.calc.emissionsSaved"
                    defaultMessage="{CO2} lbs of CO2 per tree per year"
                    description="Emissions Saved Per Year"
                    values= {{
                      CO2 : EMISSIONS_PER_TREE_IN_LBS_OF_CO2.value
                    }}
                  />
                </td>
                <td>
                  <a
                    href={EMISSIONS_PER_TREE_IN_LBS_OF_CO2.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {intl.formatMessage({ id: "ev.cardetails.calc.arborDayFoundation", defaultMessage: "Arbor Day Foundation"})}
                  </a>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th scope="row">
                  {intl.formatMessage({ id: "ev.carDetails.treesPlanted", defaultMessage: "Trees Planted"})}
                </th>
                <td colSpan="2">
                  {FormatAsThousands(
                    calcEmissions.treesPlanted(
                      car,
                      milesDrivenAnnually,
                      electricMilesPortionForPhev
                    )
                  ) + " trees"}
                </td>
                <td>
                  {intl.formatMessage({ id: "ev.cardetails.calc.emissionsReudctions", defaultMessage: "Emissions Reductions"})} /                   {intl.formatMessage({ id: "ev.cardetails.calc.emissionsPerTree", defaultMessage: "Emissions Saved per Tree"})}</td>
              </tr>
            </tfoot>
          </table>
          {comparisonText}
        </TabPane>
      </TabContent>
    </div>
  );
};

export default CarDetailsCalcs;

CarDetailsCalcs.propTypes = {
  car: PropTypes.object
};