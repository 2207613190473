import React, {useState, useContext } from "react";
import {ValidateFiveDigitCode} from "../../../utils/Helpers/USPostalCodeValidator"; 

import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";

import IconZip from "../../../client_customizations/assets/images/icons/icon-zip.png";

import { FormattedMessage } from 'react-intl';

const InputZipcodeForChargingMap = () => {
  const userPrefs = useContext(UserPrefsContext);

  const [workingZipcode,setWorkingZipcode] = useState(
    userPrefs.get("zipcode")
  );

  const isInvalid = userPrefs.zipcodeIsNotFound && !userPrefs.zipcodeIsUpdating;

  const id = "input-zipcode-for-charging-map";
  const idAria = id + "-help";


  const handleUpdateButtonPress = (e, input) => {
    if (input && e.key !== "Enter") return

    if(ValidateFiveDigitCode(workingZipcode) && !userPrefs.zipcodeIsUpdating){

      async function asyncCall() {
        await userPrefs.set({ workingZipcode: workingZipcode });
        if (!userPrefs.zipcodeIsUpdating) {
          userPrefs.syncWorkingZipcode();
        }
      }
      asyncCall()
    }
  }

  return (
    <>
      <div>
        <div className="form-group">
          <label htmlFor={id} id={idAria}>
            <FormattedMessage 
              id="zipcode"
              defaultMessage="Zipcode"
              description="Zipcode"
            />
          </label>
          
          <div className="input-group mb-2 mr-sm-2">
            <div className="input-group-prepend">
              <div className="input-group-text">
                <img src={IconZip} alt="" style={{ width: 15 }} />
              </div>
            </div>
            <input
              id={id}
              className={isInvalid ? "form-control is-invalid" : "form-control"}
              aria-describedby={idAria}
              value={workingZipcode}
              onChange={e => setWorkingZipcode (e.target.value)}
              onKeyDown={e => handleUpdateButtonPress(e, "input")}
            />
          </div>
        </div>
        <div className="form-group">
          <button
            type="button"
            className="btn-svce btn-default mb-2 btn-block"
            onClick={() => handleUpdateButtonPress()}
            disabled={userPrefs.zipcodeIsUpdating}
            id="update-zipcode-button"
          >
            <FormattedMessage 
              id="chargingMap.updateZipcode"
              defaultMessage="Update Zipcode"
              description="Update Zipcode"
            />
          </button>
        </div>
      </div>
      {(isInvalid || !ValidateFiveDigitCode( workingZipcode)) && (
        <div className="text-danger small">
          Error: {workingZipcode} is not a valid zipcode
        </div>
      )}
    </>
  );
};

export default InputZipcodeForChargingMap;
