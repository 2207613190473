import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import CarGallery from "../../../components/EVJumbotron/CarGallery/CarGallery";
import CarDetails from "../../../client_customizations/components/EVJumbotron/CarDetails/CarDetails"
import CarDetailsCalcs from "../../../components/EVJumbotron/CarDetailsCalcs/CarDetailsCalcs"

import VehicleImage from "../../../components/VehicleImage/VehicleImage"
import PricePanels from "../../../components/EVJumbotron/PricePanels/PricePanels"
import { FormatCarName } from "../../../utils/Helpers/Format";
import ShowHideButton from "../../../components/ShowHideButton/ShowHideButton"
import SmoothScroll from "../../../utils/Helpers/SmoothScroll";
import arrow from "../../assets/images/grey-arrow.svg"

import { FormattedMessage, useIntl } from 'react-intl';
import UsedVehiclePanels from "../../../components/EVJumbotron/UsedVehiclePanels/UsedVehiclePanels";

const EVJumbotron = ({ car, paymentDetails, cars, setUsedEv }) => {
  const intl = useIntl()
  if (!car) return null;

  //Error Checking code to ensure that pictures have both a thumbnail and full version sutible for display
  // It will remove any null value 
  let imagesSrc = car && car["images"] ? car.images.map((n) =>
    (n.url_thumbnail == null) ? [] :
      (n.url_full == null) ? [] :
        [n]) : [];

//Flatten evImgs into a single array                  
const images = [].concat.apply([], imagesSrc);
let carImages = images.map(image => {
  return {
    original: image.url_full,
    thumbnail: image.url_thumbnail,
    originalAlt: image["legal_info"],
    thumbnailAlt: image.title
  };
});
carImages.shift();

  return (
    <>
      <section className="container" id="ev-jumbotron-title">
        <img className="grey-arrow" src={arrow} alt="arrow" />
        {setUsedEv ? (
          <Link to="/used-vehicles">
            <button style={{ whiteSpace: "nowrap" }} className="btn-back">
              <FormattedMessage
                id="ev.jumbotron.viewAllCars"
                defaultMessage="View All Cars"
                description="View All Cars"
              />
            </button>
          </Link>
        ) : (
          <Link to="/vehicles">
            <button style={{ whiteSpace: "nowrap" }} className="btn-back">
              <FormattedMessage
                id="ev.jumbotron.viewAllCars"
                defaultMessage="View All Cars"
                description="View All Cars"
              />
            </button>
          </Link>
        )}
        <div className="row">
          <div className="col">
            <h1>
              {setUsedEv ? (
                <FormattedMessage
                  id="ev.usedCarTitle"
                  defaultMessage="Used {car}"
                  description="Used Car Title"
                  values={{
                    car: FormatCarName(car),
                  }}
                />
              ) : (
                FormatCarName(car)
              )}
            </h1>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-7 col-lg-8">
            <div className="text-center offset-lg-1 col-lg-10 col-md-12">
              <VehicleImage image={images[0]} size="full" alt={FormatCarName(car)} />
            </div>
          </div>
          <div className="col-sm-12 col-md-5 col-lg-4">
            <PricePanels car={car} paymentDetails={paymentDetails} />
            <UsedVehiclePanels cars={cars} car={car} setUsedEv={setUsedEv} />
            <br />
            <div className="row">
              {!setUsedEv ? <div className="col text-center">
                <Link to="/compare-vehicles">
                  <button className="btn-svce-wide">
                    <FormattedMessage
                      id="ev.jumbotron.compare"
                      defaultMessage="Compare"
                      description="Compare"
                    />
                  </button>
                </Link>
              </div> : null}
              {/* <div className="col text-center">
                <Link to="/dealers">
                  <button className="btn-svce-wide">
                    <FormattedMessage
                      id="ev.jumbotron.findADealer"
                      defaultMessage="Find a Dealer"
                      description="Find a Dealer"
                    />
                  </button>
                </Link>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      <section className="container" id="ev-jumbotron-details">
        <div className="row">
          <div className="col-md-7">
            <CarDetails car={car} setUsedEv={setUsedEv} />
            <br />
            <div className="row">
              {car.video_reviews.length > 0 ? (
                <button
                  className="btn-svce see-reviews"
                  onClick={(e) => SmoothScroll("EVReviews")}
                >
                  <FormattedMessage
                    id="ev.jumbotron.seeReviews"
                    defaultMessage="See Reviews"
                    description="See Reviews"
                  />
                </button>
              ) : null}
              <ShowHideButton
                buttonText={` ${intl.formatMessage({
                  id: "detailedCalculations",
                  defaultMessage: "Detailed Calculations",
                })}`}
                displayAlertOnlyOnMobile
              >
                <div className="input-well">
                  <CarDetailsCalcs car={car} />
                </div>
              </ShowHideButton>
            </div>
          </div>
          <div className="col-md-5">
            <CarGallery carImages={carImages} />
          </div>
        </div>
        <br />
      </section>
    </>
  );
};

export default EVJumbotron;

EVJumbotron.propTypes = {
  car: PropTypes.object,
  paymentDetails: PropTypes.object
};
