import * as Calcs from "./calcs";

const Private = {
  perYear: function(costPerMile, milesDrivenAnnually) {
    if (!costPerMile || !milesDrivenAnnually) return 0;
    return costPerMile * milesDrivenAnnually;
  },

  perMonth: function(costPerMile, milesDrivenAnnually) {
    if (!costPerMile || !milesDrivenAnnually) return 0;
    return this.perYear(costPerMile, milesDrivenAnnually) / 12;
  },

  total: function(costPerMile, milesDrivenAnnually, monthsOfOwnership) {
    if (!costPerMile || !milesDrivenAnnually || !monthsOfOwnership) return 0;
    return this.perMonth(costPerMile, milesDrivenAnnually) * monthsOfOwnership;
  }
};

// vehicle could be electric or gas
const calcTotalCostOfOwnership = (
  vehicle,
  purchaseMethod,
  monthsOfOwnership,
  milesDrivenAnnually,
  interestRateAsBasisPoints,
  electricMilesPortionForPhev,
  gasolinePriceInCentsPerGal,
  includeResaleValue,
  salesTax,
  insuranceData,
  maintenanceData
) => {

  let vehicleCostPerMile = Calcs.VehicleCost.perMile(
    vehicle,
    milesDrivenAnnually,
    monthsOfOwnership,
    interestRateAsBasisPoints,
    purchaseMethod,
    includeResaleValue
  );
  let electricityPerMile = Calcs.ElectricityCost.perMile(
    vehicle,
    {
      /* placeholder for electricityCostInDollarsPerKwh */
    },
    electricMilesPortionForPhev
  );
  let gasPerMile = Calcs.GasolineCost.perMile(
    vehicle,
    electricMilesPortionForPhev,
    gasolinePriceInCentsPerGal
  );
  let maintenancePerMile = Calcs.MaintenanceCost.perMile(
    vehicle,
    milesDrivenAnnually,
    monthsOfOwnership,
    electricMilesPortionForPhev,
    maintenanceData
  );
  let insurancePerMile = Calcs.InsuranceCost.perMile(
    vehicle,
    milesDrivenAnnually
  );
  let summedPerMile =
    vehicleCostPerMile +
    electricityPerMile +
    gasPerMile +
    maintenancePerMile +
    insurancePerMile;

    function calculatePremiumInsurance(data, year) {
       let sum = 0;
   
       for (const item of data) {
         if (item.year_reference <= year) {
           sum += item.insurance_premium;
         } else {
           break;
         }
       }
       return sum;
     }
     function calculateMaintenanceCost(data, year) {
       let sum = 0;
       for (const item of data) {
         if (item.year_reference <= year) {
           sum += item.annual_cost;
         } else {
           break;
         }
       }
       return sum;
     }
   
     let newInsuranceValue = insuranceData?.length && calculatePremiumInsurance(insuranceData, (monthsOfOwnership/12))

     let maintenanceCost =
       maintenanceData?.length &&
       calculateMaintenanceCost(
         maintenanceData[0].maintenance,
         monthsOfOwnership / 12
       );
     
     if (vehicle && !vehicle.equivalent_gas_vehicle) {
       newInsuranceValue = newInsuranceValue * 0.75;
     }
     if (
       maintenanceData?.length &&
       maintenanceData[1] &&
       vehicle &&
       !vehicle.equivalent_gas_vehicle
     ) {
       maintenanceCost =
         (maintenanceData?.length &&
           calculateMaintenanceCost(
             maintenanceData[1].maintenance,
             monthsOfOwnership / 12
           )) ||
         0;
     } else if (vehicle && !vehicle.equivalent_gas_vehicle) {
       maintenanceCost = maintenanceCost * 0.7;
     }

  return {
    vehicle: {
      perMile: vehicleCostPerMile,
      perMonth: Private.perMonth(vehicleCostPerMile, milesDrivenAnnually),
      perYear: Private.perYear(vehicleCostPerMile, milesDrivenAnnually),
      total: Private.total(
        vehicleCostPerMile,
        milesDrivenAnnually,
        monthsOfOwnership
      ),
    },
    electricity: {
      perMile: electricityPerMile,
      perMonth: Private.perMonth(electricityPerMile, milesDrivenAnnually),
      perYear: Private.perYear(electricityPerMile, milesDrivenAnnually),
      total: Private.total(
        electricityPerMile,
        milesDrivenAnnually,
        monthsOfOwnership
      ),
    },
    gasoline: {
      perMile: gasPerMile,
      perMonth: Private.perMonth(gasPerMile, milesDrivenAnnually),
      perYear: Private.perYear(gasPerMile, milesDrivenAnnually),
      total: Private.total(gasPerMile, milesDrivenAnnually, monthsOfOwnership),
    },
    maintenance: {
      perMile: maintenancePerMile,
      perMonth: Private.perMonth(maintenancePerMile, milesDrivenAnnually),
      perYear: Private.perYear(maintenancePerMile, milesDrivenAnnually),
      total: maintenanceCost ? maintenanceCost : 0,
    },
    insurance: {
      perMile: insurancePerMile,
      perMonth: Private.perMonth(insurancePerMile, milesDrivenAnnually),
      perYear: Private.perYear(insurancePerMile, milesDrivenAnnually),
      total: newInsuranceValue ? newInsuranceValue : 0,
    },
    summed: {
      perMile: summedPerMile,
      perMonth: Private.perMonth(summedPerMile, milesDrivenAnnually),
      perYear: Private.perYear(summedPerMile, milesDrivenAnnually),
      total: Private.total(
        summedPerMile,
        milesDrivenAnnually,
        monthsOfOwnership
      ),
    },
    car: vehicle,
  };
};

export default calcTotalCostOfOwnership;
