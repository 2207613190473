import React, { useContext } from "react";
import PropTypes from "prop-types";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext"

import ToolTip from "../../../components/shared/ToolTip/ToolTip"
import "./HomeChargersFilterControls.scss"

import SlideHomeChargerPrice from "../../../components/InputComponents/SlideHomeChargerPrice/SlideHomeChargerPrice"
import SlideHomeChargerCordLength from "../../../components/InputComponents/SlideHomeChargerCordLength/SlideHomeChargerCordLength"
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from "react-router-dom"

import sixTwenty from "../../assets/images/icons/6-20.svg"
import sixThirty from "../../assets/images/icons/6-30.svg"
import tenThirty from "../../assets/images/icons/10-30.svg"
import fourteenThirty from "../../assets/images/icons/14-30.svg"
import sixFifty from "../../assets/images/icons/6-50.svg"
import fourteenFifty from "../../assets/images/icons/14-50.svg"
import fourteenSixty from "../../assets/images/icons/14-60.svg"
import wifiIcon from "../../assets/images/icons/wifi_icon.svg"

const HomeChargersFilterControls = (
  {
    makeOption,
    onChangeMake,
    vehicleMakeOptions,
    modelOption,
    onChangeModelType,
    vehicleModelOptions,
    electricVehicles
  }
) => {
  const intl = useIntl()
  const userPrefs = useContext(UserPrefsContext);

  const formFactorFilterData = userPrefs.get("chargerFormFactorFilters");
  const chargerWifi = userPrefs.get("chargerWifiFilter")
  const chargerGridShift = userPrefs.get("chargerGridShiftFilter")
  const chargerTypeFilterData = userPrefs.get("chargerTypeFilters")

  const socketTypeWords = { 
    "6-20" : sixTwenty,
    "6-30" : sixThirty, 
    "10-30" : tenThirty,
    "14-30" : fourteenThirty,
    "6-50" : sixFifty,
    "14-50" : fourteenFifty,
    "14-60" : fourteenSixty,
  }

  const updateChargerWifiFilter = selectedKey => {
    const newFilters = Object.keys(chargerWifi).reduce(function(
      acc,
      key
    ) {
      // Toggle selected key, set all others to false
      acc[key] = key === selectedKey ? !chargerWifi[key] : false;
      return acc;
    },
    {});

    userPrefs.set({
        chargerWifiFilter: newFilters
    });
  };

  const updateChargerGridShiftFilter = selectedKey => {
       const newFilters = Object.keys(chargerGridShift).reduce(function(
         acc,
         key
       ) {
         // Toggle selected key, set all others to false
         acc[key] = key === selectedKey ? !chargerGridShift[key] : false;
         return acc;
       },
       {});
   
       userPrefs.set({
              chargerGridShiftFilter: newFilters
       });
     };

  const renderChargerWifiFilter = Object.keys(chargerWifi).map(
    (filterKey, i) => {
      let filterName = "";
      switch (filterKey) {
        case "yes":
          filterName = intl.formatMessage({ id: "yes", defaultMessage: "Yes"})
          ;
          break;
        case "no":
          filterName = intl.formatMessage({ id: "no", defaultMessage: "No"});
          break;
        default:
      }

      return (
        <button
          className={chargerWifi[filterKey] ? "btn active text-center" : "btn text-center"}
          key={i}
          type="button"
          onClick={() => updateChargerWifiFilter(filterKey)}
        >
          {filterName}
        </button>
      );
    }
  );

  const renderChargerGridShiftFilter = Object.keys(chargerGridShift).map(
       (filterKey, i) => {
         let filterName = "";
         switch (filterKey) {
           case "yes":
             filterName = intl.formatMessage({ id: "yes", defaultMessage: "Yes"})
             ;
             break;
           case "no":
             filterName = intl.formatMessage({ id: "no", defaultMessage: "No"});
             break;
           default:
         }
   
         return (
           <button
             className={chargerGridShift[filterKey] ? "btn active text-center" : "btn text-center"}
             key={i}
             type="button"
             onClick={() => updateChargerGridShiftFilter(filterKey)}
           >
             {filterName}
           </button>
         );
       }
     );

  const updateFormFactorFilter = key => {
    let newFilters = Object.assign({}, formFactorFilterData);
    newFilters[key] = !newFilters[key];

    userPrefs.set({
        chargerFormFactorFilters: newFilters
    });
  };

  const renderedFormFactorFilters = Object.keys(formFactorFilterData).map(
    (filterKey, i) => {
      const filterName = `${filterKey.charAt(0).toUpperCase()}${filterKey.substr(1)}`;
      
      return (
        <button
          className={formFactorFilterData[filterKey] ? "btn active" : "btn"}
          key={i}
          type="button"
          aria-pressed={!!formFactorFilterData[filterKey]}
          onClick={() => updateFormFactorFilter(filterKey)}
        >
          <span className="socket-text">
          {filterName === "Hardwired" ? intl.formatMessage({ id: "homeChargers.hardwired", defaultMessage: "Hardwired"}) :filterName}
          </span>
          {filterName === "Hardwired" ? null : 
          <img className="socket-image" src={socketTypeWords[filterName]} alt={filterName === "Hardwired" ? intl.formatMessage({ id: "homeChargers.hardwired", defaultMessage: "Hardwired"}) :filterName}/>
          }
        </button>
      );
    }
  );

  const updateChargerTypeFilter = selectedKey => {
    const newFilters = Object.keys(chargerTypeFilterData).reduce(function(
      acc,
      key
    ) {
      // Toggle selected key, set all others to false
      acc[key] = key === selectedKey ? !chargerTypeFilterData[key] : false;
      return acc;
    },
    {});

    userPrefs.set({
        chargerTypeFilters: newFilters
    });
  };

  const renderedChargerTypeFilters = Object.keys(chargerTypeFilterData).map(
    (filterKey, i) => {
      let filterName = "";
      switch (filterKey) {
        case "mounted":
          filterName = intl.formatMessage({ id: "homeChargers.wallMounted", defaultMessage: "Wall Mounted"})
          break;
        case "portable":
          filterName = intl.formatMessage({ id: "homeChargers.portable", defaultMessage: "Portable"})
          break;
        default:
      }

      return (
        <button
          className={chargerTypeFilterData[filterKey] ? "btn active text-center" : "btn text-center"}
          key={i}
          type="button"
          onClick={() => updateChargerTypeFilter(filterKey)}
        >
          {filterName}
        </button>
      );
    }
  );

  
  return (
    <>
      <div className="form-group d-none d-lg-block">
          <p className="lead lead-home-chargers">
            <FormattedMessage
                id="homeChargers.subText"
                defaultMessage="Select the vehicle you’re interested in to see the specific charging time to a full battery."
              />
          </p>
        <select
          className="form-control select-iid"
          value={makeOption}
          onChange={onChangeMake}
          aria-label={intl.formatMessage({ id: "compareVehicles.selectMake", defaultMessage: "Select a Make"})}
        >
          <option defaultValue="">
            {intl.formatMessage({ id: "compareVehicles.selectMake", defaultMessage: "Select a Make"})}
          </option>
          {vehicleMakeOptions(electricVehicles)}
        </select>
      </div>
      <div className="form-group d-none d-lg-block">
        <select
          className="form-control select-iid"
          value={modelOption}
          onChange={onChangeModelType}
          aria-label={intl.formatMessage({ id: "compareVehicles.selectModel", defaultMessage: "Select a Model"})}
        >
          <option defaultValue="">
            {intl.formatMessage({ id: "compareVehicles.selectModel", defaultMessage: "Select a Model"})}
          </option>
          {vehicleModelOptions(electricVehicles, makeOption)}
        </select>
      </div>
      <div className="input-well hcFilterControls">
      <p className="h2">
        <FormattedMessage 
          id="evfilter"
          defaultMessage="Filter"
          description="Filter"
        />
      </p>
      <form>
      <div className="form-group">
          <div className="btn-block-container">
              <SlideHomeChargerPrice />
          </div>
        </div>
        <div className="form-group">
          <div className="btn-block-container">
              <SlideHomeChargerCordLength />
          </div>
        </div>
        <div className="form-group">
          <img className="wifi-icon" src={wifiIcon} alt="wifi icon"/>
          <span className="label-style">
            <FormattedMessage 
            id="homeChargers.wifi"
            defaultMessage="WiFi Connectivity"
            description="WiFi Connectivity"
            />
            <ToolTip
              message={intl.formatMessage({ id: "homeChargers.wifiTooltip", defaultMessage: "Some chargers have the ability to connect to your home’s WIFI network to allow for additional features."})}
              id="wifi_tooltip"
            />
          </span>
          <div className="btn-grid-container">
            {renderChargerWifiFilter}
          </div>
        </div>
        <div className="form-group">
          <span className="label-style">
            <FormattedMessage 
            id="homeChargers.gridshiftQualified"
            defaultMessage="GridShift qualified chargers"
            description="GridShift qualified chargers"
            />
          </span>
          <div className="btn-grid-container">
            {renderChargerGridShiftFilter}
          </div>
        </div>

        <div className="form-group">
          <span className="label-style">
            <FormattedMessage 
              id="vehicle.type"
              defaultMessage="Type"
              description="Type"
            />
          </span>
          <div className="btn-grid-container">{renderedChargerTypeFilters}</div>
        </div>
        <div className="form-group">
          <span className="label-style">
            <FormattedMessage 
              id="homeChargers.socket"
              defaultMessage="Socket"
              description="Socket"
            />
            <ToolTip
              message= {intl.formatMessage({ id: "homeChargers.socketTooltip", defaultMessage: "ifferent model chargers have different plug patterns. These plug patterns correspond to the way prongs fit into your wall outlet. If you're note sure which is best for your home/garage, check with a qualified electrician."})}
              id="socket_tooltip"
            />
          </span>
          <div className="btn-grid-container">{renderedFormFactorFilters}</div>
        </div>
      </form>
      {process.env.REACT_APP_PAGES_ELECTRICIANS_ENABLED && 
        <>
          <hr />
          <div className="find-electricians">
            <p>
              Find qualified 
              <br />
              electricians near you.
            </p>
            <Link to="/electricians" target="_blank" rel="noopener noreferrer">
              <button style={{whiteSpace: "nowrap"}}className="btn-electricians">
                See Electricians
              </button>
            </Link>
          </div>
        </>
      }
    </div>
    </>
  );
};

export default HomeChargersFilterControls;

HomeChargersFilterControls.propTypes = {
  makeOption: PropTypes.string,
  onChangeMake: PropTypes.func,
  vehicleMakeOptions: PropTypes.func,
  modelOption: PropTypes.string,
  onChangeModelType: PropTypes.func,
  vehicleModelOptions: PropTypes.func,
  electricVehicles: PropTypes.array
}
