import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Uuid from "../../../utils/Uuid/Uuid"


import ChargerCatalog from "../../../components/ChargerCatalog/ChargerCatalog"
import ChargerSortControls from "../../../components/ChargerSortControls/ChargerSortControls";
import HomeChargersFilterControls from "../HomeChargersFilterControls/HomeChargersFilterControls";
import ModalComponent from "../../../components/ModalUIComponent/ModalUIComponent";

import {FormattedMessage, useIntl} from 'react-intl';
import ad_160x600 from "../../../client_customizations/assets/images/ads/ad-160x600.png";

const vehicleMakeOptions = (vehicles) => {

  let vehicleMakes = new Set()
  vehicles.forEach(vehicle => {
    if (vehicle) {
      vehicleMakes.add(vehicle.make)
    }
  })

  vehicleMakes = [...vehicleMakes]
  
  vehicleMakes.sort((a, b) => {
    return a.localeCompare(b)
  })

  return vehicleMakes.map(make => {
    return (
      <option
        value={make}
        key={make}
      >
        {make}
      </option>
    )
  })
}

const vehicleModelOptions = (vehicles, makeOption) => {

  if (makeOption) {
    vehicles = vehicles.filter(vehicle => {
      return vehicle.make === makeOption
    })
  
  vehicles.sort((a, b) => {
    return a.model.localeCompare(b.model)
  })
    
    return vehicles.map(vehicle => {
      const modelAndTrim = vehicle.trim ? `${vehicle.model} ${vehicle.trim}` : vehicle.model
      return (
        <option
          value={modelAndTrim}
          key={Uuid()}
          data-handle={vehicle.handle}
        >
          {modelAndTrim}
        </option>
      )
    }
  )}
}

const HomeChargers = ({ electricVehicles, homeChargers, ip, uuid }) => {
  const intl = useIntl()
  useEffect(() => {
    document.title = process.env.REACT_APP_PAGES_HOME_CHARGERS_TITLE;
  });

  const [makeOption, setMakeOption] = useState('')
  const [modelOption, setModelOption] = useState('')
  const [ev, setEv] = useState(null)

  if (!electricVehicles) {
    return null;
  }

  const onChangeMake = e => {
    const newMakeType = e.target.value
  
    setMakeOption(newMakeType)
  }

  const onChangeModelType = e => {
    const newModelType = e.target.value
    const vehicleHandle = e.target.options[e.target.selectedIndex].dataset.handle
  
    if (newModelType === 'Select a Model') {
      setModelOption(newModelType)
      return
    } 
  
    const selectedVehicle = electricVehicles.find(vehicle => {
      return vehicle.handle === vehicleHandle
    })
  
    setModelOption(newModelType)
    setEv(selectedVehicle)
  }

  const time = new Date().toLocaleString();

  const renderOptions = (
    <>
      <HomeChargersFilterControls 
            makeOption={makeOption}
            onChangeMake={onChangeMake}
            vehicleMakeOptions={vehicleMakeOptions}
            modelOption={modelOption}
            onChangeModelType={onChangeModelType}
            vehicleModelOptions={vehicleModelOptions}
            electricVehicles={electricVehicles}
      />
    </>
  );

  return (
    <section className="container-fluid" id="homeChargers">
      <div className="container">
        <div className="row pt-4 pb-3">
          <div className="col-sm-12 text-center">
            <h1>
              <FormattedMessage
                id="header.homeChargers"
                defaultMessage="Home Chargers"
              />
            </h1>
          </div>
        </div>
        
        <div className="row d-xs-block d-lg-none">
          <div className="col-xs-12 grid-shift-mobile-col">
            <div>
              <a href="https://www.svcleanenergy.org/gridshift-ev/" className="btn btn-svce btn-gridshift" target="_blank" rel="noopener noreferrer" style={{marginBottom:"25px", display: "block"}}>
                Learn about GridShift Smart EV Charging
              </a>
              <a href="https://svcleanenergy.org/ev-charger-rebate/" className="btn btn-svce btn-gridshift" target="_blank" rel="noopener noreferrer" style={{marginBottom:"10px", display: "block"}}>
                SVCE offering $250 Rebate on qualified chargers
              </a>
            </div>
          </div>
        </div>


        <div className="row d-lg-none">
          <div className="col-12">
          <p className="lead lead-home-chargers">
            <FormattedMessage
                id="homeChargers.subText"
                defaultMessage="Select the vehicle you’re interested in to see the specific charging time to a full battery."
              />
          </p>
          </div>
        </div>

        <div className="row d-lg-none" style={{justifyContent: "center"}}>
          <div className="col-xs-12 col-sm-6">
            <div className="form-group">
              <select
                className="form-control select-iid"
                value={makeOption}
                onChange={onChangeMake}
                aria-label={intl.formatMessage({ id: "compareVehicles.selectMake", defaultMessage: "Select a Make"})}
              >
                <option defaultValue="">
                  {intl.formatMessage({ id: "compareVehicles.selectMake", defaultMessage: "Select a Make"})}
                </option>
                {vehicleMakeOptions(electricVehicles)}
              </select>
            </div>
          </div>
            
        <div className="col-xs-12 col-sm-6">
            <div className="form-group">
              <select
                className="form-control select-iid"
                value={modelOption}
                onChange={onChangeModelType}
                aria-label={intl.formatMessage({ id: "compareVehicles.selectModel", defaultMessage: "Select a Model"})}
              >
                <option defaultValue="">
                  {intl.formatMessage({ id: "compareVehicles.selectModel", defaultMessage: "Select a Model"})}
                </option>
                {vehicleModelOptions(electricVehicles, makeOption)}
              </select>
            </div>

          </div>
        </div>

        <div className="row">
          <div className="col-sm-12 d-block d-lg-none text-center">
            <ModalComponent
              buttonText="Filters"
              titleText="Refine Filters"
              submitText="Apply Filters"
            >
              {renderOptions}
            </ModalComponent>
            <br />
          </div>
          <div className="col-xl-3 col-lg-4 d-none d-lg-block">
            {renderOptions}

            <div className="sidebar-ad-container" style={{marginTop: "25px"}}>
                     <div className="ad">
                            <a href="https://googleads.g.doubleclick.net/pcs/click?xai=AKAOjstl8trkQRzhWBvPwvn9S4kuK60S490aTGxJyDFzXTcO34QcBsYeBi8Q2-cLVx6HZEc8MeC69dKDrABSeDx0xYSF0-X6C1rxbTlRYTnVD7PHGw4dA04oBc2ke86wB_pXVfACHQXWv0wMD7GukaeTu3KrYSIjVvgKbRKg6Y2kroR18zF1UcOz6R2gQrGapzeRikIvXGBYPZUwAP_UYuGoBhFbqciiwiarUVgOTMiMZdvC2LGiyioGZYZ_R_avmTj82o95ggZK2tbuy6iQixF7OFJJtvKB-tj83vwCLW3qV8CzisdjIvQto842AYXozOORPjncPhQTxZri7EQs4DD-o5Zh_w&sai=AMfl-YTwwDUOETAoy9wBUZQu4faBJFlMdmnIfGI4SMl4TwfsY1izkP4paCZMmbkahjhfe1OXKSHL227RAicy5H41FTimcdbdkC6Lkk2IcnR-8SFKYbkrCRfSYCR9sbZ0u6vWoLkRxeJE&sig=Cg0ArKJSzIuD9nXzz3PA&fbs_aeid=[gw_fbsaeid]&adurl=https://ev.svcleanenergy.org/&nm=2&nx=279&ny=-36&mb=2" target="_blank"
                            rel="noopener noreferrer">
                                   <img src={ad_160x600} alt="ad" style={{width: "100%"}} />
                            </a>
                     </div>
              </div>

          </div>
          <div
            id="home-chargers-catalog"
            aria-live="polite"
            aria-atomic="true"
            role="region"
            style={{ position: "absolute", top: "-9999px" }}
          >
            <span>Results have been updated as of {time}.</span>
          </div>
          <div
            className="col-xl-9 col-lg-8 col-sm-12"
            style={{ paddingLeft: 10 }}
          >
            <div className="row">
              <div className="d-none d-lg-block col-lg-6">
                <div>
                  <a href="https://www.svcleanenergy.org/gridshift-ev/" className="btn btn-svce btn-gridshift" target="_blank" rel="noopener noreferrer" style={{marginBottom:"25px", display: "block"}}>
                    Learn about GridShift Smart EV Charging
                  </a>
                  <a href="https://svcleanenergy.org/ev-charger-rebate/" className="btn btn-svce btn-gridshift" target="_blank" rel="noopener noreferrer" style={{marginBottom:"10px", display: "block"}}>
                    SVCE offering $250 Rebate on qualified chargers
                  </a>
                </div>
              </div>
              <div className="col-xs-12 col-lg-6">
                <div className="pull-right">
                  <ChargerSortControls />
                </div>
              </div>
            </div>
            <ChargerCatalog
              homeChargers={homeChargers} selectedVehicle={ev}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeChargers;

HomeChargers.propTypes = {
  electricVehicles: PropTypes.array,
  homechargers: PropTypes.array,
  ip: PropTypes.string,
  uuid: PropTypes.string
};