import React from "react";
import PropTypes from "prop-types";
import "./UsedVehiclePanels.scss";
import { TabContent, TabPane } from "reactstrap";
import { FormatAsDollars } from "../../../utils/Helpers/Format";
import sortIncentives from "../../../functions/incentives/Sort/sortIncentives"
import SelectVehicleYearFilter from "../../InputComponents/SelectVehicleYearFilter/SelectVehicleYearFilter"
import { FormattedMessage, useIntl } from 'react-intl';
import ToolTip from "../../shared/ToolTip/ToolTip";

const UsedVehiclePanels = ({ cars, car, setUsedEv }) => {

  const intl = useIntl();
  if (!cars) return null;

  const usedIncentives = car.incentives.filter(incentive => incentive.applicable_to_used_vehicles)

  const totalSavings = sortIncentives(usedIncentives).total("eligible-rebates");
  
  return (
    <>
      <div className="PricePanels">
        <TabContent activeTab={"1"}>
          <TabPane tabId="1">
            <div className="row">
              <div className="col-sm-12">
                <div className="PanelItem">
                  <SelectVehicleYearFilter
                    car={car}
                    vehicles={cars}
                    setUsedEv={setUsedEv}
                  />
                </div>
                <div className="PanelItem text-center">
                  <div className="PanelItemTop">
                    <p className="RenderItemTitle text-center">
                      <FormattedMessage
                        id="vehicle.afterIncentives"
                        defaultMessage="After Incentives"
                        description="Price Panel After Incentives"
                      />
                      <ToolTip
                        message={intl.formatMessage({
                          id: "afterIncentives.infoTooltip",
                          defaultMessage:
                            'Range based on MSRP and estimated lifetime mileage. See "Resale Value" under "Sources" below. Contact your local dealership for more precise pricing information.',
                        })}
                        id="incentives_tooltip"
                      />
                    </p>
                    <p className="h1">
                      {`${FormatAsDollars(
                        car.lowerBoundMsrp - totalSavings
                      )} - ${FormatAsDollars(
                        car.upperBoundMsrp - totalSavings
                      )}`}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </TabPane>
        </TabContent>
      </div>
    </>
  );
};

export default UsedVehiclePanels;

UsedVehiclePanels.propTypes = {
  car: PropTypes.object,
  paymentDetails: PropTypes.object
};
