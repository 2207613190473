import React from "react";
import PropTypes from "prop-types";
import "./IncentiveCard.scss";
import { FormatAsDollars } from "../../../utils/Helpers/Format"
import dollar from "../../assets/images/icons/dollar.jpeg"
import outboundIcon from "../../assets/images/icons/outboundIcon.png"

const IncentiveCard = props => {
  let incentive = props && props.incentive ? props.incentive : {};

  let eligibility = incentive.evaluation && incentive.evaluation.eligibility
  
  if (eligibility === "ineligible") {
    return null
  }
  
  let description = incentive.description || "";
  
  let amountDescription =
    incentive.typical_amount && incentive.typical_amount !== "N/A"
      ? incentive.typical_amount.replace("-$", "-")
      : "";

  let amount =
    incentive.evaluation &&
    incentive.evaluation.amount_in_purchase &&
    parseInt(incentive.evaluation.amount_in_purchase, 10) > 0
      ? parseInt(incentive.evaluation.amount_in_purchase, 10)
      : 0;

  let renderCard = incentive ? (
    <a
      className="evc-card IncentiveCard"
      href={incentive.details_url}
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className="IncentiveCardBody">
        <div className="h1 card-icon">
          <img 
            src={dollar}
            style={{ maxWidth: "40px" , paddingBottom: "8px"}}
            alt="dollar"
            />
        </div>
        <p className="h1 card-title">
          {amount ? FormatAsDollars(amount) : amountDescription}
        </p>
        <p className="h1 card-title">{incentive.grantor}</p>
        <p className="h1 card-title">{incentive.name}</p>
        <img 
            src={outboundIcon}
            style={{ maxWidth: "28px" , paddingBottom: "8px"}}
            alt="outbound icon"
        />
      </div>
      <div className="pl-5 pr-5">
        <hr />
      </div>
      <div className="IncentiveCardBottom">{description}</div>
    </a>
  ) : null;

  return renderCard;
};

export default IncentiveCard;

IncentiveCard.propTypes = {
  incentive: PropTypes.object,
  compact: PropTypes.bool
};
