import React, { useContext } from "react";
import PropTypes from "prop-types";
import calcVehicleRangeForBudget from "../../functions/vehicle/calcVehicleRangeForBudget";
import UserPrefsContext from "../../context/UserPrefs/UserPrefsContext";
import { FormatAsCents, FormatCarName } from "../../utils/Helpers/Format";
import { GASOLINE_PRICE_IN_DOLLARS_PER_GAL } from "../../client_customizations/data/assumptions/ASSUMPTIONS";
import { FormattedMessage, useIntl } from "react-intl";


import { HorizontalBar } from "react-chartjs-2";
import "chartjs-plugin-datalabels";

const FUEL_BUDGET_IN_DOLLARS = 5;

const FuelRangeChart = ({ car, forceUserPrefsPresets, hasExtraPadding }) => {
  const intl = useIntl();
  const userPrefs = useContext(UserPrefsContext);
  const GASOLINE_PRICE = userPrefs.get("gasolinePriceInCentsPerGal")
    ? userPrefs.get("gasolinePriceInCentsPerGal") / 100.0
    : GASOLINE_PRICE_IN_DOLLARS_PER_GAL.value;

  if (!car) return null;
  const equivalentGasVehicle = car.equivalent_gas_vehicle || {};

  const get = forceUserPrefsPresets ? userPrefs.getPreset : userPrefs.get;

  const vehicleRange = Math.round(
    calcVehicleRangeForBudget(
      car,
      FUEL_BUDGET_IN_DOLLARS,
      get("electricMilesPortionForPhev"),
      GASOLINE_PRICE
    )
  );
  const equivalentGasVehicleRange = Math.round(
    calcVehicleRangeForBudget(
      equivalentGasVehicle,
      FUEL_BUDGET_IN_DOLLARS,
      get("electricMilesPortionForPhev"),
      GASOLINE_PRICE
    )
  );

  const title = (
    <>
      <p className="h3 my-3">
        <FormattedMessage
          id="graph.fuelRangeTitle"
          defaultMessage="How far can you drive the on {budget}?"
          description="Fuel Range Title"
          values={{
            budget: FormatAsCents(FUEL_BUDGET_IN_DOLLARS),
          }}
        />
      </p>
      <p className="h3">
        <FormattedMessage
          id="graph.fuelRangeSubTitle"
          defaultMessage="The {name} can drive {range} more miles."
          description="Fuel Range Sub Title"
          values={{
            name: FormatCarName(car),
            range: vehicleRange - equivalentGasVehicleRange,
          }}
        />
      </p>
    </>
  );

  const carNames = [
    [FormatCarName(car)],
    [FormatCarName(car.equivalent_gas_vehicle)],
  ];

  const chartData = {
    labels: carNames,
    datasets: [
      {
        backgroundColor: ["#4fad99", "#4fad99"],
        data: [
          // maxVehicle,
          vehicleRange,
          equivalentGasVehicleRange,
        ],
      },
    ],
  };

  const chartOptions = {
    maintainAspectRatio: false,
    tooltips: {
      enabled: false,
    },
    scales: {
      xAxes: [
        {
          stacked: true,
          ticks: {
            max: vehicleRange,
            stepSize: 20,
          },
          scaleLabel: {
            display: true,
            labelString: intl.formatMessage ? intl.formatMessage({ id: "vehicle.miles", defaultMessage: "miles"}): "miles",
          },
        },
      ],
      yAxes: [
        {
          stacked: true,
          ticks: {
            font: {
              weight: 700,
            },
          },
        },
      ],
    },
    legend: {
      display: false,
    },
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          // if (ctx.dataIndex === 0) {
          //   return "Unlimited";
          // }
          return value + " miles";
        },
        align: "center",
        anchor: "center",
        color: "#ffffff",
        font: {
          weight: 700,
        },
      },
    },
  };

  return (
    <div
      className="input-well text-center"
      style={hasExtraPadding ? { padding: "2.5rem" } : { paddfing: "1rem" }}
    >
      {title}
      <div className="d-none d-lg-block d-xl-block">
        <div>
          <HorizontalBar
            data={chartData}
            height={260}
            type="horizontalBar"
            options={chartOptions}
          />
        </div>
      </div>
      {hasExtraPadding && <br />}
    </div>
  );
};

export default FuelRangeChart;

FuelRangeChart.propTypes = {
  car: PropTypes.object,
  forceUserPrefsPresets: PropTypes.bool,
  hasExtraPadding: PropTypes.bool,
};
