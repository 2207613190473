import React from "react";
import PropTypes from "prop-types";
import ShowHideButton from "../ShowHideButton/ShowHideButton";
import { FormatCarName, FormatAsInt } from "../../utils/Helpers/Format"
import "chartjs-plugin-datalabels";
import { HorizontalBar } from "react-chartjs-2";
import Uuid from "../../utils/Uuid/Uuid"

import { FormattedMessage, useIntl } from 'react-intl';

const ElectricRangeGraph = ({
  cars,
  hasExtraPadding,
}) => {

  const intl = useIntl()

  if (!cars || cars.length === 0) return null;

  cars = cars.filter( car => {
    return car !== null
  })

  cars = cars.map(car => {
      if (car.electric_range === "N/A") {
          car.electric_range = 0
      }
      car["gasoline_range"] = car.total_range - car.electric_range 
      return car
  })

  const sortedCarRange = [...cars].sort((car1, car2) => {
    return car2.electric_range - car1.electric_range
  })

  const subTitle = cars => {
    if (cars.length === 2) {
      const costDelta = cars[1].electric_range - cars[0].electric_range
      const costDeltaText = costDelta < 0 ? intl.formatMessage({ id: "vehicle.greater", defaultMessage: "greater"}) : intl.formatMessage({ id: "vehicle.fewer", defaultMessage: "fewer"});
  
      return (
        <p className="h3 my-3 graph-sub-title">
          <FormattedMessage 
            id="graph.electricRange.subTitle"
            defaultMessage="The {car}'s electric range is {costDeltaText} by {number} miles"
            description="Electric Range Sub Title Two Cars"
            values= {{
              car: FormatCarName(cars[0]),
              number: <strong style={{ fontWeight: 800 }}>
                        {(Math.abs(costDelta))}
                      </strong>,
              costDeltaText: costDeltaText,
            }}
          />
        </p>
      );
    } else {
      return (
        <p className="h3 my-3 graph-sub-title">
          <FormattedMessage 
            id="graph.electricRange.subTitleThreeCars"
            defaultMessage="The {car} has the longest Electric Range"
            description="Electric Range Sub Title Three Cars"
            values= {{
              car: FormatCarName(sortedCarRange[0]),
            }}
          />
        </p>
      )
    }
  }

  const title = (
    <>
      <p className="h3 my-3">
        <FormattedMessage 
          id="graph.title.electricRange"
          defaultMessage="Electric Range"
          description="Electric Range Title"
        />
      </p>
      {subTitle(cars)}
    </>
  )

  const carNames = cars.map(car => FormatCarName(car))

  const chartData = {
    labels: carNames,
    datasets: [
      {
        label: intl.formatMessage({ id: "graph.title.electricRange", defaultMessage: "Electric Range"}),
        backgroundColor: "#A3DAD6",
        data: cars.map(car => car.electric_range)
      }
    ]
  };

  const descriptionRow = (description) => {
    return cars.map(car => {
        return (
        <td key={Uuid()}>{FormatAsInt(car[description])}</td>
      )
    })
  }

  const descriptionRows = 
    (
        <>
            <tr>
                <th scope="row">
                  <FormattedMessage 
                    id="electricity"
                    defaultMessage="Electricity"
                    description="Electricity"
                  />
                </th>
                    {descriptionRow("electric_range")}
            </tr>
            <tr>
                <th scope="row">
                  <FormattedMessage 
                    id="gasoline"
                    defaultMessage="Gasoline"
                    description="Gasoline"
                  />
                </th>
                    {descriptionRow("gasoline_range")}
            </tr>
        </>
    )

  const totalRow = (
    <tr>
        <th scope="row">
          <FormattedMessage 
            id="total"
            defaultMessage="Total"
            description="Total"
          />
        </th>
            {descriptionRow("total_range")}
    </tr>
  )

  const graphValueColumns = cars.map((car) => {
      return (
        <th key={Uuid()} scope="col">{FormatCarName(car)}</th>
      )
  })

  const CHART_JS_OPTIONS = {
    maintainAspectRatio: false,
    tooltips: {
      callbacks: {
        label: function(tooltipItem, data) {
          var value =
            data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          var label = data.datasets[tooltipItem.datasetIndex].label || "";
          value = FormatAsInt(value);
          return " " + label + ": " + value;
        },
        title: function(tooltipItems, data) {
          let total = tooltipItems.reduce(function(acc, item) {
            return acc + item.xLabel;
          }, 0);
          return tooltipItems[0].yLabel + ": " + FormatAsInt(total);
        }
      }
    },
    scales: {
      xAxes: [
        {
          stacked: true,
          ticks: {
            callback: function(value) {
              return value;
            },
            beginAtZero: true
          },
          scaleLabel: {
              display: true,
              labelString: intl.formatMessage({ id: "vehicle.miles", defaultMessage: "miles"}),
              fontSize: 10,
              fontColor: "#2b2b2b"
          }
        }
      ],
      yAxes: [
        {
          stacked: true,
          ticks: {
            font: {
              weight: 700
            }
          }
        }
      ]
    },
    legend: {
      position: "bottom",
      onClick: function(e) {
        e.stopPropagation();
      }
    },
    layout: {
      padding: {
        right: 75
      }
    },
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          let datasets = ctx.chart.data.datasets;
          if (ctx.datasetIndex === datasets.length - 1) {
            let sum = 0;
            datasets.forEach(dataset => {
              sum += dataset.data[ctx.dataIndex];
            });
            return `${FormatAsInt(sum)} ${intl.formatMessage({ id: "vehicle.miles", defaultMessage: "miles"})}`;
          } else {
            return "";
          }
        },
        align: "end",
        anchor: "end",
        color: "#333333",
        font: {
          weight: 700
        }
      },
    }
  };

  return (
    <div
      className="input-well text-center"
      style={hasExtraPadding ? { padding: "2.5rem" } : { paddfing: "1rem" }}
    >
      {title}
      <div className="d-none d-lg-block d-xl-block">
        <div>
          <HorizontalBar
            data={chartData}
            height={212}
            type="horizontalBar"
            options={CHART_JS_OPTIONS}
          />
        </div>
      </div>
      {hasExtraPadding && <br />}
      <ShowHideButton buttonText={intl.formatMessage({ id: "graph.graphValues", defaultMessage: "Graph Values"})} displayChildrenOnlyOnTablet>
        <br />
        <div className="row">
          <div className="col-sm-12">
            <table className="table table-sm table-bordered">
              <thead>
                <tr>
                <th scope="col">
                  <FormattedMessage 
                    id="description"
                    defaultMessage="Description"
                    description="Description"
                  />
                </th>
                    {graphValueColumns}
                </tr>
              </thead>
              <tbody>
                {descriptionRows}
              </tbody>
              <tfoot>{totalRow}</tfoot>
            </table>
          </div>
        </div>
      </ShowHideButton>
    </div>
  );
};

export default ElectricRangeGraph;

ElectricRangeGraph.propTypes = {
  cars: PropTypes.array,
  hasExtraPadding: PropTypes.bool
};
