
import React, { useState } from "react";
import PropTypes from "prop-types";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner"
import IncentiveCard from "../IncentiveCard/IncentiveCard"

const IncentiveCarousel = ({ incentives }) => {
  const [firstIncentive, setFirstIncentive] = useState(0);

  if (!incentives) return <LoadingSpinner />;

  incentives = incentives.filter(incentive => {
    let eligibility = incentive.evaluation && incentive.evaluation.eligibility
  
    if (eligibility === "ineligible") {
      return null
    }
    return incentive
  })

  const incentiveCount = incentives.length
  const maxIndex = incentiveCount - 1;

  const incentiveIndices = [
    firstIncentive,
    firstIncentive + 1 <= maxIndex ? firstIncentive + 1 : firstIncentive + 1 - incentiveCount,
    firstIncentive + 2 <= maxIndex ? firstIncentive + 2 : firstIncentive + 2 - incentiveCount,
  ];

  const filteredIncentives = [
    incentives[incentiveIndices[0]],
    incentives[incentiveIndices[1]],
    incentives[incentiveIndices[2]],
  ];

  const threeIncentives = filteredIncentives.map((incentive, i) => {
    return (
      <IncentiveCard incentive={incentive} key={i} />
    );
  });

  const threeIncentivesMobile = filteredIncentives.map((incentive, i) => {
    return (
      <div className="incentive-card" key={i}>
        <IncentiveCard incentive={incentive} key={i} />
      </div>
    );
  });

  const renderIncentives = (
    <>
      <div className="row d-none d-lg-flex d-xl-flex">
        <div className="col-sm-1 text-right">
          <button
            className="btn btn-default"
            style={{ marginTop: "105px", fontSize: "21px", backgroundColor: "#fff" }}
            onClick={() =>
                firstIncentive === 0 ? setFirstIncentive(maxIndex) : setFirstIncentive(firstIncentive - 1)
            }
          >
            &lsaquo;
          </button>
        </div>
        <div className="col-sm-10">
          <div className="render-incentives-container">
            {threeIncentives}
          </div>
        </div>
        <div className="col-sm-1">
          <button
            className="btn btn-default"
            style={{ marginTop: "105px", fontSize: "21px", backgroundColor: "#fff" }}
            onClick={() =>
                firstIncentive === maxIndex ? setFirstIncentive(0) : setFirstIncentive(firstIncentive + 1)
            }
          >
            &rsaquo;
          </button>
        </div>
      </div>

      <div className="render-incentives render-cards-container full-width d-flex d-lg-none d-xl-none">
          {threeIncentivesMobile}
        </div>
    </>
  );

  return (
    <>
      <div className="row">
        <div className="col-sm-12">{renderIncentives}</div>
      </div>
    </>
  );
};

export default IncentiveCarousel;

IncentiveCarousel.propTypes = {
  electricVehicles: PropTypes.array
};
