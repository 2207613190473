import React, { useEffect } from "react";
import PropTypes from "prop-types";
import "./usedEVs.scss";

import UsedEVCatalog from "../../components/UsedEVCatalog/UsedEVCatalog"
import MatchScoreOptions from "../../components/MatchScoreOptions/MatchScoreOptions"
import EvSortControls from "../../components/EvSortControls/EvSortControls"
import NewUsedEVFilter from "../../client_customizations/components/NewUsedEVFilter/NewUsedEVFilter";

import EVFilterControls from "../../components/EVFilterControls/EVFilterControls"
import ModalComponent from "../../components/ModalUIComponent/ModalUIComponent"

import ad_160x600 from "../../client_customizations/assets/images/ads/ad-160x600.png";

import {FormattedMessage, useIntl} from 'react-intl';

const UsedEVs = ({ usedElectricVehicles, ip, uuid }) => {
  const intl = useIntl() 

  useEffect(() => {
    document.title = process.env.REACT_APP_PAGES_USED_VEHICLES_TITLE;
  });

  const time = new Date().toLocaleString();
  const size = window.innerWidth > 800;

  const renderOptions = (
    <>
      {size ? (
        <div style={{ display: "inline" }}>
          <NewUsedEVFilter value={"used"} />
        </div>
      ) : null}
      <MatchScoreOptions />
      <br />
      <EVFilterControls vehicles={usedElectricVehicles} usedEvs={true} />
      <div className="sidebar-ad-container" style={{marginTop: "25px"}}>
              <div className="ad">
                     <a href="https://googleads.g.doubleclick.net/pcs/click?xai=AKAOjstl8trkQRzhWBvPwvn9S4kuK60S490aTGxJyDFzXTcO34QcBsYeBi8Q2-cLVx6HZEc8MeC69dKDrABSeDx0xYSF0-X6C1rxbTlRYTnVD7PHGw4dA04oBc2ke86wB_pXVfACHQXWv0wMD7GukaeTu3KrYSIjVvgKbRKg6Y2kroR18zF1UcOz6R2gQrGapzeRikIvXGBYPZUwAP_UYuGoBhFbqciiwiarUVgOTMiMZdvC2LGiyioGZYZ_R_avmTj82o95ggZK2tbuy6iQixF7OFJJtvKB-tj83vwCLW3qV8CzisdjIvQto842AYXozOORPjncPhQTxZri7EQs4DD-o5Zh_w&sai=AMfl-YTwwDUOETAoy9wBUZQu4faBJFlMdmnIfGI4SMl4TwfsY1izkP4paCZMmbkahjhfe1OXKSHL227RAicy5H41FTimcdbdkC6Lkk2IcnR-8SFKYbkrCRfSYCR9sbZ0u6vWoLkRxeJE&sig=Cg0ArKJSzIuD9nXzz3PA&fbs_aeid=[gw_fbsaeid]&adurl=https://ev.svcleanenergy.org/&nm=2&nx=279&ny=-36&mb=2" target="_blank"
                     rel="noopener noreferrer">
                            <img src={ad_160x600} alt="ad" style={{width: "100%"}} />
                     </a>
              </div>
       </div>
    </>
  );


  return (
    <section className="container" id="EVs">
      <div className="row mb-3">
        <div className="col-sm-12 text-left">
          <h2>
            <FormattedMessage
              id="usedElectricVehicles"
              defaultMessage="Used Electric Vehicles"
              description="Used Electric Vehicles"
            />
          </h2>
          <p className="lead">
            <FormattedMessage
              id="evs.welcomeSub"
              defaultMessage="Compare electric cars by EV range, price, or your personalized Match Score. {lineBreak} Click on the EV for more details, including total cost compared to a similar gas vehicle."
              description="Description of Colton Recharged"
              values={{
                lineBreak: <br />,
              }}
            />
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 d-block d-lg-none text-center">
          <ModalComponent
            buttonText={
              intl.formatMessage
                ? intl.formatMessage({
                    id: "evs.buttonMatchScoreAndFilters",
                    defaultMessage: "Match Score and Filters",
                  })
                : "Match Score and Filters"
            }
            titleText={
              intl.formatMessage
                ? intl.formatMessage({
                    id: "evs.matchScoreAndFilters",
                    defaultMessage: "Refine Match Score and Filters",
                  })
                : "Refine Match Score and Filters"
            }
          >
            {renderOptions}
          </ModalComponent>
          <br />
        </div>
        <div className="col-xl-3 col-lg-4 d-none d-lg-block">
          {renderOptions}
        </div>
        <div
          id="electric-vehicles-catalog"
          aria-live="polite"
          aria-atomic="true"
          role="region"
          style={{ position: "absolute", top: "-9999px" }}
        >
          <span>Results have been updated as of {time}.</span>
        </div>
        <div
          className="col-xl-9 col-lg-8 col-sm-12"
          style={{ paddingLeft: 10 }}
        >
          <div className="row">
            <div className="col">
              {size ? null : (
                <div style={{ display: "inline" }}>
                  <NewUsedEVFilter value={"used"} />
                </div>
              )}
              <div className="pull-right">
                <EvSortControls />
              </div>
            </div>
          </div>
          <UsedEVCatalog
            vehicles={usedElectricVehicles}
            hasEvDetails
            hasIncentivesAndMatchScore
          />
        </div>
      </div>
    </section>
  );
};

export default UsedEVs;

UsedEVs.propTypes = {
  electricVehicles: PropTypes.array,
  ip: PropTypes.string,
  uuid: PropTypes.string
};