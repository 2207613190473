import React from "react";
import { Link } from "react-router-dom";
import "./Footer.scss";
import whitePopOut from "../../../assets/images/icons/whitePopOut.png"
import { FormattedMessage } from 'react-intl';
import DisclaimerComponent from "@zappy-ride/disclaimer-footer-component";
const Footer = () => {
  return (
    <div className="Footer">
      <div className="container pt-5 pb-4">
        <div className="row">
          <div className="col-md-4 mb-3">
            <p className="h4">
              EV Assistant
              <span className="zappyride">
                &nbsp; <FormattedMessage
                  id="footer.poweredByJDP"
                  defaultMessage="Powered by J.D. Power ZappyRide"
                  description="Powered by J.D. Power ZappyRide"
                />
              </span>
            </p>
            <br />
            {process.env.REACT_APP_PAGES_VEHICLES_ENABLED ? (<>
              <Link to="/vehicles">
                <FormattedMessage
                  id="header.browseElectricCars"
                  defaultMessage="Browse Electric Cars"
                  description="Browse Electric Cars"
                />
              </Link>
              <br />
            </>) : (null)}
            {process.env.REACT_APP_PAGES_COMPARE_ENABLED ? (<>
              <Link to="/compare-vehicles">
                <FormattedMessage
                  id="header.compareVehicles"
                  defaultMessage="Compare Cars"
                  description="Compare Cars"
                />
              </Link>
              <br />
            </>) : (null)}
            {process.env.REACT_APP_PAGES_DEALERS_ENABLED ? (<>
              <Link to="/dealers">
                <FormattedMessage
                  id="dealers.findDealers"
                  defaultMessage="Find Dealers"
                  description="Find Dealers"
                />
              </Link>
              <br />
            </>) : (null)}
            {process.env.REACT_APP_PAGES_CHARGING_ENABLED ? (<>
              <Link to="/charging-stations">
                <FormattedMessage
                  id="header.locatePublicChargingStations"
                  defaultMessage="Charging Options"
                  description="Charging Options"
                />
              </Link>
              <br />
            </>) : (null)}
            <Link to="/home-chargers">
              <FormattedMessage
                id="header.shopHomeChargers"
                defaultMessage="Shop Home Chargers"
                description="Shop Home Chargers"
              />
            </Link>
            <br />
            {process.env.REACT_APP_PAGES_ELECTRICIANS_ENABLED &&
              <>
                <Link to="/electricians">
                  <FormattedMessage
                    id="header.findElectricians"
                    defaultMessage="Find Electricians"
                    description="Find Electricians"
                  />
                </Link>
                <br />
              </>
            }
            {process.env.REACT_APP_PAGES_INCENTIVES_ENABLED ? (<>
              <Link to="/incentives">
                <FormattedMessage
                  id="header.discoverIncentives"
                  defaultMessage="Discover Incentives"
                  description="Discover Incentives"
                />
              </Link>
              <br />
            </>) : (null)}
            {process.env.REACT_APP_PAGES_CUSTOM_1_ENABLED ? (<>
              <Link to={process.env.REACT_APP_PAGES_CUSTOM_1_NAVLINK}>
                {process.env.REACT_APP_PAGES_CUSTOM_1_NAVNAME}</Link>
              <br />
            </>) : (null)}
            {process.env.REACT_APP_PAGES_FAQ_ENABLED ? (<>
              <Link to="/faq">{process.env.REACT_APP_PAGES_FAQ_TITLE}</Link>
              <br />
            </>) : (null)}
          </div>
          <div className="col-md-4 mb-3 more-sites-col">
            <p className="h4">
              <FormattedMessage
                id="header.moreSites"
                defaultMessage="More Sites"
                description="More Sites"
              />
              <img className="more-sites-popout" alt="Go to new Tab of Home Browsing" src={whitePopOut} />
            </p>
            <br />
            <a
              href="http://appliances.svcleanenergy.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Appliances Assistant
            </a>
            <br />
            <a
              href="https://solar.svcleanenergy.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Solar + Battery Assistant
            </a>
            <br />
            <a
              href="https://solarbattery.svcleanenergy.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Battery Assistant
            </a>
            <br />
            <a
              href="https://www.svcleanenergy.org/ehub"
              target="_blank"
              rel="noopener noreferrer"
            >
              Silicon Valley Clean Energy
            </a>
            <br />
          </div>
          <div className="col-md-4 mb-3">
            <div className="h3 small"></div>
            <p>
              <FormattedMessage
                id="footer.havingTrouble"
                defaultMessage="Having trouble using this site?"
                description="Having Trouble"
              />
            </p>
            <a
              style={{ color: "white" }}
              href={process.env.REACT_APP_COMPANY_CONTACT}
            >
              <FormattedMessage
                id="footer.contact"
                defaultMessage="Contact: support@zappyride.com"
                description="Contact"
              />
            </a>
            <br />
            <p style={{ marginBottom: 0, color: "#fff", fontSize: "11px", fontFamily: "Open Sans" }}>
              <span style={{ fontFamily: "Open Sans" }}
              >Copyright &copy;</span>
              {(new Date().getFullYear().toString())} {process.env.REACT_APP_FULL_COMPANY_NAME}.&nbsp;
              <FormattedMessage
                id="footer.allRights"
                defaultMessage="All Rights Reserved"
                description="All Rights"
              />
            </p>

            <span className="disclaimer_container" style ={{marginTop:"20px"}}>
                  {/* <DisclaimerComponent clientName="zappynobackground"/> */}

                  <DisclaimerComponent clientName="zappynobackground" wrapperStyle={{padding:'0px'}}/>
            </span>

            {/* <p style={{ marginBottom: 0, color: "#fff", fontSize: "11px" }}>
              <span style={{ fontFamily: "Open Sans" }}>
                Copyright &copy;
                {(new Date().getFullYear().toString())} J.D. Power ZappyRide (c). {" "}
                <a style={{ fontFamily: "Open Sans", fontSize: 11 }} rel="noopener noreferrer" target="_blank" href="https://zappyride.com/legal-notices"> Legal notices.</a>
              </span>
            </p> */}

          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
