import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import IncentiveCarousel from "../../../../client_customizations/components/IncentiveCarousel/IncentiveCarousel"
import {FormattedMessage} from 'react-intl';

const HomepageIncentives = ({ incentives }) => {

  if (!incentives || incentives.length <= 3) return null;


  return (
    <section 
      className="container-fluid pb-0" 
      id="HomepageIncentives"
      >
      <div className="row">
        <div className="col-sm-12">
          <h2 className="discover-incentives">
            <FormattedMessage 
              id="homepage.incentives.title"
              defaultMessage="Discover Incentives"
              description="Homepage Incentives Title"
            />
          </h2>
        </div>
      </div>
      <IncentiveCarousel incentives={incentives} category="all" />
      <div className="row explore-incentives-container">
        <div className="col-sm-12">
          <Link to="/incentives" className="btn btn-svce" role="button">
            <FormattedMessage 
              id="homepage.incentives.exporeIncentives"
              defaultMessage="EXPLORE INCENTIVES"
              description="Explore Incentives Button"
            />
          </Link>
          <p className="legal-disclaimer">
            <FormattedMessage 
              id="homepage.incentives.legalDisclaimer"
              defaultMessage="Silicon Valley Clean Energy hosts this website as a resource for customers. SVCE does not endorse or guarantee, and makes no warranties or representations regarding, any vendor, contractor, service or product. SVCE shall not be liable for any loss or damage of any kind arising out of or connected to any vendor, contractor, service or product associated with this website. Any transactions that you enter into with a vendor, contractor or other third party are solely between you and that vendor, contractor or other third party."
              description="Homepage Incentives Disclaimer"
            />
        </p>
        </div>
      </div>
    </section>
  );
};

export default HomepageIncentives;

HomepageIncentives.propTypes = {
  incentives: PropTypes.array
};
