import React from "react";
import PropTypes from "prop-types";
import { TabContent, TabPane } from "reactstrap";

import {
  ALL_STATIONS_TAB_ID,
  MAP_TABS,
  DEALER_CATALOG_TAB_ID,
  ELECTRICIANS_TAB_ID,
  ROUTE_MAP_TAB_ID
} from "../../constants/mapTabs";
import ChargingMap from "../LocationMap/ChargingMap";
import DealerMap from '../LocationMap/DealerMap';
import ElectriciansMap from '../LocationMap/ElectriciansMap';
import TravelRouteMap from '../LocationMap/TravelRouteMap/TravelRouteMap';
import { useIntl } from 'react-intl';
import "./TabbedMaps.scss";
import ad_728x90 from "../../../client_customizations/assets/images/ads/ad-728x90.png";

const TabbedMaps = ({
  chargingStationsFilterFn,
  electricVehicles,
  oems,
  activeTabId,
  toggle,
  canIgnoreLowPowerStations = true,
  userLocation,
  dealerLocations,
  zipcode
}) => {
  const intl = useIntl()
  const pageTitle = MAP_TABS.find(tab => tab.id === activeTabId)
  const translatedTitles = {
    "Dealers" : intl.formatMessage ? intl.formatMessage({ id: "dealers", defaultMessage: "Dealers"}) : "Dealers",
    "Public Charging Station Map" : intl.formatMessage ? intl.formatMessage({ id: "publicCharingStationMap", defaultMessage: "Public Charging Station Map"}) : "Public Charging Station Map"
  }

  return(
    <>
      <h1 className="pt-4 pb-4">{translatedTitles[pageTitle.name]}</h1>
      <div className="TabbedMaps pb-4">
        <div className="tabbed-maps-panel">
          <TabContent activeTab={activeTabId}>
            {process.env.REACT_APP_PAGES_CHARGING_ENABLED && (
              <TabPane tabId={ALL_STATIONS_TAB_ID}>
                <ChargingMap
                  userLocation={userLocation}
                  isVisible={activeTabId === ALL_STATIONS_TAB_ID}
                  chargingTab={true}
                />
              </TabPane>
            )}
            {process.env.REACT_APP_PAGES_MAP_ROUTE_ENABLED && (
              <TabPane tabId={ROUTE_MAP_TAB_ID}>
                <TravelRouteMap isVisible={activeTabId === ROUTE_MAP_TAB_ID} />
              </TabPane>
            )}
            {process.env.REACT_APP_PAGES_DEALERS_ENABLED && (
              <TabPane tabId={DEALER_CATALOG_TAB_ID}>
                <DealerMap
                  userLocation={userLocation}
                  dealerLocations={dealerLocations}
                  isVisible={activeTabId === DEALER_CATALOG_TAB_ID}
                />
              </TabPane>
            )}
            {process.env.REACT_APP_PAGES_ELECTRICIANS_ENABLED && (
              <TabPane tabId={ELECTRICIANS_TAB_ID}>
                <ElectriciansMap
                  userLocation={userLocation}
                  zipcode={zipcode}
                  isVisible={activeTabId === ELECTRICIANS_TAB_ID}
                />
              </TabPane>
            )}
          </TabContent>
        </div>
      </div>

      <div className="container text-center" style={{marginTop: "50px", paddingBottom: "50px"}}>
              <div className="ad-container" style={{marginBottom: "50px"}}>
                     <div className="ad">
                            <a href="https://googleads.g.doubleclick.net/pcs/click?xai=AKAOjstl8trkQRzhWBvPwvn9S4kuK60S490aTGxJyDFzXTcO34QcBsYeBi8Q2-cLVx6HZEc8MeC69dKDrABSeDx0xYSF0-X6C1rxbTlRYTnVD7PHGw4dA04oBc2ke86wB_pXVfACHQXWv0wMD7GukaeTu3KrYSIjVvgKbRKg6Y2kroR18zF1UcOz6R2gQrGapzeRikIvXGBYPZUwAP_UYuGoBhFbqciiwiarUVgOTMiMZdvC2LGiyioGZYZ_R_avmTj82o95ggZK2tbuy6iQixF7OFJJtvKB-tj83vwCLW3qV8CzisdjIvQto842AYXozOORPjncPhQTxZri7EQs4DD-o5Zh_w&sai=AMfl-YTwwDUOETAoy9wBUZQu4faBJFlMdmnIfGI4SMl4TwfsY1izkP4paCZMmbkahjhfe1OXKSHL227RAicy5H41FTimcdbdkC6Lkk2IcnR-8SFKYbkrCRfSYCR9sbZ0u6vWoLkRxeJE&sig=Cg0ArKJSzIuD9nXzz3PA&fbs_aeid=[gw_fbsaeid]&adurl=https://ev.svcleanenergy.org/&nm=2&nx=279&ny=-36&mb=2" target="_blank"
                            rel="noopener noreferrer">
                                   <img src={ad_728x90} alt="ad" />
                            </a>
                     </div>
              </div>
       </div>
    </>
  );
};
TabbedMaps.propTypes = {
  chargingStationsFilterFn: PropTypes.func,
  electricVehicles: PropTypes.array,
  oems: PropTypes.array,
  activeTabId: PropTypes.string,
  toggle: PropTypes.func,
  canIgnoreLowPowerStations: PropTypes.bool
};

export default TabbedMaps;
