import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link, NavLink } from "react-router-dom";
import { Collapse, Navbar, NavbarToggler, Nav, NavItem, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle } from "reactstrap";
// import logo from "../../../assets/images/ZappyRide.svg"
import logo from "../../../assets/images/jdp-logo.png"
import SVCElogo from "../../../assets/images/SVCE.png"
import "./Header.scss";
import {FormattedMessage} from 'react-intl';

const Header = ({ page, language, changeLanguage }) => {
  const [collapse, setCollapse] = useState(false);

  const languageOptions = {
    "EN" : "English",
    "ES" : "Español",
    "ZH-HANT" : "中文",
    "VI" : "Tiếng Việt"
  }

  const onChangeLanguage = language => {
    changeLanguage(language)
  }

  return (
    <div className="Header">
        <div style={{ display: "flex", paddingTop: "0.75rem", paddingBottom: "0.75rem", justifyContent: "space-between" }}>
          <Link to="/">
            <div className="header-left">
                <span className="tool-name">EV Assistant</span>
                <div className="powered-by">
                  <span className="tool-subname">
                    Powered By
                  </span>
                    <img
                      src={logo}
                      alt="Zappyride's Logo"
                      className="img-fluid"
                    />
                </div>
            </div>
          </Link>
          <div className="header-right">
            <a
              href="https://www.svcleanenergy.org/"
              target="_blank"
              rel="noopener noreferrer"
              className="navbar-brand navbar-svce"
            >
              <img
                src={SVCElogo}
                alt="SVCE's Logo"
                className="img-fluid svce-logo"
              />
            </a>
            <div className="container d-block d-lg-none" style={{ padding: 0 }}>
            <Navbar className="mobile-hamburger" expand="lg">
              <NavbarToggler onClick={() => setCollapse(!collapse)} aria-label={collapse ? 'Close navigation' : 'Open navigation'} />
            </Navbar>
          </div >
      </div>
        </div>
        <Navbar className="d-flex justify-content-start" expand="lg">
          <Collapse isOpen={collapse} navbar>
            <Nav className="nav-fill w-100" navbar>
            { process.env.REACT_APP_PAGES_VEHICLES_ENABLED  ? (
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle className={page === "vehicles" ? "active" : ""} nav caret>
                <span>
                  <FormattedMessage 
                    id="header.exploreElectricCars"
                    defaultMessage="Explore Electric Cars"
                    description="Explore Electric Cars"
                  />
                </span>
                </DropdownToggle>
                <DropdownMenu >
                      <DropdownItem tag={Link} to={`/vehicles`}>
                        <FormattedMessage 
                          id="header.browseElectricCars"
                          defaultMessage="Browse Electric Cars"
                          description="Browse Electric Cars"
                        />
                      </DropdownItem>
                      <DropdownItem tag={Link} to={`/used-vehicles`}>
                        <FormattedMessage 
                          id="header.browseUsedElectricCars"
                          defaultMessage="Browse Electric Cars"
                          description="Browse Electric Cars"
                        />
                      </DropdownItem>
                      <DropdownItem tag={Link} to={`/compare-vehicles`}>
                        <FormattedMessage 
                          id="header.compareVehicles"
                          defaultMessage="Compare Cars"
                          description="Compare Cars"
                        />
                      </DropdownItem>
                      <DropdownItem tag={Link} to={`/dealers`}>
                        <FormattedMessage 
                          id="dealers.findDealers"
                          defaultMessage="Find Dealers"
                          description="Find Dealers"
                        />
                      </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>  
            ):(null)}
            { process.env.REACT_APP_PAGES_CHARGING_ENABLED ? (
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle className={page === "map" ? "active" : ""} nav caret>
                <span>
                  <FormattedMessage 
                    id="header.chargingOptions"
                    defaultMessage="Charging Options"
                    description="Charging Options"
                  />
                </span>
                </DropdownToggle>
                <DropdownMenu >
                    <DropdownItem tag={Link} to={`/charging-stations`}>
                      <FormattedMessage 
                        id="header.locatePublicChargingStations"
                        defaultMessage="Charging Options"
                        description="Charging Options"
                      />
                    </DropdownItem>
                    <DropdownItem tag={Link} to={`/home-chargers`}>
                      <FormattedMessage 
                        id="header.shopHomeChargers"
                        defaultMessage="Shop Home Chargers"
                        description="Shop Home Chargers"
                      />
                    </DropdownItem>
                    <DropdownItem 
                      target="_blank"
                      href="https://www.svcleanenergy.org/gridshift-ev/"
                    >
                      GridShift: EV
                    </DropdownItem>
                    {process.env.REACT_APP_PAGES_ELECTRICIANS_ENABLED && 
                      <DropdownItem tag={Link} to={`/electricians`}>
                        <FormattedMessage 
                          id="header.findElectricians"
                          defaultMessage="Find Electricians"
                          description="Find Electricians"
                        />
                      </DropdownItem>
                    }
                </DropdownMenu>
              </UncontrolledDropdown>
            ):(null)}                 
              { process.env.REACT_APP_PAGES_INCENTIVES_ENABLED  ? (
                <NavItem>
                <NavLink
                  title={page === "incentives" ? "Active Page" : null}
                  to="/incentives"
                >
                  <span> 
                    <FormattedMessage 
                      id="header.discoverIncentives"
                      defaultMessage="Discover Incentives"
                      description="Discover Incentives"
                    />
                  </span>
                </NavLink>
              </NavItem>
              ):(null)}
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  <span>
                    <FormattedMessage 
                      id="header.moreSites"
                      defaultMessage="More Sites"
                      description="More Sites"
                    />
                  </span>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem 
                    target="_blank"
                    href="http://appliances.svcleanenergy.org/"
                    className="dropdown-right-item"
                  >
                    Appliances Assistant
                  </DropdownItem>
                  <DropdownItem 
                    target="_blank"
                    href="https://solarbattery.svcleanenergy.org/"
                    className="dropdown-right-item"
                  >Solar + Battery Assistant
                  </DropdownItem>
                  <DropdownItem 
                    target="_blank" 
                    href="https://battery.svcleanenergy.org/"
                    className="dropdown-right-item"
                  >
                    Battery Assistant
                  </DropdownItem>
                  <DropdownItem 
                    target="_blank" 
                    href="https://svcleanenergy.org/ehub"
                    className="dropdown-right-item"
                  >
                    Silicon Valley Clean Energy
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown nav inNavbar className="language-toggle">
                <DropdownToggle nav caret>
                  <span>{languageOptions[language]}</span>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem 
                    onClick={() => onChangeLanguage("EN")}
                  >
                    English
                  </DropdownItem>
                  <DropdownItem
                   onClick={() => onChangeLanguage("ES")} 
                  >
                    Español
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => onChangeLanguage("ZH-HANT")}
                  >
                    中文
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => onChangeLanguage("VI")}
                  >
                    Tiếng Việt
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>    
              { process.env.REACT_APP_PAGES_CUSTOM_1_ENABLED  ? (
                <NavItem>
                  <NavLink
                    title={page === process.env.REACT_APP_PAGES_CUSTOM_1_NAVLINK ? "Active Page" : null}
                    to={process.env.REACT_APP_PAGES_CUSTOM_1_NAVLINK}
                  >
                    <span>{process.env.REACT_APP_PAGES_CUSTOM_1_NAVNAME}</span>
                  </NavLink>
                </NavItem>
              ):(null)}
              { process.env.REACT_APP_PAGES_FAQ_ENABLED ? (
                <NavItem>
                  <NavLink
                    title={page === "faq" ? "Active Page" : null}
                    to="/faq"
                  >
                    <span>FAQ</span>
                  </NavLink>
                </NavItem>
              ):(null)}           
              { process.env.REACT_APP_PAGES_FAQ_ENABLED ? (              
              <NavItem>
                <NavLink
                  title={page === "faq" ? "Active Page" : null}
                  to="/faq"
                >
                  <span>FAQ</span>
                </NavLink>
              </NavItem>
            ):(null)}              
            </Nav>
          </Collapse>
        </Navbar>
    </div>
  );
};

export default Header;

Header.propTypes = {
  page: PropTypes.string
};
